import Swal from "sweetalert2";
import ExcelJS from "exceljs";
import dayjs from "dayjs";

//empty check
export function isEmpty(value) {
  return (
    value === null ||
    value === undefined ||
    value === false ||
    value === 0 ||
    (typeof value === "string" && !value.trim())
  );
}
export function validateStr(str) {
  return str === null ||
    str === undefined ||
    (typeof str === "string" && !str.trim())
    ? false
    : true;
}

export function validateNum(num, len) {
  return num === null ||
    num === undefined ||
    num == "" ||
    parseInt(num).toString().length != len
    ? false
    : true;
}

//email valid check
export function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
//Date Helper function
function pad2(number) {
  return (number < 10 ? "0" : "") + number;
}
export function formatDate(date) {
  const formattedDate = `${date.getFullYear()}-${pad2(
    date.getMonth() + 1
  )}-${pad2(date.getDate())}T${pad2(date.getHours())}:${pad2(
    date.getMinutes()
  )}`;
  return formattedDate;
}

//Swal sweet alert
export function useToast() {
  return Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
}
export function jsonParser(str) {
  let cleanedString = str
    .replace(/^{"/, "[") // Replace { " at the beginning with [
    .replace(/"}$/, "]") // Replace "} at the end with ]
    .replace(/","/g, ","); // Replace "," with ,
  // Remove the extra backslashes
  cleanedString = cleanedString.replace(/\\/g, "");
  return JSON.parse(cleanedString);
}
export function convertNames(names) {
  return names
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const replaceTextInExcel = async (file, replacements) => {
  const workbook = new ExcelJS.Workbook();
  await workbook.xlsx.load(file);

  workbook.eachSheet((worksheet) => {
    worksheet.eachRow({ includeEmpty: true }, (row) => {
      row.eachCell({ includeEmpty: true }, (cell) => {
        if (cell.value && typeof cell.value === "string") {
          Object.keys(replacements).forEach((oldText) => {
            const newText = replacements[oldText];
            cell.value = cell.value.replace(new RegExp(oldText, "g"), newText);
          });
        }
        cell.alignment = { wrapText: true };
      });
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
};
export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      reject(new TypeError("The provided argument is not a File or Blob."));
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      const mimeType = file.type;
      const dataUrl = `data:${mimeType};base64,${base64String}`;
      resolve(dataUrl);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};
export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
export const createUrlObj = (name, url) => {
  return [
    {
      uid: name,
      name: name,
      status: "done",
      url: url,
    },
  ];
};
export const createUrlObjForMultiple = (name, urlArray) => {
  return urlArray.map(element => {
    return {
      uid: Math.random(),
      name: name,
      status: "done",
      url: element,
    }
  });
};
export const triggerConfirmation = async (
  title,
  text,
  icon,
  confirmText,
  cancelButton = true
) => {
  return new Promise((resolve) => {
    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: cancelButton,
      confirmButtonColor: "#3085d6",
      confirmButtonText: confirmText,
    }).then((result) => {
      if (result.isConfirmed) {
        resolve();
      }
    });
  });
};

export function toSnakeCase(str) {
  return str.toLowerCase().replace(/\s+/g, "_");
}
export function localFormattedDate(str, format = "DD-MM-YYYY hh:mm A") {
  if (!str) return "";
  return dayjs(str.toLocaleString()).format(format);
}
