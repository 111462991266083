import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useToast, jsonParser } from "../../utils/helpers/CommFun";
import api from "../../services/Api";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { API_URL, Role, IS_REQUIRED } from "../../utils/constant/Constant";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { HolderOutlined, DeleteOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import SkeletonForm from "../../components/DynamicForm/SkeletonForm";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientBranches } from "../../redux/slices/clientBranchSlice";
import { fetchAllClients } from "../../redux/slices/clientSlice";
import { fetchAllCategories } from "../../redux/slices/categorySlice";
import {
  createOrUpdateDocConfig,
  fetchDocPoints,
  fetchPerticularDocConfig,
} from "../../redux/slices/docConfigSlice";
import { fetchAllField } from "../../redux/slices/fieldSlice";

const RowContext = React.createContext({});

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: "move",
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};
const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
        position: "relative",
        zIndex: 1,
      }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners]
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};
function AddNewDocumentConfig() {
  const dispatch = useDispatch();
  const { fields } = useSelector((state) => state.field);
  const { clients } = useSelector((state) => state.client);
  const { clientBranches } = useSelector((state) => state.clientBranch);
  const { categories } = useSelector((state) => state.category);
  const { docPoints, selectedDocConfig } = useSelector(
    (state) => state.docConfig
  );

  const { userData, token } = useSelector((state) => state.auth);

  //Doc ID for Update
  const location = useLocation();
  const [docID, setDocID] = useState(location.state?.doc_id || null);

  //Form InitialValues
  const [initialValue, setInitialValue] = useState(null);
  //PDF FILE AND EXCEL FILE FOR CONVERSION
  const [pdfFile, setPdfFile] = useState(null);
  const [excelFile, setExcelFile] = useState();


  const [template, setTemplate] = useState([]);
  // const [dataSource, setDataSource] = React.useState(initialData);
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setTemplate((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.fieldDisplayName === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.fieldDisplayName === over?.id
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const navigate = useNavigate();

  const Toast = useToast();

  const closeRef = useRef();
  const closeModal = () => closeRef.current.click();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const openRef = useRef();
  const openModal = () => openRef.current.click();

  //Clears/Resets the Fields
  const clear = () => {
    setInitialValue(null);
    setPdfFile(null);
    setExcelFile(null);
  };
  const onCancelButtonClick = () => {
    navigate(-1);
    clear();
  };

  useEffect(() => {
    dispatch(fetchAllField({}));
    dispatch(fetchDocPoints({}));
    dispatch(fetchAllClients({}));
    dispatch(fetchAllCategories({}));
  }, []);

  useEffect(() => {
    if (docID) {
      const body = {
        doc_id: docID,
      };
      dispatch(fetchPerticularDocConfig(body));
    }
  }, [docID]);

  useEffect(() => {
    if (selectedDocConfig) {
      setInitialValue({
        client: selectedDocConfig.client_id,
        docID: docID,
        verificationType: selectedDocConfig.points_id,
        branch: selectedDocConfig.branch_id || "",
      });
      const flattenedFields = Object.values(selectedDocConfig.template).flat();
      setTemplate(flattenedFields);
    }
  }, [selectedDocConfig]);

  useEffect(() => {
    if (initialValue?.client) {
      const body = {
        client_id: initialValue.client,
      };
      dispatch(fetchClientBranches(body));
    }
    setInitialValue((prev) => {
      return { ...prev, branch: null };
    });
  }, [initialValue?.client]);


  useEffect(() => {
    getReportPdf();
  }, [initialValue?.report]);

  //Get the Template list
  const getTemplateList = async () => {
    try {
      setTemplate([]);
      if (
        !initialValue.verificationType ||
        initialValue.verificationType == "NoneType"
      ) {
        Toast.fire({ icon: "error", title: "Verification Type required" });
        return false;
      }
      const ver_id = parseInt(initialValue.verificationType);
      const body = {
        verification_type: ver_id,
      };
      const response = await api.post(API_URL.GET_DOC_COPY_FROM, body, token);
      if (response.status === 200) {
        setTemplate(jsonParser(response.result.template));
      } else {
        console.error("Failed to fetch client list");
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching the Template list",
        error
      );
    } finally {
    }
  };
  //Get the Template list
  const getReportPdf = async () => {
    try {
      if (initialValue?.report && initialValue?.report[0].status == "done") {
        openModal();
        setPdfFile(null);
        setExcelFile(null);
        const body = new FormData();
        body.append("file", initialValue.report[0].originFileObj);
        const response = await api.postFormData(
          API_URL.UPLOAD_REPORT,
          body,
          token
        );
        if (response.status === 200) {
          setPdfFile(response.result.pdf);
          setExcelFile(response.result.excel);
        } else {
          console.error("Failed to fetch client list");
        }
      }
    } catch (error) {
      console.error("An error occurred while fetching the report pdf", error);
    } finally {
    }
  };

  //Creates the fields
  const createField = async () => {
    try {
      const { field, category, is_required, options } = initialValue;

      if (!field) {
        Toast.fire({ icon: "error", title: "Field required" });
        return false;
      }

      if (!category) {
        Toast.fire({ icon: "error", title: "Category required" });
        return false;
      }

      if (!is_required) {
        Toast.fire({ icon: "error", title: "Is required field is required" });
        return false;
      }

      const foundField = fields.find((f) => f.id === field);
      const foundCategory = categories.find((c) => c.id === category);

      if (!foundField || !foundCategory) {
        Toast.fire({ icon: "error", title: "Invalid field or category" });
        return false;
      }

      if (template.some((item) => item.fieldName === foundField.name)) {
        Toast.fire({ icon: "error", title: "Field already exists" });
        return false;
      }

      const fieldType = foundField.type;
      let fieldTypeName;

      switch (fieldType) {
        case 1:
          fieldTypeName = "Text";
          break;
        case 2:
          fieldTypeName = "Number";
          break;
        case 3:
          fieldTypeName = "Dropdown";
          break;
        case 4:
          fieldTypeName = "Checkbox";
          break;
        case 5:
          fieldTypeName = "Date";
          break;
        case 6:
          fieldTypeName = "File";
          break;
        default:
          Toast.fire({ icon: "error", title: "Invalid field type" });
          return false;
      }

      const optionsCollection = foundField.options
        ? foundField.options.toString().split(",")
        : [];

      const data = {
        fieldName: foundField.name,
        fieldDisplayName: foundField.display_name,
        categoryDisplayName: foundCategory.name,
        fieldType: fieldTypeName,
        is_required_field: is_required === "1" ? "Yes" : "No",
        options: optionsCollection,
        fieldValue: null,
      };

      setTemplate((prevTemplate) => [...prevTemplate, data]);
      setInitialValue((prev) => ({ ...prev, field: null, options: null }));
    } catch (error) {
      console.error(error);
    } finally {
      // no-op
    }
  };

  const checkValidation = () => {
    if (template && template.length == 0) {
      Toast.fire({ icon: "error", title: "Other Fields are required" });
      return false;
    }
    // if (!report || report == null || report == "") {
    //   Toast.fire({ icon: "error", title: "Report Required" });
    //   return false;
    // }
    // if (!excelFile || excelFile == null || report == "") {
    //   Toast.fire({ icon: "error", title: "Wait Till Report Get's Uploaded" });
    //   return false;
    // }
    return true;
  };

  //Set Array 

  const groupByCategory = () => {
    const groupedData = template.reduce((acc, item) => {
      const category = item.categoryDisplayName;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});

    return groupedData;
  }

  // Creaet Document
  const handleCreateOrUpdateDocument = async (values) => {
    if (!checkValidation()) {
      return false;
    }
    let master_id;

    const finalTemplate = await groupByCategory();

    const body = {
      client_id: parseInt(values.client),
      points_id: parseInt(values.verificationType),
      template: finalTemplate,
      user_id: userData.id,
      report_url: excelFile ? excelFile : null,
    };
    if (userData.role == Role.BRANCH_MANAGER) {
      body.master_id = userData.parent_id;
    } else {
      body.master_id = userData.id;
    }
    if (values.branch && values.branch != undefined) {
      body.branch_id = values.branch;
    }
    const isUpdate = values.docID ? true : false;
    dispatch(createOrUpdateDocConfig({ body, isUpdate }))
      .unwrap()
      .then((response) => {
        onCancelButtonClick();
      })
      .catch((error) => {
        console.error("Error creating/updating client:", error);
      });
  };

  //delete field
  const deleteField = async (data) => {
    Swal.fire({
      title: "Delete",
      text: `Are you sure you want to delete field?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const delField = template.splice(template.indexOf(data), 1);
        if (template.length <= 0) {
          setTemplate([]);
        } else {
          setTemplate((template) => [...template]);
        }
        if (delField) {
          Toast.fire({
            icon: "success",
            title: "Field Deleted",
          });
        } else {
          Toast.fire({
            icon: "error",
            title: "Issue Occure",
          });
        }
      }
    });
  };

  const columns = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "Sr No.",
      key: "index",
      render: (_, __, index) => index + 1, // Displays row index starting from 1
      className: "dt-left",
    },
    {
      dataIndex: "fieldDisplayName",
      title: "Field Name",
      className: "dt-left",
    },
    {
      dataIndex: "categoryDisplayName",
      title: "Category Name",
      className: "dt-left",
    },
    { dataIndex: "fieldType", title: "Field Type", className: "dt-left" },
    {
      dataIndex: "is_required_field",
      title: "Is Required",
      className: "dt-left",
    },
    {
      dataIndex: "options",
      title: "Options",
      className: "dt-left",
      render: (options) => (options ? options.join(', ') : "-"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <DeleteOutlined
            style={{ color: "#D92E38", cursor: "pointer" }}
            onClick={() => deleteField(record)} // Function to handle delete
          />
        </div>
      ),
      className: "dt-center",
    },
  ];

  const formConfig = [
    {
      label: "Client",
      name: "client",
      type: "select",
      rules: [{ required: true, message: "Please Select One Option" }],
      options: clients.map((item) => ({
        value: item.id,
        label: `${item.name} (${item.short_code})`,
      })),
      colSpan: 8,
    },
    {
      label: "Branch (Optioinal)",
      name: "branch",
      type: "select",
      options: clientBranches.map((item) => ({
        value: item.id,
        label: item.branch_name,
      })),
      colSpan: 8,
    },
    {
      label: "Verification Type",
      name: "verificationType",
      type: "select",
      rules: [{ required: true, message: "Please Select One Option" }],
      options: docPoints.map((item) => ({ value: item.id, label: item.name })),
      colSpan: 8,
    },
    {
      label: "Field",
      name: "field",
      type: "select",
      options: fields.map((item) => ({
        value: item.id,
        label: item.display_name,
      })),
      colSpan: 7,
    },
    {
      label: "Category",
      name: "category",
      type: "select",
      options: categories.map((item) => ({
        value: item.id,
        label: item.name,
      })),
      rules: [{ required: true, message: "Please Select One Category" }],
      colSpan: 7,
    },
    {
      label: "Is Required",
      name: "is_required",
      type: "select",
      options: IS_REQUIRED,
      rules: [{ required: true, message: "Please Select Is Required" }],
      colSpan: 7,
    },
    {
      type: "button",
      label: "Add Field",
      onClick: () => {
        createField();
      },
      colSpan: 3, // Optional: adjust as needed
    },
    {
      label: "Report",
      name: "report",
      type: "upload_new",
      accept: ".xlsx,.xls",
      colSpan: 6,
    },
    pdfFile != null && {
      label: "Preview",
      type: "button",
      onClick: () => {
        openModal();
      },
      colSpan: 2, // Optional: adjust as needed
    },
    // {
    //   type: "button",
    //   label: "Copy From",
    //   onClick: () => {
    //     getTemplateList();
    //   },
    //   colSpan: 4,
    // },
    {
      label: "docID",
      name: "docID",
      type: "hidden",
      initialValue: docID,
    },
  ];
  const handleFieldChange = (changedValues) => {
    setInitialValue((prev) => {
      return { ...prev, ...changedValues };
    });
  };

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5>Document Management</h5>
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
          <section id="client-management-form">
            <div className="add_user-box">
              <div className="add_user-form">
                {
                  (formConfig && docID && !initialValue)
                    ?
                    (
                      <SkeletonForm
                        config={formConfig}
                        onFinish={handleCreateOrUpdateDocument}
                        initialValues={initialValue}
                        handleFieldChange={handleFieldChange}
                        onCancel={onCancelButtonClick}
                      />

                    ) : (
                      (formConfig && (docID && initialValue)) || (formConfig && !docID) ? (
                        <DynamicForm
                          config={formConfig}
                          onFinish={handleCreateOrUpdateDocument}
                          initialValues={initialValue}
                          handleFieldChange={handleFieldChange}
                          onCancel={onCancelButtonClick}
                        />
                      ) : ""
                    )}
              </div>
            </div>
          </section>

          <div className="sub-header sub-header-2">
            <div>
              {/* {template.length > 0 && ( */}
              <DndContext
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={onDragEnd}
              >
                <SortableContext
                  items={template.map((i) => i.fieldDisplayName)}
                  strategy={verticalListSortingStrategy}
                >
                  <Table
                    dataSource={template}
                    columns={columns}
                    rowKey="fieldDisplayName"
                    components={{
                      body: {
                        row: Row,
                      },
                    }}
                  />
                </SortableContext>
              </DndContext>
              {/* <Table
                className="Doc-config"
                data={template}
                columns={columns}
                isReorderable={true}
                setData={setTemplate}
                ondelete={deleteField}
              /> */}
              {/* )} */}
            </div>

            <div
              className="d-none"
              ref={openRef}
              data-bs-toggle="modal"
              data-bs-target="#reportModal"
            >
              Preview
            </div>
            <div
              className="modal fade"
              id="reportModal"
              tabIndex={-1}
              aria-labelledby="reportModal"
              data-bs-backdrop="static"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-xl">
                {/* Modal content*/}
                <div className="modal-content">
                  {/* modal header  */}
                  <div className="modal-header">
                    <h5 className="text-center">Report Preview</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      ref={closeRef}
                    />
                  </div>
                  <div className="sub-header">
                    <h6>Report</h6>
                  </div>
                  {/* modal body  */}
                  <div className="modal-body">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "600px",
                        width: "auto",
                      }}
                    >
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        {pdfFile ? (
                          <Viewer
                            plugins={[defaultLayoutPluginInstance]}
                            fileUrl={pdfFile}
                            defaultScale={1}
                          />
                        ) : (
                          <div className="loader"></div>
                        )}
                      </Worker>
                    </div>
                  </div>
                </div>
              </div>
              {/* Chat Modal End*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewDocumentConfig;
