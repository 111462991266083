// features/user/userSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import { createOrUpdateData, deleteData, fetchData } from "../utils/apiActions";

export const fetchAllPincodes = fetchData("pincode", API_URL.GET_ALL_PINCODE);
export const fetchPincodes = fetchData("pincode", API_URL.GET_PINCODE,'fetchFiltered');
export const fetchPerticularPincodes = fetchData(
  "pincode",
  API_URL.GET_PARTICULAR_PINCODE,
  "fetchPerticular"
);

export const createOrUpdatePincode = createOrUpdateData(
  "pincode",
  API_URL.CREATE_PINCODE,
  API_URL.UPDATE_PINCODE
);

export const deletePincode = deleteData("pincode", API_URL.REMOVE_PINCODE);

const pincodeSlice = createSlice({
  name: "pincode",
  initialState: {
    pincodes: [],
    selectedPincode: null,
    status: STATUS_TYPE.IDLE,
    total: 0,
    error: null,
  },
  reducers: {
    pincodeStatusReset: (state) => {
      state.status = STATUS_TYPE.IDLE;
    },
    resetSelectedPincode: (state) => {
      state.selectedPincode = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPincodes.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllPincodes.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.pincodes = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchAllPincodes.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchPincodes.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchPincodes.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.pincodes = action.payload;
      })
      .addCase(fetchPincodes.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchPerticularPincodes.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchPerticularPincodes.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.selectedPincode = action.payload;
      })
      .addCase(fetchPerticularPincodes.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(createOrUpdatePincode.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(createOrUpdatePincode.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        // Optional: Handle additional logic on successful pincode creation/updation
      })
      .addCase(createOrUpdatePincode.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(deletePincode.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(deletePincode.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(deletePincode.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      });
  },
});

export const { pincodeStatusReset, resetSelectedPincode } =
  pincodeSlice.actions;
export default pincodeSlice.reducer;
