import React, { useState, useEffect } from "react";
import {
  localFormattedDate,
  triggerConfirmation,
  useToast,
} from "../../utils/helpers/CommFun";
import { useNavigate } from "react-router-dom";

import { Role } from "../../utils/constant/Constant";
import { Form, Select, Row, Col, Tooltip, Space } from "antd";
import AntdTable from "../../components/AntdTable/Table";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { deleteCase, fetchAllCases } from "../../redux/slices/caseSlice";
import SearchBar from "../../components/SearchBar";
import { fetchAllClientBranches } from "../../redux/slices/clientBranchSlice";
import { fetchAllClients } from "../../redux/slices/clientSlice";

function CaseManagement() {
  const dispatch = useDispatch();
  const { clientBranches } = useSelector((state) => state.clientBranch);
  const { clients } = useSelector((state) => state.client);
  const { Cases, total } = useSelector((state) => state.Case);
  const { userData } = useSelector((state) => state.auth);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(fetchAllClientBranches({}));
    dispatch(fetchAllClients({}));
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [dispatch, pageNo, pageSize, searchQuery]);

  const fetchData = () => {
    const body = {
      pageNo: pageNo,
      pageSize: pageSize,
      search: searchQuery,
    };
    dispatch(fetchAllCases(body));
  };

  //get token

  const [filterForm] = Form.useForm();
  const [filterValues, setFilterValues] = useState();
  const [filteredList, setFilteredList] = useState([]);
  const navigate = useNavigate();

  //sweet alert
  const Toast = useToast();

  //Delete the case
  const handleDeleteCase = async (caseRecord, forceDelete = false) => {
    const confirmationMessage = forceDelete
      ? "Case has Applicant, Do you still want to delete?"
      : "Are you sure you want to delete this case?";

    await triggerConfirmation(
      "Delete",
      confirmationMessage,
      "warning",
      "Delete"
    ).then(async (result) => {
      // try {
      const body = {
        case_id: caseRecord.id,
        force_delete: forceDelete,
      };
      dispatch(deleteCase(body))
        .unwrap()
        .then(() => {
          const body = {
            pageNo: pageNo,
            pageSize: pageSize,
            search: searchQuery,
          };
          dispatch(fetchAllCases(body));
        })
        .catch((err) => {
          handleDeleteCase(caseRecord, true);
        });
      //   const response = await api.post(API_URL.DELETE_CASE, body, token);
      //   if (response.status === 200) {
      //     Toast.fire({
      //       icon: "success",
      //       title: response.message,
      //     });
      //     setPointList([]);
      //   } else {
      //     handleDeleteCase(caseRecord, true);
      //   }
      // } catch (error) {
      //   console.error(error);
      // } finally {
      // }
    });
  };

  const onAddCase = async () => {
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/case/addCase");
    } else if (
      userData.role == Role.BANKS ||
      userData.role == Role.MASTER_VENDOR
    ) {
      navigate("/admin/case/addCase");
    } else if (
      userData.role == Role.BRANCH_MANAGER ||
      userData.role == Role.TEAM_LEAD ||
      userData.role == Role.BACK_OFFICE
    ) {
      navigate("/user/case/addCase");
    } else {
      navigate("/login");
    }
  };

  const onView = async (caseId) => {
    const data = {
      case_id: caseId.id,
    };
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/case/edit", { state: data });
    } else if (
      userData.role == Role.BANKS ||
      userData.role == Role.MASTER_VENDOR
    ) {
      navigate("/admin/case/edit", { state: data });
    } else if (
      userData.role == Role.BRANCH_MANAGER ||
      userData.role == Role.TEAM_LEAD ||
      userData.role == Role.BACK_OFFICE
    ) {
      navigate("/user/case/edit", { state: data });
    } else {
      navigate("/login");
    }
  };

  const onEdit = async (caseId) => {
    const data = caseId;
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/case/addCase", { state: data });
    } else if (
      userData.role == Role.BANKS ||
      userData.role == Role.MASTER_VENDOR
    ) {
      navigate("/admin/case/addCase", { state: data });
    } else if (
      userData.role == Role.BRANCH_MANAGER ||
      userData.role == Role.TEAM_LEAD ||
      userData.role == Role.BACK_OFFICE
    ) {
      navigate("/user/case/addCase", { state: data });
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    setFilteredList(Cases);
  }, [Cases]);

  const columns = [
    {
      title: "#",
      key: "srNo",
      render: (_, __, index) => (pageNo - 1) * pageSize + (index + 1), // Renders the row number
      className: "dt-left",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "dt-left",
      sortable: true, // Enable sort
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      className: "dt-left",
      render: (branch) => (branch ? branch.branch_name : "-"), // Render branch name or "-"
      sortable: true, // Enable sort
    },
    {
      title: "Client",
      dataIndex: "branch",
      key: "client",
      className: "dt-left",
      render: (branch) =>
        branch && branch.client_id ? branch.client_id.name : "-", // Render client name or "-"
      sortable: true,
      width: "10%"
    },
    {
      title: "Received At",
      dataIndex: "received_at",
      key: "received_at",
      className: "dt-left",
      render: (received_at) => localFormattedDate(received_at),
      sortable: true,
    },
    {
      title: "Estimated Tat",
      dataIndex: "esitmated_tat",
      key: "esitmated_tat",
      className: "dt-left",
      render: (esitmated_tat) => localFormattedDate(esitmated_tat),
      sortable: true, // Enable sort
    },
    {
      title: "Points",
      dataIndex: "no_of_points",
      key: "totalPoints",
      className: "dt-left",
      render: (points) => points, // Render total points or "-"
      sortable: true, // Enable sort
      width: "7%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "dt-left",
      render: (status) => {
        switch (status) {
          case 0:
            return "Pending";
          case 1:
            return "Complete";
          default:
            return "-";
        }
      },
      sortable: true, // Enable sort
    },
    {
      title: "Action",
      key: "action",
      className: "dt-center",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="View">
            <EyeOutlined
              style={{ color: "#2e4fd9", cursor: "pointer" }}
              onClick={() => onView(record)}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <EditOutlined
              style={{ color: "#000", cursor: "pointer" }}
              onClick={() => onEdit(record)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined
              style={{ color: "#D92E38", cursor: "pointer" }}
              onClick={() => handleDeleteCase(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleFilterChange = (changedValues) =>
    setFilterValues((prev) => {
      return { ...prev, ...changedValues };
    });

  useEffect(() => {
    const cases = Cases.filter((obj) => {
      const statusMatch =
        filterValues?.status == undefined || obj.status == filterValues?.status;
      const branchMatch =
        filterValues?.branch == undefined ||
        obj.branch.branch_name == filterValues?.branch;
      const clientMatch =
        filterValues?.client == undefined ||
        obj.branch.client_id.name == filterValues?.client;
      return statusMatch && branchMatch && clientMatch;
    });
    setFilteredList(cases);
  }, [filterValues]);

  return (
    <>
      <div className="main-contant">
        <div className="container-fluid">
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-5">
                  <div className="title-box">
                    <h5 className="bottom-line">Case Management</h5>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="d-flex justify-content-end">
                    <SearchBar
                      setSearchQuery={setSearchQuery}
                      fetchData={fetchData}
                    />
                    <div className="button-box">
                      <ul className="">
                        <li>
                          <button onClick={onAddCase} className="blue-btn">
                            + Add New
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body row">
                <Form
                  form={filterForm}
                  name="filterForm"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: "0 auto" }}
                  onValuesChange={handleFilterChange}
                  autoComplete="off"
                >
                  <Row gutter={[16, 24]}>
                    <Col span={8}>
                      <Form.Item label="Client" name="client">
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder="Select an option"
                          style={{ width: "100%" }}
                          popupClassName="custom-dropdown"
                        >
                          {clients.map((option, i) => (
                            <Select.Option key={i} value={option.name}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Branch" name="branch">
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder="Select an option"
                          style={{ width: "100%" }}
                          popupClassName="custom-dropdown"
                        >
                          {clientBranches.map((option, i) => (
                            <Select.Option key={i} value={option.branch_name}>
                              {option.branch_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="Status" name="status">
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder="Select an option"
                          style={{ width: "100%" }}
                          popupClassName="custom-dropdown"
                        >
                          <Select.Option key={0} value={0}>
                            Pending
                          </Select.Option>
                          <Select.Option key={1} value={1}>
                            Complete
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
            <section id="case-management">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <AntdTable
                      dataSource={filteredList}
                      setMinHeight={true}
                      columns={columns}
                      pageNo={pageNo}
                      pageSize={pageSize}
                      setPageNo={setPageNo}
                      setPageSize={setPageSize}
                      total={total}
                    />
                    <div id="excel-table"></div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default CaseManagement;
