import React, { useEffect, useState } from "react";
import DynamicForm from "../../../components/DynamicForm/DynamicForm";
import { useToast } from "../../../utils/helpers/CommFun";
import { Role } from "../../../utils/constant/Constant";
import {
  fetchStateCity,
  resetStateCity,
} from "../../../redux/slices/stateCitySlice";
import { fetchAllBranchManager } from "../../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { createOrUpdateVendorBranch } from "../../../redux/slices/vendorBranchSlice";

const BranchBasicForm = ({ setActiveKey, formValues, FetchBranchDetails }) => {
  const dispatch = useDispatch();
  const { branchManagers } = useSelector((state) => state.user);
  const { pincodeId, city, state } = useSelector((state) => state.stateCity);
  const { userData } = useSelector((state) => state.auth);

  const [initialValue, setInitialValue] = useState(formValues);

  useEffect(() => {
    dispatch(resetStateCity());

    const body = { parent_id: userData.id };
    dispatch(fetchAllBranchManager(body));
  }, [dispatch]);

  useEffect(() => {
    setInitialValue((prev) => {
      return {
        ...prev,
        pincodeId,
        city,
        state,
      };
    });
  }, [pincodeId]);

  useEffect(() => {
    dispatch(fetchStateCity(initialValue?.pincode));
  }, [initialValue?.pincode]);

  const basicFormConifg = [
    {
      label: "Branch Name",
      name: "branchName",
      type: "input",
      rules: [{ required: true, message: "Please Enter Branch Name" }],
      colSpan: 12,
    },
    {
      label: "Branch Code",
      name: "branchCode",
      type: "input",
      rules: [{ required: true, message: "Please Enter Branch Code" }],
      colSpan: 12,
    },
    { label: "Address", name: "address", type: "input", colSpan: 12 },
    {
      label: "Branch Pincode",
      name: "pincode",
      type: "input",
      rules: [
        { required: true, message: "Please Enter Pincode" },
        { pattern: /^[0-9]{6}$/, message: "Pincode must be exactly 6 digits" },
      ],
      colSpan: 12,
    },
    {
      label: "Branch City",
      name: "city",
      type: "input",
      rules: [
        { required: true, message: "Please Enter Valid Pincode to Fetch City" },
      ],
      disabled: true,
      colSpan: 12,
    },
    {
      label: "Branch State",
      name: "state",
      type: "input",
      rules: [
        {
          required: true,
          message: "Please Enter Valid Pincode to Fetch State",
        },
      ],
      disabled: true,
      colSpan: 12,
    },
    {
      label: "pincodeId",
      name: "pincodeId",
      type: "hidden",
      initialValue: null,
    },
    userData.role == Role.MASTER_VENDOR && {
      label: "Branch Manager",
      name: "branchManager",
      type: "select",
      options: branchManagers.map((bm) => ({
        value: bm.id,
        label: bm.first_name,
      })),
      colSpan: 12,
    },
    userData.role == Role.MASTER_VENDOR && {
      label: "Branch Manager Mobile No.",
      name: "mobile",
      type: "number-input",
      rules: [
        {
          pattern: /^[0-9]{10}$/,
          message: " Please Enter 10 Digit Mobile No.",
        },
      ],
      disabled: true,
      colSpan: 12,
    },
    {
      label: "Branch ID",
      name: "branchId",
      type: "hidden",
      initialValue: null,
      colSpan: 12,
    },
  ];

  //HANDLE FIELD CHANGE
  const handleFieldChange = (changedValues) => {
    if (changedValues.pincode) {
      if (String(changedValues.pincode).length === 6) {
        dispatch(fetchStateCity(changedValues.pincode));
      } else {
        dispatch(resetStateCity());
        setInitialValue((prev) => {
          return { ...prev, pincodeId: null, city: null, state: null };
        });
      }
    }
    if (changedValues.branchManager) {
      const mobile = branchManagers.find(
        (element) => element.id == changedValues.branchManager
      ).mobile;
      setInitialValue((prev) => {
        return { ...prev, mobile };
      });
    }
    setInitialValue((prev) => ({ ...prev, ...changedValues }));
  };
  const handleUpdateBranch = async (values) => {
    const body = {
      name: values.branchName,
      address: values.address,
      parent_id: parseInt(userData.id),
      user_id: userData.id,
      branch_code: values.branchCode,
      city: values.city,
      state: values.state,
      pincode_id: values.pincodeId,
      bm_id: values.branchManager,
      master_branch_id: values.branchId,
      mobile: values.mobile,
    };
    const isUpdate = true;
    dispatch(createOrUpdateVendorBranch({ body, isUpdate }))
      .unwrap()
      .then((response) => {
        FetchBranchDetails();
        setActiveKey("1");
      })
      .catch((error) => {});
  };

  return (
    <>
      <DynamicForm
        config={basicFormConifg}
        initialValues={initialValue}
        handleFieldChange={handleFieldChange}
        onFinish={(data) => handleUpdateBranch(data)}
        onCancel={() => setActiveKey("1")}
        submitText="Save"
      />
    </>
  );
};

export default BranchBasicForm;
