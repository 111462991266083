import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Role } from "../../utils/constant/Constant";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import AntdTable from "../../components/AntdTable/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDocConfig,
  fetchAllDocConfigs,
  resetSelectedDoc,
  updateDocConfigStatus,
} from "../../redux/slices/docConfigSlice";
import SearchBar from "../../components/SearchBar";

function DocumentConfiguration() {
  const dispatch = useDispatch();

  const { docConfigs, total,status } = useSelector((state) => state.docConfig);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(resetSelectedDoc());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [dispatch, pageNo, pageSize,searchQuery]);



  const fetchData = () => {
    const body = {
      pageNo: pageNo,
      pageSize: pageSize,
      search: searchQuery,
    };
    dispatch(fetchAllDocConfigs(body));
  };

  const navigate = useNavigate();

  const { userData } = useSelector((state) => state.auth);

  const handleUpdateDocStatus = async (document) => {
    let status = document.status == 1 ? 0 : 1;
    const body = {
      doc_id: document.id,
      status: status,
    };
    dispatch(updateDocConfigStatus(body))
      .unwrap()
      .then(() => {})
      .catch((err) => {});
  };

  //Delete Document
  const handleDeleteDocConfig = async (document) => {
    Swal.fire({
      title: "Delete",
      text: `Are you sure you want to delete document config?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = {
          doc_id: document.id,
        };
        dispatch(deleteDocConfig(body))
          .unwrap()
          .then(() => {
            fetchData();
          })
          .catch((err) => {});
      }
    });
  };

  const columns = [
    {
      title: "#",
      key: "index",
      width:"10%",
      render: (_, __, index) => (pageNo - 1) * pageSize + (index + 1),
      className: "dt-left",
    },
    {
      dataIndex: "client_name",
      title: "Client Name",
      className: "dt-left",
      width:"20%",
      sortable: true,
    },
    {
      dataIndex: "verification_type",
      title: "Verification Type",
      className: "dt-left",
      width:"20%",
      sortable: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      width:"20%",
      key: "status",
      render: (status, record) => (
        <Switch
          checked={status == 1}
          onChange={() => handleUpdateDocStatus(record)} // Function to handle status change
        />
      ),
      className: "dt-center",
    },
    {
      title: "Action",
      width:"20%",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <EditOutlined
            style={{ color: "#000000", marginRight: 10, cursor: "pointer" }}
            onClick={() => onredirect(record)} // Function to handle edit
          />
          <DeleteOutlined
            style={{ color: "#D92E38", cursor: "pointer" }}
            onClick={() => handleDeleteDocConfig(record)} // Function to handle delete
          />
        </div>
      ),
      className: "dt-center",
    },
  ];

  const onredirect = async (doc) => {
    const data = {
      doc_id: doc.id,
    };
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/documentconfiguration/addnewdocumentconfig/", {
        state: data,
      });
    } else if (
      userData.role == Role.BANKS ||
      userData.role == Role.MASTER_VENDOR
    ) {
      navigate("/admin/documentconfiguration/addnewdocumentconfig/", {
        state: data,
      });
    } else if (
      userData.role == Role.BRANCH_MANAGER ||
      userData.role == Role.TEAM_LEAD ||
      userData.role == Role.BACK_OFFICE
    ) {
      navigate("/user/documentconfiguration/addnewdocumentconfig/", {
        state: data,
      });
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <div className="main-contant">
        <div className="container-fluid">
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-5">
                  <div className="title-box">
                    <h5>Document Configuration</h5>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="d-flex justify-content-end">
                    <SearchBar
                      setSearchQuery={setSearchQuery}
                      fetchData={fetchData}
                    />
                    <div className="button-box">
                      <ul className="">
                        <li>
                          <Link
                            to={
                              [Role.SUPER_ADMIN].some(
                                (role) => userData.role == role
                              )
                                ? "/super-admin/documentconfiguration/addnewdocumentconfig"
                                : [Role.BANKS, Role.MASTER_VENDOR].some(
                                    (role) => userData.role == role
                                  )
                                ? "/admin/documentconfiguration/addnewdocumentconfig"
                                : "/user/documentconfiguration/addnewdocumentconfig"
                            }
                            className="blue-btn"
                          >
                            + Add New
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AntdTable
              dataSource={docConfigs}
              columns={columns}
              status={status}
              setMinHeight={true}
              pageNo={pageNo}
              pageSize={pageSize}
              setPageNo={setPageNo}
              setPageSize={setPageSize}
              total={total}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentConfiguration;
