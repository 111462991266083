import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import logo from "../../assets/images/logo.png";
import {
  useToast,
  validateEmail,
  validateStr,
} from '../../utils/helpers/CommFun';
import api from '../../services/Api';
import loginBg from '../../assets/images/login-bg.png';
import { API_URL } from '../../utils/constant/Constant';

const ResetPassword = () => {
  const [form] = Form.useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswVisible, setConfirmPassVisible] = useState(false);
  const [resetState, setResetState] = useState(false);
  const [disableToggle, setDisableToggle] = useState(false);
  const navigate = useNavigate();
  const Toast = useToast();
  const { reset_id } = useParams();

  useEffect(() => {
    if (reset_id) {
      setResetState(true);
    } else {
      setResetState(false);
    }
  }, [reset_id]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPassVisible(!confirmPasswVisible);
  };

  const handleReset = async (values) => {
    const { password, confirmPassword } = values;

    if (!validateStr(password)) {
      Toast.fire({
        icon: 'error',
        title: 'Password Required',
      });
      return;
    }

    if (!validateStr(confirmPassword)) {
      Toast.fire({
        icon: 'error',
        title: 'Confirm Password Required',
      });
      return;
    }

    if (password !== confirmPassword) {
      Toast.fire({
        icon: 'error',
        title: 'Passwords Should be Same',
      });
      return;
    }

    const body = { reset_password_token: reset_id, password };

    try {
      const response = await api.post(API_URL.RESET_PASSWORD, body);
      if (response.status === 200) {
        Toast.fire({
          icon: 'success',
          title: response.message,
        });
        navigate('/login');
      } else {
        Toast.fire({
          icon: 'error',
          title: response.message,
        });
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      Toast.fire({
        icon: 'error',
        title: 'An error occurred while resetting the password.',
      });
    }
  };

  const handleSubmit = async (values) => {
    setDisableToggle(true);

    const { email } = values;

    if (!validateEmail(email)) {
      Toast.fire({
        icon: 'error',
        title: 'Valid Email Required',
      });
      setDisableToggle(false);
      return;
    }

    try {
      const body = { email };
      const response = await api.post(API_URL.FORGOT_PASSWORD, body);
      if (response.status === 200) {
        Toast.fire({
          icon: 'success',
          title: response.message,
        });
        navigate('/login');
      } else {
        Toast.fire({
          icon: 'error',
          title: response.message,
        });
      }
    } catch (error) {
      console.error('Error submitting forgot password request:', error);
      Toast.fire({
        icon: 'error',
        title: 'An error occurred while submitting the request.',
      });
    } finally {
      setDisableToggle(false);
    }
  };

  return (
    <section id="login" className="com-form">
      <div className="login-part">
        <div className="container-fluid">
          <div className="login-box">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="left">
                  <div className="img-box">
                    <img src={loginBg} alt="Login Background" />
                    <div className="text-box">
                      <p>Nice to see you again</p>
                      <h3>Welcome Back</h3>
                      <div className="line"></div>
                      <h4>Login Now</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right-box">
                  <div className="login-box">
                    <div className="form-box">
                      <div className="logo-box">
                        <img src={logo} alt="logo" />
                      </div>
                      <Form
                        form={form}
                        onFinish={handleSubmit}
                        layout="vertical"
                      >
                        <Form.Item
                          label="User Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your email!",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            maxLength={50}
                            placeholder="Enter User Email"
                            autoComplete="off"
                            className="form-custom-control"
                          />
                        </Form.Item>

                        

                        <div className="Remember-box">
                          <Form.Item>
                            <Link
                              to="/login"
                              style={{
                                float: "right",
                                marginTop: "8px",
                                color: "blue",
                                fontSize: "16px",
                              }}
                              className="forget-link"
                            >
                              Back to Login ?
                            </Link>
                          </Form.Item>
                        </div>
                        <Form.Item>
                          <Button htmlType="submit" className="btns submit-btn">
                            Forgot Password
                          </Button>
                        </Form.Item>
                      </Form>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
