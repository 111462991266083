// features/user/userSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import { useToast } from "../../utils/helpers/CommFun";
import api from "../../services/Api";
import Dashboard from "../../pages/dashboard/Dashboard";
import { fetchData } from "../utils/apiActions";

export const login = createAsyncThunk(
  "auth/login",
  async (body, { rejectWithValue }) => {
    const Toast = useToast();
    try {
      const response = await api.post(API_URL.AUTH_LOGIN, body);
      if (response.status === 200) {
        localStorage.setItem("user_data", JSON.stringify(response.result));
        localStorage.setItem("token", response.result.access_token);
        return response;
      } else {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchUserData = createAsyncThunk(
  "auth/fetchUserData",
  async (body, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const response = await api.post(API_URL.GET_USER, body, token);
      if (response.status === 200) {
        localStorage.setItem("user_data", JSON.stringify(response.result));
        return response;
      } else {
        return rejectWithValue(response.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchDashboardData = fetchData(
  "auth",
  API_URL.AUTH_DASHBOARD,
  "Dashboard"
);

const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    userData: JSON.parse(localStorage.getItem("user_data")) || {},
    token: localStorage.getItem("token") || null,
    dashboardData: null,
    status: STATUS_TYPE.IDLE,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.userData = {};
      state.token = null;
      state.status = STATUS_TYPE.IDLE;
      state.error = null;

      // Clear localStorage on logout
      localStorage.removeItem("user_data");
      localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.userData = action.payload.result;
        state.token = action.payload.result.access_token;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchUserData.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.userData = action.payload.result;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchDashboardData.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.dashboardData = action.payload;
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      });
  },
});

export const { logout } = AuthSlice.actions;
export default AuthSlice.reducer;
