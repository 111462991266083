import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { userData,token } = useSelector((state) => state.auth);

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (userData && roles.includes(userData.role)) {
    return <Component {...rest} />;
  } else {
    return <Navigate to="/unauthorized" />;
  }
};

export default PrivateRoute;
