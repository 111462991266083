import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import userReducer from "../slices/userSlice";
import clientReducer from "../slices/clientSlice";
import holidayReducer from "../slices/holidaySlice";
import pincodeReducer from "../slices/pincodeSlice";
import fieldReducer from "../slices/fieldSlice";
import categoryReducer from "../slices/categorySlice";
import paymentReducer from "../slices/paymentSlice";
import vendorBranchReducer from "../slices/vendorBranchSlice";
import clientBranchReducer from "../slices/clientBranchSlice";
import stateCityReducer from "../slices/stateCitySlice";
import pickedCaseReducer from "../slices/pickedCaseSlice";
import CaseReducer from "../slices/caseSlice";
import docConfigReducer from "../slices/docConfigSlice";
import SearchReducer from "../slices/searchSlice";
import targetReducer from  "../slices/targetSlice"
import authReducer from "../slices/authSlice";

// Combine reducers
const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  client: clientReducer,
  clientBranch: clientBranchReducer,
  vendorBranch: vendorBranchReducer,
  holiday: holidayReducer,
  pincode: pincodeReducer,
  field: fieldReducer,
  category: categoryReducer,
  payment: paymentReducer,
  stateCity: stateCityReducer,
  pickedCase: pickedCaseReducer,
  Case: CaseReducer,
  docConfig: docConfigReducer,
  Search: SearchReducer,
  target:targetReducer
});

// Root reducer that handles RESET_STORE action
const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    state = undefined; // Reset the entire state
  }
  return appReducer(state, action);
};

// Create store with rootReducer
export const store = configureStore({
  reducer: rootReducer,
});

export default store;
