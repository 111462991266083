// features/user/userSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import {
  createOrUpdateData,
  deleteData,
  fetchData,
  updateStatus,
} from "../utils/apiActions";

export const fetchAllVendorBranches = fetchData(
  "vendorBranch",
  API_URL.GET_ALL_MASTER_BRANCH,
  "fetchAll"
);
export const fetchPerticularVendorBranch = fetchData(
  "vendorBranch",
  API_URL.GET_PERTICULAR_MASTER_BRANCH,
  "fetchPerticular"
);
export const fetchUserMasterBranch = fetchData(
  "user",
  API_URL.GET_USER_MASTER_BRANCH,
  "fetchUserMasterBranch"
);
export const addMasterBranchPincode = createOrUpdateData(
  "vendorBranch",
  API_URL.ADD_MASTER_BRANCH_PINCODE,
  API_URL.ADD_MASTER_BRANCH_PINCODE,
  "addMasterBranchPincode"
);
export const addMasterBranchHours = createOrUpdateData(
  "vendorBranch",
  API_URL.ADD_MASTER_BRANCH_HOURS,
  API_URL.ADD_MASTER_BRANCH_HOURS,
  "addMasterBranchHours"
);

export const createOrUpdateVendorBranch = createOrUpdateData(
  "vendorBranch",
  API_URL.CREATE_MASTER_BRANCH,
  API_URL.UPDATE_MASTER_BRANCH
);

export const updateVendorBranchStatus = updateStatus(
  "vendorBranch",
  API_URL.UPDATE_MASTER_BRANCH
);

export const deleteVendorBranch = deleteData(
  "vendorBranch",
  API_URL.DELETE_MASTER_BRANCH
);

const vendorBranchSlice = createSlice({
  name: "vendorBranch",
  initialState: {
    vendorBranches: [],
    selectedVendorBranch: null,
    total: 0,
    status: STATUS_TYPE.IDLE,
    total:0,
    error: null,
  },
  reducers: {
    vendorBranchStatusReset: (state) => {
      state.status = STATUS_TYPE.IDLE;
    },
    resetVendorBranchState: (state) => {
      state.vendorBranches = [];
      state.selectedVendorBranch = null;
      state.status = STATUS_TYPE.IDLE;
      state.total = 0;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllVendorBranches.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllVendorBranches.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.vendorBranches = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchAllVendorBranches.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchPerticularVendorBranch.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchPerticularVendorBranch.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.selectedVendorBranch = action.payload;
      })
      .addCase(fetchPerticularVendorBranch.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchUserMasterBranch.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchUserMasterBranch.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.vendorBranches = action.payload.data;
      })
      .addCase(fetchUserMasterBranch.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(addMasterBranchPincode.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(addMasterBranchPincode.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(addMasterBranchPincode.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(updateVendorBranchStatus.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(updateVendorBranchStatus.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        const data = action.payload;
        state.vendorBranches = state.vendorBranches.map((vendorBranch) =>
          vendorBranch.id === data.vendorBranch_id
            ? { ...vendorBranch, status: data.status }
            : vendorBranch
        );
      })
      .addCase(updateVendorBranchStatus.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload; // Error message from `rejectWithValue`
      })
      .addCase(createOrUpdateVendorBranch.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(createOrUpdateVendorBranch.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        // Optional: Handle additional logic on successful vendorBranch creation/updation
      })
      .addCase(createOrUpdateVendorBranch.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(deleteVendorBranch.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(deleteVendorBranch.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(deleteVendorBranch.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      });
  },
});

export const { vendorBranchStatusReset, resetVendorBranchState } =
  vendorBranchSlice.actions;
export default vendorBranchSlice.reducer;
