import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Layout from "./components/layouts/Layout";
import CommonRoutes from "./routes/CommonRoutes";
import { Role } from "./utils/constant/Constant";
import { useSelector } from "react-redux";

const RoleBaseRoute = ({ element: Element, role, ...rest }) => {
  const { userData } = useSelector((state) => state.auth);
  if (!userData) {
    return <Navigate to="/login" replace />;
  }

  if (role.includes(userData.role)) {
    return <Element {...rest} />;
  } else {
    return <Navigate to="/login" replace />;
  }
};

const AuthenticatedApp = () => {
  return (
    <Routes>
      <Route
        path="/super-admin/*"
        element={<RoleBaseRoute element={Layout} role={[Role.SUPER_ADMIN]} />}
      >
        <Route path="*" element={<CommonRoutes />} />
      </Route>
      <Route
        path="/admin/*"
        element={
          <RoleBaseRoute
            element={Layout}
            role={[Role.BANKS, Role.MASTER_VENDOR]}
          />
        }
      >
        <Route path="*" element={<CommonRoutes />} />
      </Route>
      <Route
        path="/user/*"
        element={
          <RoleBaseRoute
            element={Layout}
            role={[Role.BRANCH_MANAGER, Role.TEAM_LEAD, Role.BACK_OFFICE]}
          />
        }
      >
        <Route path="*" element={<CommonRoutes />} />
      </Route>
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default AuthenticatedApp;
