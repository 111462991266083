import React, { useEffect, useState } from "react";
import { Table, Input, Pagination, Skeleton, Empty } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { STATUS_TYPE } from "../../utils/constant/Constant";
import "antd/dist/reset.css"; // Import Ant Design styles
import { SkeletonTable } from "../../utils/helpers/SkeletonTable";

const getColumnSearchProps = (dataIndex, render) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: "block" }}
      />
      <div>
        <a onClick={() => confirm()} style={{ marginRight: 8 }}>
          Search
        </a>
        <a onClick={() => clearFilters()}>Reset</a>
      </div>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) => {
    const recordValue = record[dataIndex];
    const renderedValue = render ? render(recordValue, record) : recordValue;

    return renderedValue
      ? renderedValue.toString().toLowerCase().includes(value.toLowerCase())
      : false;
  },
});

const AntdTable = ({
  columns,
  dataSource,
  showPagination = true,
  pagination = false,
  setMinHeight = false,
  pageNo,
  pageSize,
  setPageNo,
  setPageSize,
  total,
  status,
  onRowClick,
}) => {
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [dataWithFillerRows, setDataWithFillerRows] = useState(null);
  // const [current, setCurrent] = useState(currentPage);

  const processedColumns = columns.map((col) => {
    let newCol = { ...col };

    // Add sorting functionality if enabled in the column definition
    if (col.sortable) {
      newCol.sorter = (a, b) => {
        const aValue = col.render
          ? col.render(a[col.dataIndex], a)
          : a[col.dataIndex];
        const bValue = col.render
          ? col.render(b[col.dataIndex], b)
          : b[col.dataIndex];

        if (typeof aValue === "number" && typeof bValue === "number") {
          return aValue - bValue;
        } else if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue);
        } else {
          return 0; // Prevents sorting if types are different or not handled
        }
      };
    }

    // Add searching functionality if enabled in the column definition
    // if (col.searchable) {
    //   newCol = { ...newCol, ...getColumnSearchProps(col.dataIndex, col.render) };
    // }

    return newCol;
  });
  const rowHeight = 55; // Approximate row height
  const targetRowCount = 10;
  useEffect(() => {
    // const dataWithFillerRows = [...dataSource];
    // if (dataSource.length < targetRowCount && dataSource.length > 0) {
    //   for (let i = dataSource.length; i < targetRowCount; i++) {
    //     dataWithFillerRows.push({
    //       isEmpty: true, // Use this to style empty rows differently if needed
    //     });
    //   }
    // }
  }, [dataSource]);

  // Fill empty rows to match the target row count (10 rows)

  const onChange = (page, pageSize) => {
    setPageNo(page);
    setPageSize(pageSize);
  };

  return (
    <>
      <Table
        columns={processedColumns}
        dataSource={dataSource}
        pagination={pagination}
        // style={setMinHeight ? { minHeight: "500px" } : {}}
        scroll={{
          y: 55 * 10,
        }}
        // rowClassName={(record) => (record.isEmpty ? "empty-row" : "")}
        // loading={((status == STATUS_TYPE.LOADING) && (dataSource.length == 0) )? {  tip: <SkeletonTable columns={columns} /> } : {  tip: <SkeletonTable columns={columns} /> }}
        locale={{
          emptyText:
            status == STATUS_TYPE.LOADING && dataSource.length == 0 ? (
              <SkeletonTable columns={columns} dataSource />
            ) : (
              <Empty />
            ),
        }}
        onRow={
          onRowClick
            ? (record) => ({
                onClick: () => {
                  onRowClick(record); // Call the passed function
                  setSelectedRowKey(record.id); // Update selected row key
                },
                style: {
                  backgroundColor:
                    selectedRowKey === record.id ? "#e6f7ff" : "#ffffff",
                }, // Conditional styling
              })
            : undefined
        }
      />

      <div className="m-3">
        {showPagination ? (
          <Pagination
            align="end"
            total={total}
            // showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            defaultPageSize={pageSize}
            current={pageNo}
            onChange={onChange}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AntdTable;
