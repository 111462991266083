import React, { useState } from 'react';
import { Form, Input, Button } from "antd";
import { API_URL, Role } from '../utils/constant/Constant';
import { useToast } from '../utils/helpers/CommFun';
import { useNavigate } from "react-router-dom";
import api from '../services/Api';

const ChangePassword = () => {
  const [initialValue, setInitialValue] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [form] = Form.useForm();
  const [passwordValidation, setPasswordValidation] = useState({
    minLength: false,
    hasLowercase: false,
    hasUppercase: false,
    hasNumber: false,
    hasSpecialChar: false,
  });
  const userData = JSON.parse(localStorage.getItem("user_data"));
  const navigate = useNavigate();
  const Toast = useToast();

  const updatePassword = async (values) => {
    const body = {
      user_id: parseInt(userData.id),
      current_password: values.currentPassword,
      new_password: values.newPassword,
    };

    try {
      const response = await api.post(API_URL.CHANGE_PASSWORD, body);
      if (response.status === 200) {
        Toast.fire({ icon: "success", title: response.message });
         redirectUser();
        form.resetFields();
      
       
      } else {
        Toast.fire({ icon: "error", title: response.message });
      }
    } catch (error) {
      Toast.fire({ icon: "error", title: "An error occurred. Please try again." });
      console.error(error);
    }
  };

  const redirectUser = () => {
    const routes = {
      [Role.SUPER_ADMIN]: "/super-admin/changepassword",
      [Role.BANKS]: "/admin/changepassword",
      [Role.MASTER_VENDOR]: "/admin/changepassword",
      [Role.BRANCH_MANAGER]: "/user/changepassword",
      [Role.TEAM_LEAD]: "/user/changepassword",
      [Role.BACK_OFFICE]: "/user/changepassword",
    };
    navigate(routes[userData.role] || "/login");
  };

  const validatePassword = (newPassword) => {
    setPasswordValidation({
      minLength: newPassword.length >= 8,
      hasLowercase: /[a-z]/.test(newPassword),
      hasUppercase: /[A-Z]/.test(newPassword),
      hasNumber: /[0-9]/.test(newPassword),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
    });
  };

  const handleFieldChange = (changedValues) => {
    if (changedValues.newPassword) {
      validatePassword(changedValues.newPassword);
    }
    setInitialValue((prev) => ({ ...prev, ...changedValues }));
  };

  const newPasswordValidator = (rule, value) => {
    if (value === initialValue.currentPassword) {
      return Promise.reject("New Password cannot be the same as Current Password");
    }
    return Promise.resolve();
  };

  const confirmPasswordValidator = (rule, value) => {
    if (value !== initialValue.newPassword) {
      return Promise.reject("Confirm password and New Password do not match");
    }
    return Promise.resolve();
  };

  const formConfig = [
    {
      label: "Current Password",
      name: "currentPassword",
      rules: [{ required: true, message: "Please Enter Current Password" }],
    },
    {
      label: "New Password",
      name: "newPassword",
      rules: [
        { required: true, message: "Please Enter New Password" },
        { validator: newPasswordValidator },
      ],
    },
    {
      label: "Confirm Password",
      name: "confirmPassword",
      rules: [
        { required: true, message: "Please Enter Confirm Password" },
        { validator: confirmPasswordValidator },
      ],
    }
  ];

  const handleCancel = () => {
    form.resetFields();
    redirectUser();
  };

  return (
    <div className="main-contant">
    <div className="container-fluid">
      <div className="contant-box">
        <div className="sub-header">
          <div className="row">
            <div className="col-md-8">
              <div className="title-box">
                <h5>Change Password</h5>
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
          <section id="password-management-form">
            <div className="add_user-box">
              <div className="add_user-form">
                <div className='row'>
                  <div className='col-6'>
                    <Form
                      form={form}
                      initialValues={initialValue}
                      layout="vertical"
                      onValuesChange={handleFieldChange}
                      onFinish={updatePassword}
                    >
                      {formConfig.map((field) => (
                        <Form.Item
                          key={field.name}
                          label={field.label}
                          name={field.name}
                          rules={field.rules}
                        >
                          <Input.Password autoComplete="off" maxLength={50} />
                        </Form.Item>
                      ))}
                    </Form>
                  </div>
                  <div className="col-6">
                    <h5>New Password must Contain:</h5>
                    <div className={passwordValidation.minLength ? 'valid' : 'invalid'}
                      style={{ color: initialValue.newPassword ? (passwordValidation.minLength ? 'green' : 'red') : 'grey' }}>
                      - At least 8 Characters
                    </div>
                    <hr />
                    <div className={passwordValidation.hasLowercase ? 'valid' : 'invalid'}
                      style={{ color: initialValue.newPassword ? (passwordValidation.hasLowercase ? 'green' : 'red') : 'grey' }}>
                      - At least 1 lowercase letter (a-z)
                    </div>
                    <hr />
                    <div className={passwordValidation.hasUppercase ? 'valid' : 'invalid'}
                      style={{ color: initialValue.newPassword ? (passwordValidation.hasUppercase ? 'green' : 'red') : 'grey' }}>
                      - At least 1 uppercase letter (A-Z)
                    </div>
                    <hr />
                    <div className={passwordValidation.hasNumber ? 'valid' : 'invalid'}
                      style={{ color: initialValue.newPassword ? (passwordValidation.hasNumber ? 'green' : 'red') : 'grey' }}>
                      - At least 1 Number (0-9)
                    </div>
                    <hr />
                    <div className={passwordValidation.hasSpecialChar ? 'valid' : 'invalid'}
                      style={{ color: initialValue.newPassword ? (passwordValidation.hasSpecialChar ? 'green' : 'red') : 'grey' }}>
                      - At least 1 Special Character
                    </div>
                  </div>
                 <Form  
                      form={form}
                      initialValues={initialValue}
                      layout="vertical"
                      onValuesChange={handleFieldChange}
                      onFinish={updatePassword}>
                      <Form.Item>
                      <div className="cta-button-box">
                        <Button htmlType="submit" className="btn submit-btn">
                          Save
                        </Button>
                      </div>
                    </Form.Item>
                 </Form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
