import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import AntdTable from "../../components/AntdTable/Table";
import { Switch, Skeleton, Table } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateClient,
  deleteClient,
  fetchAllClients,
  updateClientStatus,
} from "../../redux/slices/clientSlice";
import SearchBar from "../../components/SearchBar";
import SkeletonForm from "../../components/DynamicForm/SkeletonForm";

function ClientManagement() {
  const dispatch = useDispatch();
  const { clients, status, total } = useSelector((state) => state.client);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [initialValue, setInitialValue] = useState(null);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const { userData } = useSelector((state) => state.auth);

  useEffect(() => {
    fetchData();
  }, [dispatch, pageNo, pageSize, searchQuery]);


  const fetchData = () => {
    const body = {
      pageNo: pageNo,
      pageSize: pageSize,
      search: searchQuery,
    };
    dispatch(fetchAllClients(body));
  };

  const closeRef = useRef();
  const closeModal = () => {
    setInitialValue(null);
    closeRef.current.click();
  };

  // Update the client list
  const openModalforUpdate = async (client) => {
    setInitialValue({
      clientId: client.id,
      name: client.name,
      code: client.short_code,
    });
  }

  //Update Status
  const handleUpdateClientStatus = async (client) => {
    let status = client.status == 1 ? 0 : 1;
    const body = {
      client_id: client.id,
      status: status,
    };
    dispatch(updateClientStatus(body))
      .unwrap()
      .then(() => { })
      .catch((err) => { });
  };

  const handleCreateOrUpdateClient = async (values) => {
    const body = {
      id: values.clientId,
      name: values.name,
      short_code: values.code,
      user_id: userData.id,
      parent_id: userData.id,
    };

    if (userData.parent_id !== 0) {
      body.master_id = userData.parent_id;
    }
    const isUpdate = values.clientId ? true : false;
    dispatch(createOrUpdateClient({ body, isUpdate }))
      .unwrap()
      .then((response) => {
        fetchData();
        closeModal();
      })
      .catch((error) => {
        console.error("Error creating/updating client:", error);
      });
  };

  //Delete Client
  const handleDeleteClient = (client) => {
    Swal.fire({
      title: "Delete",
      text: `Are you sure you want to delete client?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = { client_id: client.id };
        dispatch(deleteClient(body))
          .unwrap()
          .then(() => {
            fetchData();
          })
          .catch((err) => { });
      }
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: "10%",
      render: (_, __, index) => (pageNo - 1) * pageSize + (index + 1),
      className: "dt-left",
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: "20%",
      className: "dt-left",

      sortable: true, // Enable sort
    },
    {
      key: "short_code",
      dataIndex: "short_code",
      width: "10%",
      title: "Code",
      className: "dt-left",

      sortable: true, // Enable sort
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "30%",
      key: "status",
      render: (text, record) => (
        <div className="form-check form-switch d-flex justify-content-center">
          <Switch
            checked={text == 1}
            onChange={() => handleUpdateClientStatus(record)} // Handle switch change
          />
        </div>
      ),
      className: "dt-center",
    },
    {
      title: "Action",
      width: "30%",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <EditOutlined
            data-bs-toggle="modal"
            data-bs-target="#clientModal"
            style={{ color: "#000000", marginRight: 10, cursor: "pointer" }}
            onClick={() => openModalforUpdate(record)} // Implement handleEdit function
          />
          <DeleteOutlined
            style={{ color: "#D92E38", cursor: "pointer" }}
            onClick={() => handleDeleteClient(record)} // Implement handleDelete function
          />
        </div>
      ),
      className: "dt-center",
    },
  ];

  const formConfig = [
    {
      label: "Name",
      name: "name",
      type: "input",
      rules: [{ required: true, message: "Please Enter Name" }],
      maxLength:15,
      colSpan: 24,
    },
    {
      label: "Code",
      name: "code",
      type: "input",
      maxLength:10,
      rules: [{ required: true, message: "Please Enter Code" }],
      colSpan: 24,
    },
    {
      label: "clientId",
      name: "clientId",
      type: "hidden",
      initialValue: null,
    },
  ];


  return (
    <>
      <div className="main-contant">
        {/* {
        (clientStatus == STATUS_TYPE.LOADING)
         ? (
      <SkeletonTable columns={columns} />
        ) : ( */}
        <div className="container-fluid">
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-5">
                  <div className="title-box">
                    <h5>Client Management</h5>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="d-flex justify-content-end">
                    <SearchBar
                      setSearchQuery={setSearchQuery}
                      fetchData={fetchData}
                    />
                    <div className="button-box">
                      <ul>
                        <li>
                          <Link
                            to="/"
                            className="blue-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#clientModal"
                            onClick={closeModal}
                          >
                            + Add New
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AntdTable
              dataSource={clients}
              columns={columns}
              setMinHeight={true}
              pageNo={pageNo}
              pageSize={pageSize}
              status={status}
              setPageNo={setPageNo}
              setPageSize={setPageSize}
              total={total}
            />
          </div>
        </div>
        {/* )} */}

        {/* Modal for adding/editing a client */}
        <div
          className="modal fade"
          id="clientModal"
          tabIndex={-1}
          aria-labelledby="clientModal"
          data-bs-backdrop="static"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
              <h5 className="modal-title">
                      { initialValue ? "Edit client" : "Add new client"}
                    </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeRef}
                />
              </div>
              <div className="modal-body">
             {
              (formConfig && setInitialValue.clientId && !initialValue)
         ? 
           (
              <SkeletonForm
                  config={formConfig}
                  onFinish={handleCreateOrUpdateClient}
                  initialValues={initialValue}
                  onCancel={closeModal}
                />
             
               ) : (
                (formConfig && (setInitialValue.clientId && initialValue)) || (formConfig && !setInitialValue.clientId) ? (
                <DynamicForm
                  config={formConfig}
                  onFinish={handleCreateOrUpdateClient}
                  initialValues={initialValue}
                  onCancel={closeModal}
                />
   ) : "" 
  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientManagement;
