import React, { useEffect, useState } from "react";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import api from "../../services/Api";
import { useNavigate } from "react-router-dom";
import {
  createOrUpdateVendorBranch,
  fetchAllVendorBranches,
} from "../../redux/slices/vendorBranchSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStateCity,
  resetStateCity,
} from "../../redux/slices/stateCitySlice";
import { fetchAllBranchManager } from "../../redux/slices/userSlice";

const AddBranchManagement = () => {
  const dispatch = useDispatch();

  const { branchManagers } = useSelector((state) => state.user);
  const { pincodeId, city, state } = useSelector((state) => state.stateCity);

  const { userData } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [initialValue, setInitialValue] = useState({});

  useEffect(() => {
    dispatch(resetStateCity());

    const body = { parent_id: userData.id };
    dispatch(fetchAllBranchManager(body));
  }, [dispatch]);

  useEffect(() => {
    setInitialValue((prev) => {
      return {
        ...prev,
        pincodeId,
        city,
        state,
      };
    });
  }, [pincodeId]);

  const handleCreateBranch = async (values) => {
    const body = {
      name: values.branchName,
      address: values.address,
      parent_id: parseInt(userData.id),
      user_id: userData.id,
      branch_code: values.branchCode,
      // city: values.city,
      // state: values.state,
      pincode_id: values.pincodeId,
      mobile: values.mobile,
      bm_id: values.branchManager,
      ...(userData.parent_id && { master_id: userData.parent_id }),
    };
    const isUpdate = false;
    dispatch(createOrUpdateVendorBranch({ body, isUpdate }))
      .unwrap()
      .then((response) => {
        dispatch(fetchAllVendorBranches({}));
        navigate(-1);
      })
      .catch((error) => {});
  };

  const handleFieldChange = (changedValues) => {
    if (changedValues.pincode) {
      if (String(changedValues.pincode).length === 6) {
        dispatch(fetchStateCity(changedValues.pincode));
      } else {
        dispatch(resetStateCity());
        setInitialValue((prev) => {
          return { ...prev, pincodeId: null, city: null, state: null };
        });
      }
    }
    if (changedValues.branchManager) {
      const mobile = branchManagers.find(
        (element) => element.id == changedValues.branchManager
      ).mobile;
      setInitialValue((prev) => {
        return { ...prev, mobile };
      });
    }
  };

  const formConfig = [
    {
      label: "Branch Name",
      name: "branchName",
      type: "input",
      maxLength:50,
      rules: [{ required: true, message: "Please Enter Branch Name" }],
      colSpan: 12,
    },
    {
      label: "Branch Code",
      name: "branchCode",
      type: "input",
      maxLength:10,
      rules: [{ required: true, message: "Please Enter Branch Code" }],
      colSpan: 12,
    },
    { label: "Address", name: "address", type: "input", maxLength:250, colSpan: 12 },
    {
      label: "Branch Pincode",
      name: "pincode",
      type: "input",
      rules: [
        { required: true, message: "Please Enter Pincode" },
        { pattern: /^[0-9]{6}$/, message: "Pincode must be exactly 6 digits" },
      ],
      colSpan: 12,
    },
    {
      label: "Branch City",
      name: "city",
      type: "input",
      rules: [
        { required: true, message: "Please Enter Valid Pincode to Fetch City" },
      ],
      disabled: true,
      colSpan: 12,
    },
    {
      label: "Branch State",
      name: "state",
      type: "input",
      rules: [
        {
          required: true,
          message: "Please Enter Valid Pincode to Fetch State",
        },
      ],
      disabled: true,
      colSpan: 12,
    },
    {
      label: "Branch Manager",
      name: "branchManager",
      type: "select",
      options: branchManagers.map((bm) => ({
        value: bm.id,
        label: bm.first_name,
      })),
      colSpan: 12,
    },
    {
      label: "Branch Manager Mobile No.",
      name: "mobile",
      type: "number-input",
      maxLength:10,
      disabled: true,
      rules: [
        {
          pattern: /^[0-9]{10}$/,
          message: "Mobile No.must be exactly 10 digits",
        },
      ],
      colSpan: 12,
    },
    {
      label: "Branch ID",
      name: "branchId",
      type: "hidden",
      initialValue: null,
      colSpan: 12,
    },
    {
      label: "pincodeId",
      name: "pincodeId",
      type: "hidden",
      initialValue: null,
    },
  ];

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5 className="bottom-line">Add New Branch</h5>
                </div>
              </div>
            </div>
          </div>
          <section id="add-new-user">
            <div className="add_user-box">
              <div className="add_user-form">
                <DynamicForm
                  config={formConfig}
                  initialValues={initialValue}
                  onFinish={handleCreateBranch}
                  handleFieldChange={handleFieldChange}
                  onCancel={() => {
                    navigate(-1);
                  }}
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddBranchManagement;
