import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../services/Api";
import { API_URL } from "../../utils/constant/Constant";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateField,
  fetchPerticularField,
} from "../../redux/slices/fieldSlice";
import SkeletonForm from "../../components/DynamicForm/SkeletonForm";
import { toSnakeCase } from "../../utils/helpers/CommFun";

const AddField = () => {
  const dispatch = useDispatch();
  const { fields, selectedField } = useSelector((state) => state.field);
  const { userData } = useSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();

  const [fieldId, setFieldId] = useState(location.state?.field_id || null);
  const [initialValue, setInitialValue] = useState(null);

  const clear = () => {
    setInitialValue(null);
  };

  const onCancelButtonClick = () => {
    navigate(-1);
    clear();
  };

  useEffect(() => {
    if (fieldId) {
      const body = {
        id: fieldId,
      };
      dispatch(fetchPerticularField(body));
    }
  }, [fieldId]);
  useEffect(() => {
    if (selectedField) {
      setInitialValue({
        id: fieldId,
        name: selectedField.name,
        displayName: selectedField.display_name,
        type: selectedField.type,
      });
    }
  }, [selectedField]);

  const handleCreateOrUpdateField = async (values) => {
    const body = {
      name: values.name,
      display_name: values.displayName,
      type: values.type,
      options: values.options,
      user_id: userData.id,
    };
    if (fieldId) {
      body.id = fieldId;
    }

    const isUpdate = fieldId ? true : false;
    dispatch(createOrUpdateField({ body, isUpdate }))
      .unwrap()
      .then((response) => {
        navigate("/admin/field");
      })

      .catch((error) => {});
  };
  const formConfig = [
    {
      label: "Name",
      name: "name",
      type: "hidden",
      rules: [{ required: true, message: "Please Enter Name" }],
      colSpan: 8,
    },
    {
      label: "Field Name",
      name: "displayName",
      maxLength:15,
      type: "input",
      rules: [{ required: true, message: "Please Enter Field Name" }],
      colSpan: 8,
    },
    {
      label: "Type",
      name: "type",
      key: "type",
      type: "select",
      options: [
        { value: 1, label: "Text" },
        { value: 2, label: "Number" },
        { value: 3, label: "Dropdown" },
        { value: 4, label: "Checkbox" },
        { value: 5, label: "Date" },
        { value: 6, label: "File" },
      ],
      rules: [
        {
          required: true,
          message: "Please Select One Option",
        },
      ],
      // onChange: (val) => console.log(val), // Uncomment if needed
      render: (text, record) => {
        switch (record.type) {
          case "1":
            return "Text";
          case 2:
            return "Number";
          case 3:
            return "Dropdown";
          case 4:
            return "Checkbox";
          case 5:
            return "Date";
          case 6:
            return "File";
          default:
            return "Unknown";
        }
      },
      colSpan: 8,
    },
    (initialValue?.type == 3 || initialValue?.type == 4) && {
      label: "Options",
      name: "options",
      type: "input",
      placeholder: "Enter Comma Separeted Values Ex. Male,Female",
      rules: [{ required: true, message: "Please Enter Options" }],
      colSpan: 8,
    },
    {
      label: "fieldId",
      name: "fieldId",
      type: "hidden",
      initialValue: fieldId,
    },
  ];
  const handleFieldChange = (changedValues) => {
    if (changedValues.displayName) {
      setInitialValue((prev) => {
        return { ...prev, name: toSnakeCase(changedValues.displayName) };
      });
    }
    setInitialValue((prev) => {
      return { ...prev, ...changedValues };
    });
  };

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5>Field Management</h5>
                </div>
              </div>
            </div>
          </div>
          <section id="client-management-form">
            <div className="add_user-box">
              <div className="add_user-form">
              {
           (formConfig && fieldId && !initialValue)
            ? 
           (
              <SkeletonForm
              config={formConfig}
              onFinish={handleCreateOrUpdateField}
              initialValues={initialValue}
              handleFieldChange={handleFieldChange}
              onCancel={onCancelButtonClick}
                />
             
               ) : (
               (formConfig && (fieldId && initialValue)) || (formConfig && !fieldId) ? (
                <DynamicForm
                  config={formConfig}
                  onFinish={handleCreateOrUpdateField}
                  initialValues={initialValue}
                  handleFieldChange={handleFieldChange}
                  onCancel={onCancelButtonClick}
                />
                  ) : "" 
                )} 
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddField;
