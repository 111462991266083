import React from "react";
import { localFormattedDate } from "../../utils/helpers/CommFun";
import { CASE_STATUS_TEXT } from "../../utils/constant/Constant";

function CaseDetailsPage({CaseDetails}) {

  return (
    <div className="main-content">
      <div className="container-fluid">
        <div className="content-box">
          <div className="sub-header">
            <div className="row align-items-end">
              <div className="col-8">
                <div className="left">
                  <div className="vendor-branch-box"></div>
                </div>
              </div>
            </div>
            <section id="case-detail">
              <div className="card mb-3">
                <div className="card-header">
                  <b className="card-title lg">Case Information</b>
                </div>
                <div className="row ml-10">
                  <div className="card-body col-4">
                    <p className="card-text">
                      <span className="text-muted">Case Status</span>
                    </p>
                    <p className="card-text">
                      {CASE_STATUS_TEXT[CaseDetails.status]} 
                    </p>
                  </div>
                  <div className="card-body col-4">
                    <p className="card-text">
                      <span className="text-muted">Report Status</span>
                    </p>
                    <p className="card-text">Pending</p>
                  </div>
                  <div className="card-body col-4">
                    <p className="card-text">
                      <span className="text-muted">Received At</span>
                    </p>
                    <p className="card-text">
                      {localFormattedDate(CaseDetails.received_at)}
                    </p>
                  </div>
                </div>
                <hr className="mt-0 " />
                <div className="row ml-10">
                  <div className="card-body col-4">
                    <p className="card-text">
                      <span className="text-muted">Client Branch</span>
                    </p>
                    <p className="card-text">
                      {CaseDetails.branch?.branch_name}
                    </p>
                  </div>
                  <div className="card-body col-4">
                    <p className="card-text">
                      <span className="text-muted">Vendor Branch</span>
                    </p>
                    <p className="card-text">
                      {CaseDetails.branch?.vendor_branch_id?.name}
                    </p>
                  </div>
                  <div className="card-body col-4">
                    <p className="card-text">
                      <span className="text-muted">Estimated At</span>
                    </p>
                    <p className="card-text">
                      {localFormattedDate(CaseDetails.esitmated_tat)}
                    </p>
                  </div>
                </div>
                <hr className="mt-0 " />
                <div className="row ml-10">
                  <div className="card-body col-4">
                    <p className="card-text">
                      <span className="text-muted">Total Applicant</span>
                    </p>
                    <p className="card-text">
                      {CaseDetails.applicant_data?.length}
                    </p>
                  </div>
                  <div className="card-body col-4">
                    <p className="card-text">
                      <span className="text-muted">Total Points</span>
                    </p>
                    <p className="card-text">{CaseDetails.no_of_points}</p>
                  </div>
                  <div className="card-body col-4">
                    <p className="card-text">
                      <span className="text-muted">Completed Date</span>
                    </p>
                    <p className="card-text">Pending</p>
                  </div>
                </div>
                <hr className="mt-0 " />
                <div className="row ml-10">
                  <div className="card-body col-6">
                    <p className="card-text">
                      <span className="text-muted">Download Report</span>
                    </p>
                    <p className="card-text">Button</p>
                  </div>
                </div>
              </div>
              <div className="row"></div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaseDetailsPage;
