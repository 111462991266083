import React from "react";

import { SearchOutlined } from "@ant-design/icons";

const SearchBar = ({ setSearchQuery }) => {
  return (
    <div className="search-box">
      <div className="form-group ">
        <input
          type="search"
          className="form-control"
          placeholder="Search"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <SearchOutlined />
      </div>
    </div>
  );
};

export default SearchBar;
