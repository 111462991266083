// features/user/userSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import {
  createOrUpdateData,
  deleteData,
  fetchData,
  updateStatus,
} from "../utils/apiActions";

export const fetchAllClientBranches = fetchData(
  "clientBranch",
  API_URL.GET_ALL_BRANCH,
  "fetchAll"
);
export const fetchClientBranches = fetchData(
  "clientBranch",
  API_URL.GET_CLIENT_BRANCH,
  "fetchClient"
);
export const fetchPerticularClientBranch = fetchData(
  "clientBranch",
  API_URL.GET_BRANCH,
  "fetchPerticular"
);

export const createOrUpdateClientBranch = createOrUpdateData(
  "clientBranch",
  API_URL.CREATE_BRANCH,
  API_URL.UPDATE_BRANCH
);

export const updateClientBranchStatus = updateStatus(
  "clientBranch",
  API_URL.UPDATE_BRANCH_STATUS
);

export const deleteClientBranch = deleteData(
  "clientBranch",
  API_URL.DELETE_BRANCH
);

const clientBranchSlice = createSlice({
  name: "clientBranch",
  initialState: {
    clientBranches: [],
    selectedClientBranch: null,
    total: 0,
    status: STATUS_TYPE.IDLE,
    error: null,
  },
  reducers: {
    clientBranchStatusReset: (state) => {
      state.status = STATUS_TYPE.IDLE;
    },
    resetClientBranchState: (state) => {
      state.clientBranches = [];
      state.selectedClientBranch = null;
      state.total = 0;
      state.status = STATUS_TYPE.IDLE;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllClientBranches.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllClientBranches.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.clientBranches = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchAllClientBranches.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchClientBranches.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchClientBranches.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.clientBranches = action.payload;
      })
      .addCase(fetchClientBranches.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchPerticularClientBranch.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchPerticularClientBranch.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.selectedClientBranch = action.payload;
      })
      .addCase(fetchPerticularClientBranch.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(updateClientBranchStatus.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(updateClientBranchStatus.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        const data = action.payload;
        state.clientBranches = state.clientBranches.map((clientBranch) =>
          clientBranch.id === data.clientBranch_id
            ? { ...clientBranch, status: data.status }
            : clientBranch
        );
      })
      .addCase(updateClientBranchStatus.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload; // Error message from `rejectWithValue`
      })
      .addCase(createOrUpdateClientBranch.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(createOrUpdateClientBranch.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        // Optional: Handle additional logic on successful clientBranch creation/updation
      })
      .addCase(createOrUpdateClientBranch.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(deleteClientBranch.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(deleteClientBranch.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(deleteClientBranch.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      });
  },
});

export const { clientBranchStatusReset, resetClientBranchState } =
  clientBranchSlice.actions;
export default clientBranchSlice.reducer;
