import React, { useState, useEffect } from "react";
import AntdTable from "../../components/AntdTable/Table";
import { Role } from "../../utils/constant/Constant";
import { Link, useNavigate } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  deleteField,
  fetchAllField,
  resetSelectedField,
} from "../../redux/slices/fieldSlice";
import SearchBar from "../../components/SearchBar";

const FieldManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fields, status,total } = useSelector((state) => state.field);
  const { userData } = useSelector((state) => state.auth);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(resetSelectedField());
  }, []);

  useEffect(() => {
    fetchData();
  }, [dispatch, pageNo, pageSize, searchQuery]);
  
  //Delete Document
  const fetchData = () => {
    const body = {
      pageNo: pageNo,
      pageSize: pageSize,
      search: searchQuery,
    };
    dispatch(fetchAllField(body));
  };

  const handleDeleteField = async (field) => {
    Swal.fire({
      title: "Delete",
      text: `Are you sure you want to delete field?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = { id: parseInt(field.id) };
        dispatch(deleteField(body))
          .unwrap()
          .then(() => {
            const body = {
              pageNo: pageNo,
              pageSize: pageSize,
              search: searchQuery,
            };
            dispatch(fetchAllField(body));
          })
          .catch((err) => {});
      }
    });
  };

  const onredirect = async (field) => {
    const data = {
      field_id: field.id,
    };
    if (userData.role == Role.MASTER_VENDOR) {
      navigate("/admin/field/add/", {
        state: data,
      });
    } else {
      navigate("/login");
    }
  };

  const columns = [
    {
      title: "#",
      key: "index",
      render: (_, __, index) => (pageNo - 1) * pageSize + (index + 1),
      className: "dt-left",
      width: "10%",
    },
    // { dataIndex: "name", title: "Name", className: "dt-center" },
    {
      dataIndex: "display_name",
      title: "Field Name",
      width: "20%",
      className: "dt-left",
    },
    {
      dataIndex: "type",
      title: "Type",
      className: "dt-left",
      render: (text, record) => {
        switch (record.type) {
          case 1:
            return "Text";
          case 2:
            return "Number";
          case 3:
            return "Dropdown";
          case 4:
            return "Checkbox";
          case 5:
            return "Date";
          case 6:
            return "File";
          default:
            return "Unknown";
        }
      },
    },
    {
      dataIndex: "options",
      title: "Options",
      width: "20%",
      className: "dt-left",

      render: (text, record) => (text ? text : "-"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <EditOutlined
            style={{ color: "#000000", marginRight: 10, cursor: "pointer" }}
            onClick={() => onredirect(record)}
          />
          <DeleteOutlined
            style={{ color: "#D92E38", cursor: "pointer" }}
            onClick={() => handleDeleteField(record)}
          />
        </div>
      ),
      className: "dt-center",
    },
  ];

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-5">
                <div className="title-box">
                  <h5>Field Management</h5>
                </div>
              </div>
              <div className="col-md-7">
                <div className="d-flex justify-content-end">
                  <SearchBar
                    
                    setSearchQuery={setSearchQuery}
                    fetchData={fetchData}
                  />
                  <div className="button-box">
                    <ul>
                      <li>
                        <Link
                          to={
                            [Role.MASTER_VENDOR].some(
                              (role) => userData.role == role
                            )
                              ? "/admin/field/add"
                              : ""
                          }
                          className="blue-btn"
                        >
                          + Add New
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AntdTable
            dataSource={fields}
            columns={columns}
            pageNo={pageNo}
            setMinHeight={true}
            pageSize={pageSize}
            setPageNo={setPageNo}
            setPageSize={setPageSize}
            total={total}
            status={status}
          />
        </div>
      </div>
    </div>
  );
};

export default FieldManagement;
