import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../services/Api";
import { useToast } from "../../utils/helpers/CommFun";
import { API_URL } from "../../utils/constant/Constant";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const AddPointForm = () => {
  //sweet alert
  const Toast = useToast();
  const location = useLocation();
  const data = location.state;
  const { token } = useSelector((state) => state.auth);

  const [template, setTemplate] = useState(null);
  const [updatedTemplate, setUpdatedTemplate] = useState(null);
  const [formValues, setFormValues] = useState({});
  const navigate = useNavigate();
  const [initialValue, setInitialValue] = useState(null);

  const onCancelButtonClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (data && data.getData && data.getData.template) {
      const temp = data.getData.template;
      setTemplate(temp);
    }
  }, [data]);

  useEffect(() => {
    generateFormConfig();
    setFieldData();
    // getCaseDetails();
  }, [template]);

  const generateFormConfig = () => {

    if (!template) return;
    let formConfig = [];

    // updated

    Object.keys(template).forEach((key) => {
      formConfig.push({
        label: key,
        name: key,
        type: 'label',
        colSpan: 24,
      });
      template[key].forEach((element) => {
        formConfig.push({
          label: element.fieldDisplayName,
          name: element.fieldName,
          type: getFieldType(element.fieldType),
          options: element?.options?.map((item) => ({
            value: item,
            label: item,
          })),
          colSpan: 8,
          rules: [
            {
              required: element.is_required_field == "Yes",
              message: "This is Required Field",
            },
          ],
        });
      })
    });

    //old

    // template.map((element) => {      
    //   formConfig.push({
    //     label: element.fieldDisplayName,
    //     name: element.fieldName,
    //     type: getFieldType(element.fieldType),
    //     options: element?.options?.map((item) => ({
    //       value: item,
    //       label: item,
    //     })),
    //     colSpan: 8,
    //     rules: [
    //       {
    //         required: element.is_required_field == "Yes",
    //         message: "This is Required Field",
    //       },
    //     ],
    //   });
    // });
    return formConfig;
  };

  const setFieldData = () => {

    if (!template) return;

    Object.keys(template).forEach((key) => {
      template[key].forEach((element) => {
        setInitialValue((prev) => ({
          ...prev,
          [element.fieldName]: element.fieldValue,
        }));
      })
    });
  }

  const getFieldType = (val) => {
    switch (val) {
      case "Text":
        return "input";
      case "Number":
        return "input";
      case "Dropdown":
        return "select";
      case "Checkbox":
        return "checkbox-group";
      case "Date":
        return "date";
      case "File":
        return "multi-upload";
      default:
        return "Unknown";
    }
  };

  const getCaseDetails = async () => {
    try {
      const body = {
        case_id: JSON.parse(data.case_id),
        applicant_id: JSON.parse(data.applicant_id),
        type: JSON.parse(data.type),
      };
      const response = await api.post(API_URL.GET_CASE_DETAILS, body, token);
      if (response.status === 200) {

        const existingCase = response.result.existingCase;
        let tempFormValues = { ...formValues };
        if (template)
          Object.keys(template).forEach((key) => {
            template[key].map((element) => {
              if (Object.keys(element).includes(element.fieldName)) {
                tempFormValues[element.fieldName] = existingCase[element.fieldName];
              }
            });
          });
        setFormValues(tempFormValues);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleSubmit = async () => {
    try {

      if (!updatedTemplate) return;
      const formData = {
        case_id: data.case_id,
        applicant_id: data.applicant_id,
        type: data.type,
        verification_type: data.type,
        point_id: data.pointData.id,
        template: updatedTemplate,
      };
      const response = await api.postFormData(API_URL.UPDATE_POINTS, formData, token);
      if (response.status === 200) {
        Toast.fire({
          icon: "success",
          title: response.message,
        });
        onCancelButtonClick();
      } else {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleFieldChange = (changedValues) => {
    setInitialValue((prev) => {
      return { ...prev, ...changedValues };
    });
  };

  const handleSave = (values) => {

    Object.keys(template).forEach((key) => {
      const updatedCategory = template[key].map((element) => {
        if (Object.keys(values).includes(element.fieldName)) {
          // Update the fieldValue if the fieldName exists in the values object
          return {
            ...element,
            fieldValue: formatValue(element.fieldType, values[element.fieldName]),
          };
        } else {
          return element;
        }
      });

      // Update state for each category
      setUpdatedTemplate((prevTemplate) => ({
        ...prevTemplate,
        [key]: updatedCategory, // Update the specific category
      }));
    });
  };

  useEffect(() => {
    handleSubmit();
  }, [updatedTemplate]);

  const formatValue = (type, value) => {
    switch (type) {
      case "Date":
        return dayjs(value).toDate();
      case "File":
        return transformData(value);
      default:
        return value;
    }
  };

  const transformData = (data) => {
    if (!data)
      return data
    const newData = [];
    data.forEach((element, index) => {
      if (element.originFileObj)
        newData[index] = { originFileObj: element.originFileObj };
      else
        newData[index] = element.url.split('/').pop();
    })

    return newData;
  };

  const renderForm = () => {
    if (!template) {
      return <div>NO FORM IS THERE...</div>;
    }

    return (
      <DynamicForm
        config={generateFormConfig()}
        initialValues={initialValue}
        onFinish={handleSave}
        handleFieldChange={handleFieldChange}
        onCancel={onCancelButtonClick}
      />
    );
  };

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5 className="bottom-line">Add Point Details</h5>
                </div>
              </div>
            </div>
          </div>
          <section id="add-new-user">
            <div className="add_user-box">
              <div className="add_user-form">{renderForm()}</div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddPointForm;
