import React, { useEffect, useState } from "react";
import { daysOfWeek } from "../../../utils/constant/Constant";
import dayjs from "dayjs";

const BranchInfo = ({ formValues }) => {
  return (
    <>
      {/* Branch Information Card */}
      <div className="card mb-3">
        <div className="card-header">
          <b className="card-title lg">Branch Information</b>
        </div>
        <div className="row ml-10">
          <div className="card-body col-6">
            <p className="card-text">
              <span className="text-muted">Branch Name</span>
            </p>
            <p className="card-text">{formValues?.branchName}</p>
          </div>
          <div className="card-body col-6">
            <p className="card-text">
              <span className="text-muted">Branch Code</span>
            </p>
            <p className="card-text">{formValues?.branchCode}</p>
          </div>
        </div>
        <hr className="mt-0 " />
        <div className="row ml-10">
          <div className="card-body col-6">
            <p className="card-text">
              <span className="text-muted">Branch Manager</span>
            </p>
            <p className="card-text">
              {`${formValues?.bm_name?.first_name} ${
                formValues?.bm_name?.middle_name
                  ? formValues.bm_name.middle_name + " "
                  : ""
              }${
                formValues?.bm_name?.last_name
                  ? formValues.bm_name.last_name
                  : ""
              }`}
            </p>
          </div>
          <div className="card-body col-6">
            <p className="card-text">
              <span className="text-muted">Branch Manager Phone No.</span>
            </p>
            <p className="card-text">{formValues?.bm_name?.mobile}</p>
          </div>
        </div>
        <hr className="mt-0 " />
        <div className="row ml-10">
          <div className="card-body col-6">
            <p className="card-text">
              <span className="text-muted">Pincode</span>
            </p>
            <p className="card-text">{formValues?.pincode}</p>
          </div>
          <div className="card-body col-6">
            <p className="card-text">
              <span className="text-muted">City</span>
            </p>
            <p className="card-text">{formValues?.city}</p>
          </div>
        </div>
        <hr className="mt-0 " />
        <div className="row ml-10">
          <div className="card-body col-6">
            <p className="card-text">
              <span className="text-muted">State</span>
            </p>
            <p className="card-text">{formValues?.state}</p>
          </div>
          <div className="card-body col-6">
            <p className="card-text">
              <span className="text-muted">Address</span>
            </p>
            <p className="card-text">{formValues?.address}</p>
          </div>
        </div>
      </div>

      {/* Service Area Card */}
      <div className="card mb-3">
        <div className="card-header">
          <b className="card-title lg ">Service Area</b>
        </div>
        <div className="row ml-10">
          <div className="card-body">
            <p className="card-text">
              <span className="text-muted">CL Pincode</span>
            </p>
            {formValues?.clPincodes?.map((element) => (
              <span class="custom-badge m-1">{element.pincode}</span>
            ))}
          </div>
        </div>
        <hr className="mt-0" />
        <div className="row ml-10">
          <div className="card-body">
            <p className="card-text">
              <span className="text-muted">OCL Pincode</span>
            </p>
            {formValues?.oclPincodes?.map((element) => (
              <span class="custom-badge m-1">{element.pincode}</span>
            ))}
          </div>
        </div>
      </div>

      {/* Hours Card */}
      <div className="card mb-3">
        <div className="card-header">
          <b className="card-title lg">Hours</b>
        </div>
        <div className="row ml-10">
          <div className="col-12">
            <div className="card-body">
              <div className="d-flex flex-column w-25">
                {formValues?.working_hours
                  ?.slice()
                  .sort((a, b) => a.day_type - b.day_type)
                  .map((element) => (
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <p className="card-text">
                        <span className="text-muted">
                          {daysOfWeek[element.day_type - 1]}
                        </span>
                      </p>
                      <p className="card-text">
                        {element.is_closed == 1
                          ? "Closed"
                          : `${dayjs(element.is_open_time, "HH:mm").format(
                              "h:mm A"
                            )} - ${dayjs(element.is_close_time, "HH:mm").format(
                              "h:mm A"
                            )}`}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BranchInfo;
