import { createSlice } from "@reduxjs/toolkit";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";

import {
  createOrUpdateData,
  createOrUpdateFormData,
  deleteData,
  fetchData,
  updateStatus,
} from "../utils/apiActions";

export const fetchAllUsers = fetchData("user", API_URL.GET_ALL_USER);

export const fetchPerticularUser = fetchData(
  "user",
  API_URL.GET_USER,
  "fetchPerticularUser"
);

export const checkUserBranches = fetchData(
  "user",
  API_URL.CHECK_USER_BRANCH,
  "checkUserBranches"
);

export const checkUserPincodes = fetchData(
  "user",
  API_URL.CHECK_USER_PINCODES,
  "checkUserPincodes"
);

export const fetchAllBranchManager = fetchData(
  "user",
  API_URL.GET_ALL_BRANCHMANAGER,
  "allBranchManager"
);

export const fetchAllTeamLead = fetchData(
  "user",
  API_URL.GET_ALL_TEAMLEAD,
  "allTeamLead"
);

export const createOrUpdateUser = createOrUpdateFormData(
  "user",
  API_URL.CREATE_USER,
  API_URL.UPDATE_USER
);

export const updateUserStatus = updateStatus(
  "user",
  API_URL.UPDATE_USER_STATUS
);

export const deleteUser = deleteData("user", API_URL.DELETE_USER);

const userSlice = createSlice({
  name: "user",
  initialState: {
    users: [],
    branchManagers: [],
    teamLeads: [],
    assignedBranches: [],
    assignedPincodes: [],
    selectedUser: null,
    status: STATUS_TYPE.IDLE,
    total: 0,
    error: null,
  },
  reducers: {
    userStatusReset: (state) => {
      state.status = STATUS_TYPE.IDLE;
    },
    resetSelectedUser: (state) => {
      state.selectedUser = null;
    },
    resetUserState: (state) => {
      state.users = [];
      state.branchManagers = [];
      state.teamLeads = [];
      state.total = 0;
      state.selectedUser = null;
      state.status = STATUS_TYPE.IDLE;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.users = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(checkUserBranches.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(checkUserBranches.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.assignedBranches = action.payload.assignedBranches;
      })
      .addCase(checkUserBranches.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(checkUserPincodes.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(checkUserPincodes.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.assignedPincodes = action.payload.assignedPincodes;
      })
      .addCase(checkUserPincodes.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchPerticularUser.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchPerticularUser.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.selectedUser = action.payload;
      })
      .addCase(fetchPerticularUser.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchAllBranchManager.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllBranchManager.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.branchManagers = action.payload;
      })
      .addCase(fetchAllBranchManager.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchAllTeamLead.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllTeamLead.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.teamLeads = action.payload;
      })
      .addCase(fetchAllTeamLead.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(createOrUpdateUser.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(createOrUpdateUser.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        // Optional: Handle additional logic on successful user creation/updation
      })
      .addCase(createOrUpdateUser.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(updateUserStatus.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(updateUserStatus.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        const data = action.payload;
        state.users = state.users.map((user) =>
          user.id === data.user_id ? { ...user, status: data.status } : user
        );
      })
      .addCase(updateUserStatus.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload; // Error message from `rejectWithValue`
      })
      .addCase(deleteUser.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      });
  },
});

export const { userStatusReset, resetUserState, resetSelectedUser } =
  userSlice.actions;
export default userSlice.reducer;
