import React, { useEffect, useState } from "react";

import { AgCharts } from "ag-charts-react"; //for a graph-modal
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchDashboardData } from "../../redux/slices/authSlice";
import api from "../../services/Api";
import { API_URL, Role } from "../../utils/constant/Constant";

function Dashboard() {
  // const [dashboardData, setdashboardData] = useState([]);

  const dispatch = useDispatch();
  const { userData, token, dashboardData } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  //graph-modal
  const [graph, setGraph] = useState({
    data: [
      { Date: "8/5/24", avgTemp: 2.3, Sales: 10 },
      { Date: "9/5/24", avgTemp: 2.5, Sales: 25 },
      { Date: "10/5/24", avgTemp: 3.3, Sales: 30 },
      { Date: "11/5/24", avgTemp: 6.2, Sales: 60 },
      { Date: "12/5/24", avgTemp: 4.2, Sales: 45 },
      { Date: "5/16/24", avgTemp: 7.2, Sales: 80 },
      { Date: "6/16/24", avgTemp: 2.8, Sales: 20 },
    ],
    series: [
      {
        type: "bar",
        xKey: "Date",
        yKey: "Sales",
      },
    ],
    axes: [
      {
        type: "category",
        position: "bottom",
      },
      {
        type: "number",
        position: "left",
      },
    ],
    legend: {
      position: "top",
    },
    title: {
      text: "Monthly  Sales",
    },
  });

  const RoleBaseNavigator = (url) => {
    if (userData.role === Role.SUPER_ADMIN) {
      navigate("/super-admin/" + url);
    } else if (
      userData.role === Role.BANKS ||
      userData.role === Role.MASTER_VENDOR
    ) {
      navigate("/admin/" + url);
    } else if (
      userData.role === Role.BRANCH_MANAGER ||
      userData.role === Role.TEAM_LEAD ||
      userData.role === Role.BACK_OFFICE
    ) {
      navigate("/user/" + url);
    } else {
      navigate("/login");
    }
  };
  const fetchData = () => {
    dispatch(fetchDashboardData({}));
  };

  //Get the case list
  const getdashbordList = async () => {
    try {
      // setdashboardData([]);
      const body = {
        user_id: userData.id,
        master_id: userData.id,
      };
      const response = await api.post(API_URL.AUTH_DASHBOARD, body, token);
      if (response.status === 200) {
        // setdashboardData(response.result);
      } else {
        console.error("Failed to fetch Dashboard list");
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    // getdashbordList();
    fetchData();
  }, []);
  const displayData = (data) => {
    return data ? data : 0
  }

  const DisplayBlockObject = [
    {
      title: userData.role == Role.MASTER_VENDOR ? "Vendor Branches" : "My Branches",
      count: displayData(dashboardData?.master_branch_count),
      icon: GeneralIcon,
    },
    {
      title: "Total Clients",
      count: displayData(dashboardData?.client_count),
      icon: GeneralIcon,
    },
    {
      title: "Total Client Branches",
      count: displayData(dashboardData?.branch_count),
      icon: GeneralIcon,
    },
    {
      title: "Total User",
      count: displayData(dashboardData?.user_count),
      icon: GeneralIcon,
    },
    {
      title: "Target Of Month",
      count: "0",
      icon: GeneralIcon,
    },
    {
      title: "Target MTD",
      count: "0",
      icon: GeneralIcon,
    },
    {
      title: "Revenue Target",
      count: "0",
      icon: GeneralIcon,
    },
    {
      title: "Revenue MTD",
      count: "0",
      icon: GeneralIcon,
    },
    {
      title: "Revenue Achievement in %",
      count: "0",
      icon: GeneralIcon,
    },
    {
      title: "Shortfall",
      count: "0",
      icon: GeneralIcon,
    },
    {
      title: "Projected Expenses",
      count: "0",
      icon: GeneralIcon,
    },
  ]
  // const userCount = dashboardData.user_count || 0;
  // const branchCount = dashboardData.branch_count || 0;
  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <section id="dashboard">
            <div className="row">
              {[
                Role.MASTER_VENDOR,
                Role.BRANCH_MANAGER,
                Role.TEAM_LEAD,
                Role.BACK_OFFICE,
              ].includes(userData.role) && (
                  <>
                    {
                      DisplayBlockObject.map((element) => (DisplayBlock(element.title, element.count, element.icon)))
                    }
                    {/* <div className="col-md-6 col-lg-4 col-xxl-3">
                      <a className="card op-warm-blue">
                        <div className="card-body card-img">
                          <div className="icon">
                            <svg
                              width={51}
                              height={51}
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="23.2988"
                                cy="23.2988"
                                r="24.2988"
                                transform="matrix(1 0 0 -1 2 49)"
                                fill="#E7E7E7"
                                stroke="#5A639C"
                                strokeWidth={2}
                              />
                              <g clipPath="url(#clip0_347_57)">
                                <path
                                  d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                  fill="#5A639C"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_347_57">
                                  <rect
                                    width={25}
                                    height={25}
                                    fill="white"
                                    transform="translate(13 13)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="text-box">
                            <h6 className="card-title-4">My Branches</h6>
                            <h3 className="">{branchCount}</h3>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xxl-3">
                      <a className="card op-warm-blue">
                        <div className="card-body card-img">
                          <div className="icon">
                            <svg
                              width={51}
                              height={51}
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="23.2988"
                                cy="23.2988"
                                r="24.2988"
                                transform="matrix(1 0 0 -1 2 49)"
                                fill="#E7E7E7"
                                stroke="#5A639C"
                                strokeWidth={2}
                              />
                              <g clipPath="url(#clip0_347_57)">
                                <path
                                  d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                  fill="#5A639C"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_347_57">
                                  <rect
                                    width={25}
                                    height={25}
                                    fill="white"
                                    transform="translate(13 13)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="text-box">
                            <h6 className="card-title-4">Total Clients</h6>
                            <h3 className="">0</h3>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xxl-3">
                      <a className="card op-warm-blue">
                        <div className="card-body card-img">
                          <div className="icon">
                            <svg
                              width={51}
                              height={51}
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="23.2988"
                                cy="23.2988"
                                r="24.2988"
                                transform="matrix(1 0 0 -1 2 49)"
                                fill="#E7E7E7"
                                stroke="#5A639C"
                                strokeWidth={2}
                              />
                              <g clipPath="url(#clip0_347_57)">
                                <path
                                  d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                  fill="#5A639C"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_347_57">
                                  <rect
                                    width={25}
                                    height={25}
                                    fill="white"
                                    transform="translate(13 13)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="text-box">
                            <h6 className="card-title-4">Total User</h6>
                            <h3>{userCount}</h3>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xxl-3">
                      <a className="card op-warm-blue">
                        <div className="card-body card-img">
                          <div className="icon">
                            <svg
                              width={51}
                              height={51}
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="23.2988"
                                cy="23.2988"
                                r="24.2988"
                                transform="matrix(1 0 0 -1 2 49)"
                                fill="#E7E7E7"
                                stroke="#5A639C"
                                strokeWidth={2}
                              />
                              <g clipPath="url(#clip0_347_57)">
                                <path
                                  d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                  fill="#5A639C"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_347_57">
                                  <rect
                                    width={25}
                                    height={25}
                                    fill="white"
                                    transform="translate(13 13)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="text-box">
                            <h6 className="card-title-4">Target of month</h6>
                            <h3 className="">0</h3>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xxl-3">
                      <a className="card op-warm-blue">
                        <div className="card-body card-img">
                          <div className="icon">
                            <svg
                              width={51}
                              height={51}
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="23.2988"
                                cy="23.2988"
                                r="24.2988"
                                transform="matrix(1 0 0 -1 2 49)"
                                fill="#E7E7E7"
                                stroke="#5A639C"
                                strokeWidth={2}
                              />
                              <g clipPath="url(#clip0_347_57)">
                                <path
                                  d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                  fill="#5A639C"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_347_57">
                                  <rect
                                    width={25}
                                    height={25}
                                    fill="white"
                                    transform="translate(13 13)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="text-box">
                            <h6 className="card-title-4">Target MTD</h6>
                            <h3 className="">0</h3>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xxl-3">
                      <a className="card op-warm-blue">
                        <div className="card-body card-img">
                          <div className="icon">
                            <svg
                              width={51}
                              height={51}
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="23.2988"
                                cy="23.2988"
                                r="24.2988"
                                transform="matrix(1 0 0 -1 2 49)"
                                fill="#E7E7E7"
                                stroke="#5A639C"
                                strokeWidth={2}
                              />
                              <g clipPath="url(#clip0_347_57)">
                                <path
                                  d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                  fill="#5A639C"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_347_57">
                                  <rect
                                    width={25}
                                    height={25}
                                    fill="white"
                                    transform="translate(13 13)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="text-box">
                            <h6 className="card-title-4">Revenue Target</h6>
                            <h3 className="">0</h3>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xxl-3">
                      <a className="card op-warm-blue">
                        <div className="card-body card-img">
                          <div className="icon">
                            <svg
                              width={51}
                              height={51}
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="23.2988"
                                cy="23.2988"
                                r="24.2988"
                                transform="matrix(1 0 0 -1 2 49)"
                                fill="#E7E7E7"
                                stroke="#5A639C"
                                strokeWidth={2}
                              />
                              <g clipPath="url(#clip0_347_57)">
                                <path
                                  d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                  fill="#5A639C"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_347_57">
                                  <rect
                                    width={25}
                                    height={25}
                                    fill="white"
                                    transform="translate(13 13)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="text-box">
                            <h6 className="card-title-4">Revenue MTD</h6>
                            <h3 className="">0</h3>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xxl-3">
                      <a className="card op-warm-blue">
                        <div className="card-body card-img">
                          <div className="icon">
                            <svg
                              width={51}
                              height={51}
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="23.2988"
                                cy="23.2988"
                                r="24.2988"
                                transform="matrix(1 0 0 -1 2 49)"
                                fill="#E7E7E7"
                                stroke="#5A639C"
                                strokeWidth={2}
                              />
                              <g clipPath="url(#clip0_347_57)">
                                <path
                                  d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                  fill="#5A639C"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_347_57">
                                  <rect
                                    width={25}
                                    height={25}
                                    fill="white"
                                    transform="translate(13 13)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="text-box">
                            <h6 className="card-title-4">
                              Revenue Achievement in %
                            </h6>
                            <h3 className="">0</h3>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xxl-3">
                      <a className="card op-warm-blue">
                        <div className="card-body card-img">
                          <div className="icon">
                            <svg
                              width={51}
                              height={51}
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="23.2988"
                                cy="23.2988"
                                r="24.2988"
                                transform="matrix(1 0 0 -1 2 49)"
                                fill="#E7E7E7"
                                stroke="#5A639C"
                                strokeWidth={2}
                              />
                              <g clipPath="url(#clip0_347_57)">
                                <path
                                  d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                  fill="#5A639C"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_347_57">
                                  <rect
                                    width={25}
                                    height={25}
                                    fill="white"
                                    transform="translate(13 13)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="text-box">
                            <h6 className="card-title-4">Shortfall</h6>
                            <h3 className="">0</h3>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xxl-3">
                      <a className="card op-warm-blue">
                        <div className="card-body card-img">
                          <div className="icon">
                            <svg
                              width={51}
                              height={51}
                              viewBox="0 0 51 51"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="23.2988"
                                cy="23.2988"
                                r="24.2988"
                                transform="matrix(1 0 0 -1 2 49)"
                                fill="#E7E7E7"
                                stroke="#5A639C"
                                strokeWidth={2}
                              />
                              <g clipPath="url(#clip0_347_57)">
                                <path
                                  d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
                                  fill="#5A639C"
                                />
                                <path
                                  d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
                                  fill="#5A639C"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_347_57">
                                  <rect
                                    width={25}
                                    height={25}
                                    fill="white"
                                    transform="translate(13 13)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="text-box">
                            <h6 className="card-title-4">Projected Expenses</h6>
                            <h3 className="">0</h3>
                          </div>
                        </div>
                      </a>
                    </div> */}
                  </>
                )}

            </div>
            {/* garph-modal */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "50px",
              }}
            >
              <div style={{ height: "325px", width: "48%" }}>
                <AgCharts options={graph} />
              </div>
              <div style={{ height: "325px", width: "48%" }}>
                <AgCharts options={graph} />
              </div>
            </div>

            {/* </div> */}
          </section>
        </div>
      </div>
    </div>
  );
}
const DisplayBlock = (title, count, icon = GeneralIcon) => <div className="col-md-6 col-lg-4 col-xxl-3">
  <a className="card op-warm-blue">
    <div className="card-body card-img">
      <div className="icon">
        {GeneralIcon()}
      </div>
      <div className="text-box">
        <h6 className="card-title-4">{title}</h6>
        <h3 className="">{count}</h3>
      </div>
    </div>
  </a>
</div>
const GeneralIcon = () => <svg
  width={51}
  height={51}
  viewBox="0 0 51 51"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle
    cx="23.2988"
    cy="23.2988"
    r="24.2988"
    transform="matrix(1 0 0 -1 2 49)"
    fill="#E7E7E7"
    stroke="#5A639C"
    strokeWidth={2}
  />
  <g clipPath="url(#clip0_347_57)">
    <path
      d="M33.0684 13.4287V17.8827H37.522L33.0684 13.4287Z"
      fill="#5A639C"
    />
    <path
      d="M32.3359 19.3477C31.9314 19.3477 31.6035 19.0197 31.6035 18.6152V13H21.1055C19.8939 13 18.9082 13.9857 18.9082 15.1973V23.3852C19.1495 23.3633 19.3937 23.3516 19.6406 23.3516C22.1371 23.3516 24.3719 24.493 25.8509 26.2812H33.8008C34.2053 26.2812 34.5332 26.6092 34.5332 27.0137C34.5332 27.4182 34.2053 27.7461 33.8008 27.7461H26.8158C27.2736 28.6395 27.5688 29.6292 27.6636 30.6758H33.8008C34.2053 30.6758 34.5332 31.0037 34.5332 31.4082C34.5332 31.8127 34.2053 32.1406 33.8008 32.1406H27.6636C27.4446 34.5595 26.1522 36.6731 24.2675 38H35.7539C36.9655 38 37.9512 37.0143 37.9512 35.8027V19.3477H32.3359ZM33.8008 23.3516H23.0586C22.6541 23.3516 22.3262 23.0236 22.3262 22.6191C22.3262 22.2146 22.6541 21.8867 23.0586 21.8867H33.8008C34.2053 21.8867 34.5332 22.2146 34.5332 22.6191C34.5332 23.0236 34.2053 23.3516 33.8008 23.3516Z"
      fill="#5A639C"
    />
    <path
      d="M19.6406 24.8164C16.0059 24.8164 13.0488 27.7735 13.0488 31.4082C13.0488 35.0429 16.0059 38 19.6406 38C23.2753 38 26.2324 35.0429 26.2324 31.4082C26.2324 27.7735 23.2753 24.8164 19.6406 24.8164ZM21.5938 32.1406H19.6406C19.2361 32.1406 18.9082 31.8127 18.9082 31.4082V28.4785C18.9082 28.074 19.2361 27.7461 19.6406 27.7461C20.0451 27.7461 20.373 28.074 20.373 28.4785V30.6758H21.5938C21.9982 30.6758 22.3262 31.0037 22.3262 31.4082C22.3262 31.8127 21.9982 32.1406 21.5938 32.1406Z"
      fill="#5A639C"
    />
  </g>
  <defs>
    <clipPath id="clip0_347_57">
      <rect
        width={25}
        height={25}
        fill="white"
        transform="translate(13 13)"
      />
    </clipPath>
  </defs>
</svg>

export default Dashboard;
