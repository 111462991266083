import React, { useState, useEffect, useContext } from "react";
import logo from "../../assets/images/logo.png";
import { Form, Input, Checkbox, Button, Alert } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import api from "../../services/Api";
import { useToast } from "../../utils/helpers/CommFun";
import { useLocation } from "react-router-dom";
import loginBg from "../../assets/images/login-bg.png";
import { useSelector } from "react-redux";
function Password() {
  const [form] = Form.useForm();
  const Toast = useToast();
  const location = useLocation();
  const [userId, setUserId] = useState(location.state?.user_id || null);
  const navigate = useNavigate();

  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user_data");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleSave = async (values) => {
    const { currentPassword, newPassword } = values;

    try {
      const body = {
        user_id: userId,
        current_password: currentPassword,
        new_password: newPassword,
      };

      const response = await api.post("/user/change_password", body);

      if (response.status === 200) {
        Toast.fire({
          icon: "success",
          title: "Password change Sucessfully",
        });
        navigate("/login");
      } else {
        Toast.fire({
          icon: "error",
          title: response.data.message || "Failed to change password",
        });
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "An error occurred while changing password",
      });
      console.error("Error changing password:", error);
    }
  };
  const newPasswordValidator = (rule, value, callback) => {
    const currentPassword = form.getFieldValue("currentPassword");
    if (value == currentPassword) {
      callback("New Password can not be same as Current Password");
    } else {
      callback();
    }
  };
  const confirmPasswordValidator = (rule, value, callback) => {
    const newPassword = form.getFieldValue("newPassword");
    if (value !== newPassword) {
      callback("Passwords do not match");
    } else {
      callback();
    }
  };

  return (
    <section id="login" class="com-form">
      <div className="login-part">
        <div className="container-fluid">
          <div className="login-box">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div class="left">
                  <div class="img-box">
                    <img src={loginBg} alt="Login Background" />
                    <div className="text-box">
                      <p>Nice to see you again</p>
                      <h3>welcome back</h3>
                      <div className="line"></div>
                      <h4>Login Now</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right-box">
                  <div className="login-box">
                    <div div className="form-box">
                      <div className="logo-box">
                        <img src={logo} alt="logo" />
                      </div>
                      <Form form={form} onFinish={handleSave} layout="vertical">
                        <Form.Item
                          label=" Current Password"
                          name="currentPassword"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Current password!",
                            },
                          ]}
                        >
                          <Input.Password
                            maxLength={50}
                            placeholder="Enter current  password"
                            iconRender={(visible) =>
                              visible ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                            className="form-custom-control"
                          />
                        </Form.Item>
                        <Form.Item
                          label=" New Password"
                          name="newPassword"
                          rules={[
                            {
                              required: true,
                              message: "Please enter New Password!",
                            },
                            {
                              validator: newPasswordValidator,
                            },
                          ]}
                        >
                          <Input.Password
                            maxLength={50}
                            placeholder="Enter New password"
                            iconRender={(visible) =>
                              visible ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                            className="form-custom-control"
                          />
                        </Form.Item>
                        <Form.Item
                          label=" Confirm Password"
                          name="confirmPassword"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Confirm  password!",
                            },
                            {
                              validator: confirmPasswordValidator,
                            },
                          ]}
                        >
                          <Input.Password
                            maxLength={50}
                            placeholder="Enter Confirm  password"
                            iconRender={(visible) =>
                              visible ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                            className="form-custom-control"
                          />
                        </Form.Item>
                        <div className="Remember-box"></div>
                        <Form.Item>
                          <Button htmlType="submit" className="btns submit-btn">
                            save
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Password;
