import { Form, Checkbox, Button } from "antd";
import DynamicForm from "../components/DynamicForm/DynamicForm";

const NestedTableDemo = () => {
  const onFinish = (values) => {
    console.log("Submitted values:", values);
  };

  const formFields = [
    {
      label: "Select Options",
      name: "options",
      type: "checkbox-group",
      options: [
        { label: "Option A", value: "A" },
        { label: "Option B", value: "B" },
        { label: "Option C", value: "C" }
      ],
      rules: [{ required: true, message: "Please select at least one option" }]
    }
  ];

  const renderField = (field) => {
    switch (field.type) {
      case "checkbox-group":
        return (
          <Form.Item
            label={field.label}
            name={field.name}
            rules={field.rules}
          >
            <Checkbox.Group options={field.options} />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  return (
    <DynamicForm config={formFields} onFinish={onFinish} />
    // <Form onFinish={onFinish} layout="vertical">
    //   {formFields.map((field) => renderField(field))}

    //   <Form.Item>
    //     <Button type="primary" htmlType="submit">
    //       Submit
    //     </Button>
    //   </Form.Item>
    // </Form>
  );
};

export default NestedTableDemo;
