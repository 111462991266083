import { createSlice } from "@reduxjs/toolkit";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import { createOrUpdateData, deleteData, fetchData } from "../utils/apiActions";

export const fetchAllCategories = fetchData(
  "category",
  API_URL.GET_ALL_DOC_CATEGORY
);


export const createOrUpdateCategory = createOrUpdateData(
  "category",
  API_URL.CREATE_CATEGORY,
  API_URL.UPDATE_CATEGORY
);

export const deleteCategory = deleteData("category", API_URL.REMOVE_CATEGORY);

const categorySlice = createSlice({
  name: "category",
  initialState: {
    categories: [],
    selectedcategory: null,
    status: STATUS_TYPE.IDLE,
    total: 0,
    error: null,
  },
  reducers: {
    categoryStatusReset: (state) => {
      state.status = STATUS_TYPE.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCategories.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllCategories.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.categories = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchAllCategories.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(createOrUpdateCategory.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(createOrUpdateCategory.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        // Optional: Handle additional logic on successful category creation/updation
      })
      .addCase(createOrUpdateCategory.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      });
  },
});

export const { categoryStatusReset } = categorySlice.actions;
export default categorySlice.reducer;
