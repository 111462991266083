import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import AdminDashboard from "../pages/dashboard/Dashboard";
import CaseManagement from "../pages/case-management/CaseManagement";
import UserMenagement from "../pages/user-management/UsersManagement";
import AddNewuserForm from "../pages/user-management/AddNewUserForm";
import ClientManagement from "../pages/client-management/ClientManagement";
import PickedUpCases from "../pages/picked-case/pickedCases";
import UpdateCase from "../pages/picked-case/UpdateCase";
import AddNewCase from "../pages/case-management/AddNewCase";
import AddNewCaseWithApplicant from "../pages/case-management/AddNewCaseWithApplicant";
import Profile from "../pages/Profile";
import ChangePassword from "../pages/ChangePassword";
import DocumentConfiguration from "../pages/document-configuration/DocumentConfiguration";
import AddNewDocumentConfig from "../pages/document-configuration/AddNewDocumentConfig";
import CategoryManagement from "../pages/category-management/CategoryManagement";
import Privacy from "../pages/settings/privacy";
import Terms from "../pages/settings/terms";
import field from "../pages/field-management/field";
import addFeild from "../pages/field-management/addFeild";
import AddPointForm from "../pages/case-management/AddPointForm";
import { PDFViewerPage } from "../pages/pdf-viewer/pdf-viewer";
import ClientBranchManagement from "../pages/client-branch-management/ClientBranchManagement";
import BranchManagement from "../pages/branch-management/BranchManagement";
import AddBranchManagement from "../pages/branch-management/AddBranchManagement";
import HolidayManagement from "../pages/holiday-management/HolidayManagement";
import ZoneManagement from "../pages/zone-management/ZoneManagement";
import AddClientBranch from "../pages/client-branch-management/AddClientBranch";
import PaymentManagement from "../pages/payment-management/PaymentManagement";
import AddNewPayment from "../pages/payment-management/AddNewPayment";
import NestedTableDemo from "../pages/test";
import PincodeManagement from "../pages/pincode-management/PincodeManagement";
import AddNewPincode from "../pages/pincode-management/AddNewPincode";
import TargetManagement from "../pages/target-management/TargetHistory";
import AddNewTarget from "../pages/target-management/AddNewTarget";
import TargetHistory from "../pages/target-management/TargetHistory";
import BranchTabs from "../pages/branch-management/BranchTabs";
import AddMultipleCase from "../pages/case-management/AddMultipleCase";

const CommonRoutes = () => {
  const userRole = JSON.parse(localStorage.getItem("user_data")).role;

  return (
    <Routes>
      <Route
        path="dashboard"
        element={<PrivateRoute component={AdminDashboard} roles={[userRole]} />}
      />

      {/* CaseManagement  */}
      <Route
        path="case"
        element={<PrivateRoute component={CaseManagement} roles={[userRole]} />}
      />
      <Route
        path="case/edit"
        element={<PrivateRoute component={AddNewCase} roles={[userRole]} />}
      />
      <Route
        path="case/addCase"
        element={<PrivateRoute component={AddNewCaseWithApplicant} roles={[userRole]} />}
      />
      <Route
        path="case/addMultipleCase"
        element={<PrivateRoute component={AddMultipleCase} roles={[userRole]} />}
      />
      <Route
        path="case/addpointform"
        element={<PrivateRoute component={AddPointForm} roles={[userRole]} />}
      />
      {/*
      <Route
        path="pickedcases"
        element={<PrivateRoute component={PickedUpCases} roles={[userRole]} />}
      />

      {/* User Management */}
      <Route
        path="user"
        element={<PrivateRoute component={UserMenagement} roles={[userRole]} />}
      />
      <Route
        path="user/add"
        element={<PrivateRoute component={AddNewuserForm} roles={[userRole]} />}
      />

      {/* clientmanagement */}
      <Route
        path="client"
        element={
          <PrivateRoute component={ClientManagement} roles={[userRole]} />
        }
      />

      {/* Document Configuration */}
      <Route
        path="documentconfiguration"
        element={
          <PrivateRoute component={DocumentConfiguration} roles={[userRole]} />
        }
      />
      <Route
        path="documentconfiguration/addnewdocumentconfig"
        element={
          <PrivateRoute component={AddNewDocumentConfig} roles={[userRole]} />
        }
      />
      {/*  Category Management  */}
      <Route
        path="categoryManagement"
        element={
          <PrivateRoute component={CategoryManagement} roles={[userRole]} />
        }
      />
      {/* Payment Management */}
      <Route
        path="payment"
        element={
          <PrivateRoute component={PaymentManagement} roles={[userRole]} />
        }
      />
      <Route
        path="payment/add"
        element={<PrivateRoute component={AddNewPayment} roles={[userRole]} />}
      />

      {/* Pincode Management */}
      <Route
        path="pincode"
        element={
          <PrivateRoute component={PincodeManagement} roles={[userRole]} />
        }
      />
      <Route
        path="pincode/add"
        element={<PrivateRoute component={AddNewPincode} roles={[userRole]} />}
      />
      {/* Target Management */}
      <Route
        path="target"
        element={
          <PrivateRoute component={TargetManagement} roles={[userRole]} />
        }
      />
      <Route
        path="target/add"
        element={<PrivateRoute component={AddNewTarget} roles={[userRole]} />}
      />
      <Route
        path="target/history"
        element={<PrivateRoute component={TargetHistory} roles={[userRole]} />}
      />

      {/* clientbranchmanagement */}
      <Route
        path="clientbranch"
        element={
          <PrivateRoute component={ClientBranchManagement} roles={[userRole]} />
        }
      />
      <Route
        path="clientbranch/add"
        element={
          <PrivateRoute component={AddClientBranch} roles={[userRole]} />
        }
      />
      {/* branchmanagement */}
      <Route
        path="branch"
        element={
          <PrivateRoute component={BranchManagement} roles={[userRole]} />
        }
      />
      {/* Addbranchmanagement */}
      <Route
        path="branch/add"
        element={
          <PrivateRoute component={AddBranchManagement} roles={[userRole]} />
        }
      />
      {/* BranchTabs */}
      <Route
        path="branch/info"
        element={
          <PrivateRoute component={BranchTabs} roles={[userRole]} />
        }
      />
      {/* holiday mangaement */}
      <Route
        path="holiday"
        element={
          <PrivateRoute component={HolidayManagement} roles={[userRole]} />
        }
      />
      {/* {Zone management} */}
      <Route
        path="zone"
        element={<PrivateRoute component={ZoneManagement} roles={[userRole]} />}
      />
      {/* Picked Cases */}
      <Route
        path="pickedcases"
        element={<PrivateRoute component={PickedUpCases} roles={[userRole]} />}
      />
      <Route
        path="updatecase"
        element={<PrivateRoute component={UpdateCase} roles={[userRole]} />}
      />
      {/* PDF Viewer Page */}
      <Route
        path="pdfpage"
        element={<PrivateRoute component={PDFViewerPage} roles={[userRole]} />}
      />
      {/* Other admin-specific routes */}

      <Route
        path="profile"
        element={<PrivateRoute component={Profile} roles={[userRole]} />}
      />
       <Route
        path="changepassword"
        element={<PrivateRoute component={ChangePassword} roles={[userRole]} />}
      />
      <Route
        path="field"
        element={<PrivateRoute component={field} roles={[userRole]} />}
      />
      <Route
        path="field/add"
        element={<PrivateRoute component={addFeild} roles={[userRole]} />}
      />
      <Route
        path="setting/privacypolicy"
        element={<PrivateRoute component={Privacy} roles={[userRole]} />}
      />
      <Route
        path="setting/term&conditions"
        element={<PrivateRoute component={Terms} roles={[userRole]} />}
      />
      <Route
        path="test"
        element={
          <PrivateRoute component={NestedTableDemo} roles={[userRole]} />
        }
      />
      
      {/* <Route path="*" element={<Navigate to="/404" />} /> */}
    </Routes>
  );
};

export default CommonRoutes;
