import { Col } from "antd";
import { Role } from "../../utils/constant/Constant";

const commonObjects = [
  {
    label: "First Name",
    name: "firstName",
    type: "input",
    rules: [{ required: true, message: "Please Enter First Name" }],
    colSpan: 6,
    maxLength:15
  },
  {
    label: "Middle Name",
    name: "middleName",
    type: "input",
    colSpan: 6,
    maxLength:15
  },
  {
    label: "Last Name",
    name: "lastName",
    type: "input",
    rules: [{ required: true, message: "Please Enter Last Name" }],
    colSpan: 6,
    maxLength:15
  },
  {
    label: "Email",
    name: "email",
    type: "input",
    maxLength:50,
    rules: [
      { required: true, message: "Please enter your email" },
      { type: "email", message: "Please enter a valid email" },
    ],
    colSpan: 6,
    disabled: false,
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    maxLength:25,
    rules: [{ required: true, message: "Please Enter Password" }],
    colSpan: 6,
  },
  {
    label: "Mobile 01",
    name: "mobile_01",
    type: "number-input",
    maxLength:10,
    rules: [
      { required: true, message: "Please Enter Mobile 1" },
      {
        pattern: /^[0-9]{10}$/,
        message: "Phone number must be exactly 10 digits",
      },
    ],
    colSpan: 6,
  },
  {
    label: "Mobile 02",
    name: "mobile_02",
    type: "number-input",
    maxLength:10,
    rules: [
      {
        pattern: /^[0-9]{10}$/,
        message: "Phone number must be exactly 10 digits",
      },
    ],
    colSpan: 6,
  },
  {
    label: "Home Mobile",
    name: "home_mobile",
    type: "number-input",
    maxLength:10,
    rules: [
      {
        pattern: /^[0-9]{10}$/,
        message: "Phone number must be exactly 10 digits",
      },
    ],
    colSpan: 6,
  },
  {
    label: "Address",
    name: "address",
    type: "input",
    maxLength:250,
    colSpan: 6,
  },

  {
    label: "Pincode",
    name: "pincode",
    type: "number-input",
    maxLength:6,
    colSpan: 6,
    rules: [
      { required: true, message: "Please Enter Pincode" },
      {
        pattern: /^[0-9]{6}$/,
        message: "Pincode must be exactly 6 digits",
      },
    ],
  },
  {
    label: "City",
    name: "city",
    type: "input",
    rules: [
      {
        required: true,
        message: "Please Enter Valid Pincode to Fetch City",
      },
    ],
    colSpan: 6,
    disabled: true,
  },
  {
    label: "State",
    name: "state",
    type: "input",
    rules: [
      {
        required: true,
        message: "Please Enter Valid Pincode to Fetch State",
      },
    ],
    disabled: true,
    colSpan: 6,
  },
  {
    label: "Relation",
    name: "relation",
    type: "select",
    options: [
      { value: "father", label: "Father" },
      { value: "mother", label: "Mother" },
      { value: "brother", label: "Brother" },
      { value: "sister", label: "Sister" },
      { value: "wife", label: "Wife" },
      { value: "other", label: "Other" },
    ],
    colSpan: 6,
  },
  {
    label: "Relation Name",
    name: "relation_name",
    type: "input",
    colSpan: 6,
    maxLength:15,
  },
  {
    label: "Relation Mobile",
    name: "relation_mobile",
    maxLength:10,
    type: "number-input",
    
    rules: [
      {
        pattern: /^[0-9]{10}$/,
        message: "Phone number must be exactly 10 digits",
      },
    ],
    colSpan: 6,
  },
  // {
  //   label: "User Id",
  //   name: "userId",
  //   type: "hidden",
  //   initialValue: userId,
  // },
  {
    label: "pincodeId",
    name: "pincodeId",
    type: "hidden",
    initialValue: null,
  },
];

const adminCommonObject = [
  {
    label: "Name",
    name: "firstName",
    type: "input",
    rules: [{ required: true, message: "Please Enter First Name" }],
    maxLength:15,
    colSpan: 6,
  },
  {
    label: "Email",
    name: "email",
    type: "input",
    rules: [
      { required: true, message: "Please enter your email" },
      { type: "email", message: "Please enter a valid email" },
    ],
    colSpan: 6,
    maxLength:50,
    disabled: false,
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    maxLength:25,
    rules: [{ required: true, message: "Please Enter Password" }],
    colSpan: 6,
  },
  {
    label: "Mobile 01",
    name: "mobile_01",
    maxLength:10,
    type: "number-input",
    rules: [
      { required: true, message: "Please Enter Mobile 1" },
      {
        pattern: /^[0-9]{10}$/,
        message: "Phone number must be exactly 10 digits",
      },
    ],
    colSpan: 6,
  },
  {
    label: "Address",
    name: "address",
    maxLength:250,
    type: "input",
    colSpan: 6,
  },

  {
    label: "Pincode",
    name: "pincode",
    type: "number-input",
    maxLength:6,
    colSpan: 6,
    rules: [
      { required: true, message: "Please Enter Pincode" },
      {
        pattern: /^[0-9]{6}$/,
        message: "Pincode must be exactly 6 digits",
      },
    ],
  },
  {
    label: "City",
    name: "city",
    type: "input",
    rules: [
      {
        required: true,
        message: "Please Enter Valid Pincode to Fetch City",
      },
    ],
    colSpan: 6,
    disabled: true,
  },
  {
    label: "State",
    name: "state",
    type: "input",
    rules: [
      {
        required: true,
        message: "Please Enter Valid Pincode to Fetch State",
      },
    ],
    disabled: true,
    colSpan: 6,
  },
  {
    label: "pincodeId",
    name: "pincodeId",
    type: "hidden",
    initialValue: null,
  },
];

export const baseBranch = {
  label: "Base Branch",
  name: "branch",
  type: "select",
  options: [],
  rules: [{ required: true, message: "Please select an option" }],
  colSpan: 6,
};

export const assignVendorBranch = {
  label: "Assign Branch",
  name: "assign_vendor_branch",
  type: "multiple-select",
  options: [],
  colSpan: 24,
}

export const resume = {
  label: "Resume",
  name: "resume",
  accept: ".pdf",
  type: "upload",
  colSpan: 2,
};

export const address = {
  label: "Address",
  name: "addressProof",
  accept: ".jpg,.jpeg,.png",
  type: "upload",
  colSpan: 2,
};

export const identity = {
  label: "Identity",
  name: "identityProof",
  type: "upload",
  accept: ".jpg,.jpeg,.png",
  colSpan: 2,
};

export const photo_proof = {
  label: "Photo",
  name: "photoProof",
  accept: ".jpg,.jpeg,.png",
  type: "upload",
  colSpan: 2,
};

export const signature = {
  label: "Signature",
  name: "signature",
  accept: ".jpg,.jpeg,.png",
  type: "upload",
  colSpan: 2,
};

export const stamp = {
  label: "Stamp",
  name: "stamp",
  accept: ".jpg,.jpeg,.png",
  type: "upload",
  colSpan: 2,
};

export const clientBranch = {
  label: "Client Branch",
  name: "assign_client_branches",
  type: "multiple-select",
  options: [],
  colSpan: 24,
};

export const assignPincode = {
  label: "Pincode",
  name: "assign_pincodes",
  type: "multiple-select",
  options: [],
  colSpan: 24,
};

export const logo = {
  label: "Logo",
  name: "logoPhoto",
  accept: ".jpg,.jpeg,.png",
  type: "upload",
  colSpan: 2,
};

export const BankFormConfig = [[...adminCommonObject], [logo]];

export const MasterVendorFormConfig = [
  [
  ...adminCommonObject,
  {
    label: "GST No.",
    name: "gst_no",
    type: "input",
    rules: [{ required: true, message: "Please Enter the GST No." }],
    maxLength:15,
    colSpan: 6,
  },
  {
    label: "Pan No.",
    name: "pan_no",
    type: "input",
    maxLength:10,
    rules: [{ required: true, message: "Please Enter the PAN No." }],
    colSpan: 6,
  },
  {
    label: "GST Percentage",
    name: "gst_per",
    type: "number-float",
    maxLength:5,
    rules: [{ required: true, message: "Please Enter the GST Percentage" }],
    colSpan: 6,
  }],
  [
  logo]
];

export const BranchVendorFormConfig = [
  [
  ...commonObjects
  ],
  [
    identity,
    address,
    photo_proof,
    stamp,
    signature,
  ]
];


export const TeamLeadFormConfig = [
  [baseBranch, ...commonObjects, assignVendorBranch],
  [identity,address,photo_proof,resume, signature ] 
];


export const BackOfficeFormConfig = [
  [
  baseBranch,
  {
    label: "Team Lead",
    name: "teamLead",
    type: "select",
    options: [],
    colSpan: 6,
  },
  ...commonObjects,
  clientBranch,
  assignVendorBranch
],
[ identity , address, photo_proof, resume]
]


export const FieldExecFormConfig = [
  [
    baseBranch,
    ...commonObjects,
    clientBranch,
    assignPincode,
  ],
  [identity, address, photo_proof, resume],
];

export const userTypeOptions = [
  { value: Role.BANKS, label: "Banks" },
  { value: Role.MASTER_VENDOR, label: "Master Vendor" },
  { value: Role.BRANCH_MANAGER, label: "Branch Manager" },
  { value: Role.TEAM_LEAD, label: "Teamlead" },
  { value: Role.BACK_OFFICE, label: "Back Office" },
  { value: Role.FE_PE, label: "FE/PE" },
];
