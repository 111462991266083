// features/user/userSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import {
  createOrUpdateData,
  deleteData,
  fetchData,
  updateStatus,
} from "../utils/apiActions";

export const fetchAllClients = fetchData("client", API_URL.GET_ALL_CLIENT);

export const fetchClientPayment = fetchData(
  "client",
  API_URL.GET_CLIENT_DATA,
  "clientPayment"
);

export const createOrUpdateClient = createOrUpdateData(
  "client",
  API_URL.CREATE_CLIENT,
  API_URL.UPDATE_CLIENT
);

export const updateClientStatus = updateStatus(
  "client",
  API_URL.UPDATE_CLIENT_STATUS
);

export const deleteClient = deleteData("client", API_URL.DELETE_CLIENT);

const clientSlice = createSlice({
  name: "client",
  initialState: {
    clients: [],
    clientPayments: [],
    selectedClient: null,
    status: STATUS_TYPE.IDLE,
    total: 0,
    error: null,
  },
  reducers: {
    clientStatusReset: (state) => {
      state.status = STATUS_TYPE.IDLE;
    },
    resetClientPayment: (state) => {
      state.clientPayments = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllClients.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllClients.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.clients = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchAllClients.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchClientPayment.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchClientPayment.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.clientPayments = action.payload;
      })
      .addCase(fetchClientPayment.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(updateClientStatus.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(updateClientStatus.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        const data = action.payload;
        state.clients = state.clients.map((client) =>
          client.id === data.client_id
            ? { ...client, status: data.status }
            : client
        );
      })
      .addCase(updateClientStatus.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload; // Error message from `rejectWithValue`
      })
      .addCase(createOrUpdateClient.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(createOrUpdateClient.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        // Optional: Handle additional logic on successful client creation/updation
      })
      .addCase(createOrUpdateClient.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(deleteClient.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(deleteClient.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      });
  },
});

export const { clientStatusReset, resetClientPayment } = clientSlice.actions;
export default clientSlice.reducer;
