import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUrlObj } from "../utils/helpers/CommFun";
import { Role } from "../utils/constant/Constant";
import DynamicForm from "../components/DynamicForm/DynamicForm";
import { createOrUpdateUser } from "../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../redux/slices/authSlice";
import SkeletonForm from "../components/DynamicForm/SkeletonForm";
function Profile() {
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.auth);
  const [initialValue, setInitialValue] = useState(null);

  const navigate = useNavigate();

  //update profile
  const updateProfile = async (values) => {
    const formData = new FormData();

    formData.append("user_id", values.userId ?? "");
    formData.append("first_name", values.firstName);
    formData.append("middle_name", values.middleName ?? "");
    formData.append("last_name", values.lastName ?? "");
    formData.append("mobile", values.mobile_01 ?? "");
    const appendFile = async (key, file) => {
      if (file) {
        formData.append(key, file[0].originFileObj);
      }
    };
    appendFile("profile_image", values.profileImage ?? "");
    appendFile("logo", values.logoPhoto ?? "");
    appendFile("signature", values.signature ?? "");
    const isUpdate = values.userId ? true : false;

    if (values.userId) {
      dispatch(createOrUpdateUser({ formData, isUpdate }))
        .unwrap()
        .then((response) => {
          const body = {
            user_id: JSON.parse(userData.id),
          };
          dispatch(fetchUserData(body));
          if (userData.role == Role.SUPER_ADMIN) {
            navigate("/super-admin/profile");
          } else if (
            userData.role == Role.BANKS ||
            userData.role == Role.MASTER_VENDOR
          ) {
            navigate("/admin/profile");
          } else if (
            userData.role == Role.BRANCH_MANAGER ||
            userData.role == Role.TEAM_LEAD ||
            userData.role == Role.BACK_OFFICE
          ) {
            navigate("/user/profile");
          } else {
            navigate("/login");
          }
        })
        .catch((error) => {});
    }
  };

  const onredirect = async () => {
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/dashboard");
    } else if (
      userData.role == Role.BANKS ||
      userData.role == Role.MASTER_VENDOR
    ) {
      navigate("/admin/dashboard");
    } else if (
      userData.role == Role.BRANCH_MANAGER ||
      userData.role == Role.TEAM_LEAD ||
      userData.role == Role.BACK_OFFICE
    ) {
      navigate("/user/dashboard");
    } else {
      navigate("/login");
    }
  };

  //set role name
  const setroleName = (role) => {
    if (role === Role.SUPER_ADMIN) {
      return "Super Admin";
    } else if (role === Role.BANKS) {
      return "Bank";
    } else if (role === Role.MASTER_VENDOR) {
      return "Master Vendor";
    } else if (role === Role.BRANCH_MANAGER) {
      return "Bank Manager";
    } else if (role === Role.TEAM_LEAD) {
      return "TL";
    } else if (role === Role.BACK_OFFICE) {
      return "Bank Office";
    }
  };

  //For Update Fetch User Details, after the required fields are added
  useEffect(() => {
    fetchData();
  }, [dispatch]);

  const fetchData = () => {
    const body = {
      user_id: JSON.parse(userData.id),
    };
    dispatch(fetchUserData(body));
  }


  useEffect(() => {
    if (userData) {
      const userDetails = {
        userId: userData.id,
        firstName: userData.first_name,
        middleName: userData.middle_name,
        lastName: userData.last_name,

        email: userData.email,
        mobile_01: userData.mobile ? parseInt(userData.mobile) : null,
        role: setroleName(userData.role),

        logoPhoto: userData.logo
          ? createUrlObj("logoPhoto", userData.logo)
          : undefined,
        profileImage: userData.profile_image
          ? createUrlObj("profileImage", userData.profile_image)
          : undefined,

        signature: userData.signature
          ? createUrlObj("signature", userData.signature)
          : undefined,
      };
      setInitialValue(userDetails);
    }
  }, [userData]);

  const rolesWithCommonUsers = [
    Role.BRANCH_MANAGER,
    Role.TEAM_LEAD,
    Role.BACK_OFFICE,
  ];
  const formConfig = [
    [
    {
      label:
        userData.role == Role.SUPER_ADMIN ||
          userData.role == Role.BANKS ||
          userData.role == Role.MASTER_VENDOR
          ? "Name"
          : "First Name",
      name: "firstName",
      type: "input",
      rules: [{ required: true, message: "Please Enter  Name" }],
      colSpan: 6,
      maxLength:15
    },

    rolesWithCommonUsers.includes(userData.role) && {
      label: "Middle Name",
      name: "middleName",
      type: "input",
      maxLength:15,
      colSpan: 6,
    },

    rolesWithCommonUsers.includes(userData.role) && {
      label: "Last Name",
      name: "lastName",
      type: "input",
      maxLength:15,
      colSpan: 6,
    },
    {
      label: "Email",
      name: "email",
      type: "input",
      maxLength:50,
      rules: [
        { required: true, message: "Please enter your email" },
        { type: "email", message: "Please enter a valid email" },
      ],
      colSpan: 6,
      disabled: true,
    },
    {
      label: "Mobile 01",
      name: "mobile_01",
      type: "number-input",
      maxLength:10,
      rules: [
        { required: true, message: "Please Enter Mobile 1" },
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 6,
    },
    {
      label: "Role",
      name: "role",
      type: "input",
      colSpan: 6,
      disabled: true,
    }],
    [
    {
      label: "Profile Image",
      name: "profileImage",
      accept: ".jpg,.jpeg,.png",
      type: "upload",
      maxCount: 1, // Limit to one file upload
      colSpan: 6,
    },
    userData.role === Role.MASTER_VENDOR && {
      label: "Logo",
      name: "logoPhoto",
      accept: ".jpg,.jpeg,.png",
      type: "upload",
      colSpan: 6,
    },
    (userData.role === Role.BRANCH_MANAGER ||
      userData.role === Role.TEAM_LEAD) && {
      label: "Signature",
      name: "signature",
      accept: ".jpg,.jpeg,.png",
      type: "upload",
      colSpan: 6,
    },
    {
      label: "User Id",
      name: "userId",
      type: "hidden",
    }]
  ];
  const handleFieldChange = (changedValues) => {
    setInitialValue((prev) => {
      return { ...prev, ...changedValues };
    });
  };


  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5>Profile</h5>
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
          <section id="profile-management-form">
            <div className="add_user-box">
              <div className="add_user-form">
              {
           (formConfig && setInitialValue.id && !initialValue)
          ? 
           (
              <SkeletonForm
              key={initialValue}
              config={formConfig}
              initialValues={initialValue}
              onFinish={updateProfile}
              handleFieldChange={handleFieldChange}
              onCancel={onredirect}
              showCancle={false}
                />
             
               ) : (
               (formConfig && ( setInitialValue.id && initialValue)) || (formConfig && ! setInitialValue.id) ? (
                <DynamicForm
                  key={initialValue}
                  config={formConfig}
                  initialValues={initialValue}
                  onFinish={updateProfile}
                  handleFieldChange={handleFieldChange}
                  onCancel={onredirect}
                  showCancle={false}
                />
              ) : "" 
            )} 
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Profile;
