export const DashboardIcon = () => (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="dashboard-icon"
    >
      <g clipPath="url(#clip0_2100_1381)">
        <mask
          id="mask0_2100_1381"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={18}
          height={18}
        >
          <path d="M0 1.90735e-06H18V18H0V1.90735e-06Z" fill="white" />
        </mask>
        <g mask="url(#mask0_2100_1381)">
          <path
            d="M7.73437 7.73438C7.73437 8.51101 7.10476 9.14062 6.32812 9.14062H2.10937C1.33274 9.14062 0.703125 8.51101 0.703125 7.73438V2.10938C0.703125 1.33274 1.33274 0.703125 2.10937 0.703125H6.32812C7.10476 0.703125 7.73437 1.33274 7.73437 2.10938V7.73438Z"
            stroke="#3D3D3D"
            strokeWidth="1.2"
            strokeMiterlimit={10}
          />
          <path
            d="M17.2969 15.8906C17.2969 16.6673 16.6673 17.2969 15.8906 17.2969H11.6719C10.8952 17.2969 10.2656 16.6673 10.2656 15.8906V10.2656C10.2656 9.48899 10.8952 8.85938 11.6719 8.85938H15.8906C16.6673 8.85938 17.2969 9.48899 17.2969 10.2656V15.8906Z"
            stroke="#3D3D3D"
            strokeWidth="1.2"
            strokeMiterlimit={10}
          />
          <path
            d="M7.73437 15.8906C7.73437 16.6673 7.10476 17.2969 6.32812 17.2969H2.10937C1.33274 17.2969 0.703125 16.6673 0.703125 15.8906V13.0781C0.703125 12.3015 1.33274 11.6719 2.10937 11.6719H6.32812C7.10476 11.6719 7.73437 12.3015 7.73437 13.0781V15.8906Z"
            stroke="#3D3D3D"
            strokeWidth="1.2"
            strokeMiterlimit={10}
          />
          <path
            d="M17.2969 4.92188C17.2969 5.69851 16.6673 6.32812 15.8906 6.32812H11.6719C10.8952 6.32812 10.2656 5.69851 10.2656 4.92188V2.10938C10.2656 1.33274 10.8952 0.703126 11.6719 0.703126H15.8906C16.6673 0.703126 17.2969 1.33274 17.2969 2.10938V4.92188Z"
            stroke="#3D3D3D"
            strokeWidth="1.2"
            strokeMiterlimit={10}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2100_1381">
          <rect width={18} height={18} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  export const CaseDetailIcon = () => (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="case-icon"
    >
      <path
        d="M12.2905 16.3125H2.17969C1.59718 16.3125 1.125 15.8403 1.125 15.2578V1.58203C1.125 0.999527 1.59718 0.527344 2.17969 0.527344H10.125L13.5 3.9375V6.10298"
        stroke="#3D3D3D"
        strokeWidth="1.2"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4035 3.937H10.125V0.621094"
        stroke="#3D3D3D"
        strokeWidth="1.2"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.625 12.7989V16.3475C14.625 16.9688 14.1213 17.4725 13.5 17.4725C12.8787 17.4725 12.375 16.9688 12.375 16.3475V12.793"
        stroke="#3D3D3D"
        strokeWidth="1.2"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 12.9375C15.364 12.9375 16.875 11.4265 16.875 9.5625C16.875 7.69854 15.364 6.1875 13.5 6.1875C11.636 6.1875 10.125 7.69854 10.125 9.5625C10.125 11.4265 11.636 12.9375 13.5 12.9375Z"
        stroke="#3D3D3D"
        strokeWidth="1.2"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.875 2.8125H3.375V6.1875H7.875V2.8125Z"
        stroke="#3D3D3D"
        strokeWidth="1.2"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.375 8.4375H10.125"
        stroke="#3D3D3D"
        strokeWidth="1.2"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.375 10.6875H10.125"
        stroke="#3D3D3D"
        strokeWidth="1.2"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.375 12.9375H7.03125"
        stroke="#3D3D3D"
        strokeWidth="1.2"
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  export const UserManagementIcon = () => (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2100_1350)">
        <path
          d="M15.364 11.636C14.3837 10.6558 13.217 9.93013 11.9439 9.49085C13.3074 8.55179 14.2031 6.9802 14.2031 5.20312C14.2031 2.33413 11.869 0 9 0C6.131 0 3.79688 2.33413 3.79688 5.20312C3.79688 6.9802 4.69262 8.55179 6.05609 9.49085C4.78308 9.93013 3.61631 10.6558 2.63605 11.636C0.936176 13.3359 0 15.596 0 18H1.40625C1.40625 13.8128 4.81279 10.4062 9 10.4062C13.1872 10.4062 16.5938 13.8128 16.5938 18H18C18 15.596 17.0638 13.3359 15.364 11.636ZM9 9C6.90641 9 5.20312 7.29675 5.20312 5.20312C5.20312 3.1095 6.90641 1.40625 9 1.40625C11.0936 1.40625 12.7969 3.1095 12.7969 5.20312C12.7969 7.29675 11.0936 9 9 9Z"
          fill="#3D3D3D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2100_1350">
          <rect width={18} height={18} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  export const ClientBranchManagementIcon = () => (
    <svg
      width={23}
      height={21}
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.40008 10.3575H15.1501L21.9001 3.6975H15.7463V0.885C15.7469 0.781975 15.7093 0.682387 15.6407 0.605499C15.5722 0.528611 15.4775 0.479886 15.3751 0.46875H7.71383C7.60343 0.46875 7.49756 0.512605 7.41949 0.590667C7.34143 0.668729 7.29758 0.774604 7.29758 0.885V3.6975H0.986328L8.40008 10.3575ZM8.14133 1.3575H14.8913V3.6975H8.14133V1.3575Z"
        fill="#3D3D3D"
      />
      <path
        d="M15.6114 11.0782C15.5735 11.1175 15.5281 11.1487 15.4778 11.1699C15.4275 11.1912 15.3735 11.2021 15.3189 11.202H8.24266C8.13852 11.199 8.03883 11.1592 7.96141 11.0895L0.378906 4.33945V18.1657C0.378906 18.9176 0.677593 19.6387 1.20926 20.1704C1.74092 20.702 2.46202 21.0007 3.21391 21.0007H19.6952C20.447 21.0007 21.1681 20.702 21.6998 20.1704C22.2315 19.6387 22.5302 18.9176 22.5302 18.1657V4.2832L15.6114 11.0782ZM14.9814 16.5007H8.81641C8.71634 16.4843 8.62536 16.4329 8.55972 16.3556C8.49407 16.2783 8.45803 16.1802 8.45803 16.0788C8.45803 15.9774 8.49407 15.8793 8.55972 15.8021C8.62536 15.7248 8.71634 15.6733 8.81641 15.657H14.9814C15.0426 15.6469 15.1052 15.6503 15.1649 15.6669C15.2247 15.6835 15.2801 15.7129 15.3273 15.753C15.3745 15.7931 15.4125 15.8431 15.4385 15.8993C15.4645 15.9556 15.478 16.0168 15.478 16.0788C15.478 16.1408 15.4645 16.2021 15.4385 16.2583C15.4125 16.3146 15.3745 16.3645 15.3273 16.4046C15.2801 16.4448 15.2247 16.4741 15.1649 16.4907C15.1052 16.5073 15.0426 16.5107 14.9814 16.5007Z"
        fill="#3D3D3D"
      />
    </svg>
  );
  export const ClientManagementIcon = () => (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0533 9.54954L11.4655 11.3588H9.5634C9.04684 11.3588 8.58973 11.6903 8.43066 12.1817C8.27075 12.6723 8.44573 13.2098 8.86349 13.5129L10.4023 14.6314L9.81457 16.4406C9.65466 16.9312 9.82964 17.4687 10.2466 17.7718C10.6643 18.0757 11.2295 18.0757 11.6472 17.7718L13.186 16.6541L14.7248 17.7718C15.1426 18.0757 15.7077 18.0757 16.1255 17.7718C16.5424 17.4687 16.7174 16.9312 16.5575 16.4406L15.9697 14.6314L17.5085 13.5129C17.9263 13.2098 18.1013 12.6723 17.9414 12.1817C17.7823 11.6903 17.3252 11.3588 16.8086 11.3588H14.9065L14.3188 9.54954C14.1597 9.05894 13.7017 8.72656 13.186 8.72656C12.6703 8.72656 12.2123 9.05894 12.0533 9.54954ZM13.186 10.1272L13.8533 12.1801C13.937 12.4388 14.1781 12.6146 14.4502 12.6146H16.6094L14.8629 13.8838C14.6428 14.0437 14.5507 14.3267 14.6344 14.5854L15.3016 16.639L13.5552 15.3698C13.335 15.2099 13.037 15.2099 12.8168 15.3698L11.0704 16.639L11.7376 14.5854C11.8214 14.3267 11.7293 14.0437 11.5091 13.8838L9.76266 12.6146H11.9218C12.1939 12.6146 12.435 12.4388 12.5188 12.1801L13.186 10.1272Z"
        fill="#3D3D3D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.58141 15.9166H1.42326C1.37889 15.9166 1.33619 15.8982 1.30521 15.8672C1.2734 15.8354 1.25582 15.7935 1.25582 15.7491C1.25582 15.7483 1.25582 14.5352 1.25582 14.5352C1.25582 13.8395 1.75228 13.2266 2.51749 12.7059C3.88382 11.7732 6.09489 11.1855 8.58141 11.1855C8.92802 11.1855 9.20932 10.9042 9.20932 10.5576C9.20932 10.2118 8.92802 9.92969 8.58141 9.92969C5.8002 9.92969 3.33796 10.6254 1.80921 11.6677C0.642978 12.4631 0 13.4736 0 14.5343V15.7491C0 16.1259 0.149861 16.4884 0.416931 16.7555C0.684001 17.0217 1.04568 17.1724 1.42326 17.1724H8.58141C8.92802 17.1716 9.20932 16.8903 9.20932 16.5437C9.20932 16.1979 8.92802 15.9157 8.58141 15.9166Z"
        fill="#3D3D3D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.58122 0C6.03945 0 3.97656 2.06289 3.97656 4.60466C3.97656 7.14643 6.03945 9.20932 8.58122 9.20932C11.123 9.20932 13.1859 7.14643 13.1859 4.60466C13.1859 2.06289 11.123 0 8.58122 0ZM8.58122 1.25582C10.4298 1.25582 11.9301 2.7561 11.9301 4.60466C11.9301 6.45322 10.4298 7.9535 8.58122 7.9535C6.73266 7.9535 5.23238 6.45322 5.23238 4.60466C5.23238 2.7561 6.73266 1.25582 8.58122 1.25582Z"
        fill="#3D3D3D"
      />
    </svg>
  );
  export const HolidayManagementIcon = () => (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2100_1409)">
        <path
          d="M2.71968 18H15.2797C16.5963 18 17.6664 16.93 17.6664 15.6133V3.71998C17.6664 2.40332 16.5963 1.33331 15.2797 1.33331H14.333V0.666654C14.333 0.299983 14.033 0 13.6663 0C13.2996 0 12.9997 0.299983 12.9997 0.666654V1.33331H4.9997V0.666654C4.9997 0.299983 4.69966 0 4.33299 0C3.96632 0 3.66634 0.299983 3.66634 0.666654V1.33331H2.71968C1.40302 1.33331 0.333008 2.40332 0.333008 3.71998V15.6133C0.333008 16.93 1.40302 18 2.71968 18ZM1.66637 3.71998C1.66637 3.14 2.1397 2.66667 2.71968 2.66667H3.66634V3.33333C3.66634 3.7 3.96632 3.99998 4.33299 3.99998C4.69966 3.99998 4.99964 3.7 4.99964 3.33333V2.66667H12.9997V3.33333C12.9997 3.7 13.2996 3.99998 13.6663 3.99998C14.033 3.99998 14.333 3.7 14.333 3.33333V2.66667H15.2796C15.8596 2.66667 16.3329 3.14 16.3329 3.71998V5.66667H1.66637V3.71998ZM1.66637 6.99998H16.333V15.6133C16.333 16.1933 15.8597 16.6666 15.2797 16.6666H2.71968C2.1397 16.6666 1.66637 16.1933 1.66637 15.6133V6.99998Z"
          fill="#3D3D3D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2100_1409">
          <rect width={18} height={18} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  export const DocumentIcon = () => (
    <svg
      width={23}
      height={21}
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.40008 10.3575H15.1501L21.9001 3.6975H15.7463V0.885C15.7469 0.781975 15.7093 0.682387 15.6407 0.605499C15.5722 0.528611 15.4775 0.479886 15.3751 0.46875H7.71383C7.60343 0.46875 7.49756 0.512605 7.41949 0.590667C7.34143 0.668729 7.29758 0.774604 7.29758 0.885V3.6975H0.986328L8.40008 10.3575ZM8.14133 1.3575H14.8913V3.6975H8.14133V1.3575Z"
        fill="#3D3D3D"
      />
      <path
        d="M15.6114 11.0782C15.5735 11.1175 15.5281 11.1487 15.4778 11.1699C15.4275 11.1912 15.3735 11.2021 15.3189 11.202H8.24266C8.13852 11.199 8.03883 11.1592 7.96141 11.0895L0.378906 4.33945V18.1657C0.378906 18.9176 0.677593 19.6387 1.20926 20.1704C1.74092 20.702 2.46202 21.0007 3.21391 21.0007H19.6952C20.447 21.0007 21.1681 20.702 21.6998 20.1704C22.2315 19.6387 22.5302 18.9176 22.5302 18.1657V4.2832L15.6114 11.0782ZM14.9814 16.5007H8.81641C8.71634 16.4843 8.62536 16.4329 8.55972 16.3556C8.49407 16.2783 8.45803 16.1802 8.45803 16.0788C8.45803 15.9774 8.49407 15.8793 8.55972 15.8021C8.62536 15.7248 8.71634 15.6733 8.81641 15.657H14.9814C15.0426 15.6469 15.1052 15.6503 15.1649 15.6669C15.2247 15.6835 15.2801 15.7129 15.3273 15.753C15.3745 15.7931 15.4125 15.8431 15.4385 15.8993C15.4645 15.9556 15.478 16.0168 15.478 16.0788C15.478 16.1408 15.4645 16.2021 15.4385 16.2583C15.4125 16.3146 15.3745 16.3645 15.3273 16.4046C15.2801 16.4448 15.2247 16.4741 15.1649 16.4907C15.1052 16.5073 15.0426 16.5107 14.9814 16.5007Z"
        fill="#3D3D3D"
      />
    </svg>
  );
  export const PickedIcon = () => (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2100_1330)">
        <path
          d="M16.2081 4.0804H12.3295V2.51752C12.3295 1.77335 11.7241 1.16797 10.98 1.16797H7.02001C6.27585 1.16797 5.67043 1.77335 5.67043 2.51752V4.0804H1.79189C0.80382 4.0804 0 4.88425 0 5.87229V15.0373C0 16.0253 0.80382 16.8291 1.79189 16.8291H16.2081C17.1961 16.8291 18 16.0253 18 15.0373V5.87229C18 4.88425 17.1961 4.0804 16.2081 4.0804ZM6.81354 2.51752C6.81354 2.40367 6.90616 2.31108 7.02001 2.31108H10.98C11.0938 2.31108 11.1864 2.40371 11.1864 2.51752V4.0804H6.81354V2.51752ZM1.14311 5.87229C1.14311 5.51453 1.43414 5.22351 1.79189 5.22351H16.2081C16.5658 5.22351 16.8569 5.51453 16.8569 5.87229V6.71823C16.8569 7.51169 16.3248 8.22074 15.563 8.44254L9.58511 10.183C9.20235 10.2945 8.79769 10.2945 8.41485 10.183L2.43697 8.4425C1.67515 8.2207 1.14311 7.51169 1.14311 6.71823V5.87229ZM16.2081 15.6861H1.79189C1.43417 15.6861 1.14311 15.395 1.14311 15.0373V9.04549C1.43114 9.26811 1.75946 9.43583 2.11739 9.54006C8.72096 11.4627 8.38156 11.4098 8.99998 11.4098C9.62658 11.4098 9.27129 11.465 15.8826 9.54006C16.2405 9.43583 16.5689 9.26811 16.8569 9.04549V15.0373C16.8569 15.395 16.5658 15.6861 16.2081 15.6861Z"
          fill="#3D3D3D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2100_1330">
          <rect width={18} height={18} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  export const ZoneIcon = () => (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2100_1439)">
        <path
          d="M9 18C7.21997 18 5.47991 17.4722 3.99987 16.4832C2.51983 15.4943 1.36628 14.0887 0.685088 12.4442C0.00389965 10.7996 -0.17433 8.99002 0.172937 7.24419C0.520203 5.49836 1.37737 3.89472 2.63604 2.63604C3.89472 1.37737 5.49836 0.520203 7.24419 0.172937C8.99002 -0.17433 10.7996 0.00389965 12.4442 0.685088C14.0887 1.36628 15.4943 2.51983 16.4832 3.99987C17.4722 5.47991 18 7.21997 18 9C18 9.15913 17.9368 9.31175 17.8243 9.42427C17.7117 9.53679 17.5591 9.6 17.4 9.6C17.2409 9.6 17.0883 9.53679 16.9757 9.42427C16.8632 9.31175 16.8 9.15913 16.8 9C16.8 7.45731 16.3425 5.94926 15.4855 4.66656C14.6284 3.38385 13.4102 2.38411 11.9849 1.79374C10.5597 1.20338 8.99135 1.04891 7.4783 1.34988C5.96525 1.65084 4.57542 2.39372 3.48457 3.48457C2.39372 4.57542 1.65084 5.96525 1.34988 7.4783C1.04891 8.99135 1.20338 10.5597 1.79374 11.9849C2.38411 13.4102 3.38385 14.6284 4.66656 15.4855C5.94926 16.3425 7.45731 16.8 9 16.8C9.15913 16.8 9.31175 16.8632 9.42427 16.9757C9.53679 17.0883 9.6 17.2409 9.6 17.4C9.6 17.5591 9.53679 17.7117 9.42427 17.8243C9.31175 17.9368 9.15913 18 9 18Z"
          fill="#3D3D3D"
        />
        <path
          d="M15.9 5.39922H2.1C1.94087 5.39922 1.78826 5.336 1.67574 5.22348C1.56321 5.11096 1.5 4.95835 1.5 4.79922C1.5 4.64009 1.56321 4.48748 1.67574 4.37495C1.78826 4.26243 1.94087 4.19922 2.1 4.19922H15.9C16.0591 4.19922 16.2117 4.26243 16.3243 4.37495C16.4368 4.48748 16.5 4.64009 16.5 4.79922C16.5 4.95835 16.4368 5.11096 16.3243 5.22348C16.2117 5.336 16.0591 5.39922 15.9 5.39922Z"
          fill="#3D3D3D"
        />
        <path
          d="M9 9.59844H0.6C0.44087 9.59844 0.288258 9.53522 0.175736 9.4227C0.0632141 9.31018 0 9.15757 0 8.99844C0 8.83931 0.0632141 8.6867 0.175736 8.57417C0.288258 8.46165 0.44087 8.39844 0.6 8.39844H9C9.15913 8.39844 9.31174 8.46165 9.42426 8.57417C9.53679 8.6867 9.6 8.83931 9.6 8.99844C9.6 9.15757 9.53679 9.31018 9.42426 9.4227C9.31174 9.53522 9.15913 9.59844 9 9.59844Z"
          fill="#3D3D3D"
        />
        <path
          d="M7.2 13.7977H2.1C1.94087 13.7977 1.78826 13.7344 1.67574 13.6219C1.56321 13.5094 1.5 13.3568 1.5 13.1977C1.5 13.0385 1.56321 12.8859 1.67574 12.7734C1.78826 12.6609 1.94087 12.5977 2.1 12.5977H7.2C7.35913 12.5977 7.51174 12.6609 7.62426 12.7734C7.73679 12.8859 7.8 13.0385 7.8 13.1977C7.8 13.3568 7.73679 13.5094 7.62426 13.6219C7.51174 13.7344 7.35913 13.7977 7.2 13.7977Z"
          fill="#3D3D3D"
        />
        <path
          d="M9 18C6.4767 18 4.5 14.0466 4.5 9C4.5 3.9534 6.4767 0 9 0C11.1687 0 12.9813 2.9358 13.4073 7.14C13.4152 7.2184 13.4075 7.29758 13.3848 7.37303C13.3621 7.44848 13.3247 7.51871 13.2749 7.57972C13.1742 7.70293 13.0286 7.78109 12.8703 7.797C12.7919 7.80488 12.7127 7.79724 12.6373 7.77452C12.5618 7.75179 12.4916 7.71443 12.4306 7.66457C12.3074 7.56386 12.2292 7.41833 12.2133 7.26C11.8632 3.8055 10.4814 1.2 9 1.2C7.44 1.2 5.7 4.4034 5.7 9C5.7 13.5966 7.44 16.8 9 16.8C9.15913 16.8 9.31174 16.8632 9.42426 16.9757C9.53679 17.0883 9.6 17.2409 9.6 17.4C9.6 17.5591 9.53679 17.7117 9.42426 17.8243C9.31174 17.9368 9.15913 18 9 18Z"
          fill="#3D3D3D"
        />
        <path
          d="M13.2004 17.9984C12.251 17.9984 11.323 17.7169 10.5337 17.1895C9.7443 16.6621 9.12907 15.9124 8.76577 15.0353C8.40247 14.1582 8.30741 13.1931 8.49262 12.262C8.67783 11.3309 9.13499 10.4756 9.80628 9.80433C10.4776 9.13303 11.3329 8.67588 12.264 8.49067C13.1951 8.30546 14.1602 8.40052 15.0373 8.76382C15.9144 9.12712 16.664 9.74235 17.1914 10.5317C17.7189 11.3211 18.0004 12.2491 18.0004 13.1984C17.9989 14.471 17.4927 15.691 16.5928 16.5909C15.693 17.4907 14.473 17.9969 13.2004 17.9984ZM13.2004 9.59844C12.4884 9.59844 11.7924 9.80958 11.2003 10.2051C10.6083 10.6007 10.1469 11.163 9.87443 11.8208C9.60195 12.4786 9.53066 13.2024 9.66957 13.9008C9.80847 14.5991 10.1513 15.2406 10.6548 15.744C11.1583 16.2475 11.7997 16.5904 12.4981 16.7293C13.1964 16.8682 13.9202 16.7969 14.5781 16.5244C15.2359 16.2519 15.7981 15.7905 16.1937 15.1985C16.5893 14.6065 16.8004 13.9105 16.8004 13.1984C16.7994 12.244 16.4197 11.3289 15.7448 10.654C15.0699 9.97909 14.1549 9.59947 13.2004 9.59844Z"
          fill="#3D3D3D"
        />
        <path
          d="M12.3001 14.6992C12.1814 14.6992 12.0654 14.664 11.9668 14.5981C11.8681 14.5321 11.7913 14.4384 11.7459 14.3288C11.7005 14.2192 11.6886 14.0986 11.7117 13.9822C11.7349 13.8658 11.792 13.7589 11.8759 13.675L12.6001 12.9508V10.7992C12.6001 10.6401 12.6633 10.4875 12.7758 10.375C12.8883 10.2624 13.0409 10.1992 13.2001 10.1992C13.3592 10.1992 13.5118 10.2624 13.6243 10.375C13.7369 10.4875 13.8001 10.6401 13.8001 10.7992V13.1992C13.8 13.3583 13.7368 13.5109 13.6243 13.6234L12.7243 14.5234C12.6686 14.5792 12.6025 14.6235 12.5297 14.6537C12.4569 14.6839 12.3789 14.6993 12.3001 14.6992Z"
          fill="#3D3D3D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2100_1439">
          <rect width={18} height={18} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  export const PaymentManagementIcon = () => (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2100_1416)">
        <path
          d="M17.4713 0.52875C17.1336 0.192035 16.6768 0.00204254 16.2 0H5.4C4.92317 0.00204254 4.46638 0.192035 4.12875 0.52875C3.96101 0.695214 3.82792 0.893266 3.73716 1.11146C3.64641 1.32966 3.59979 1.56368 3.6 1.8V9.60188H1.8C1.32358 9.60187 0.866597 9.79074 0.529192 10.1271C0.191788 10.4634 0.0014888 10.9198 0 11.3962L0 15.5981C0 16.2351 0.253054 16.8461 0.703493 17.2965C1.15393 17.7469 1.76486 18 2.40188 18H15.0019C15.7966 17.9985 16.5583 17.6822 17.1202 17.1202C17.6822 16.5583 17.9985 15.7966 18 15.0019V1.8C18.0002 1.56368 17.9536 1.32966 17.8628 1.11146C17.7721 0.893266 17.639 0.695214 17.4713 0.52875ZM1.19813 15.5981V11.3962C1.19812 11.3175 1.21373 11.2395 1.24404 11.1668C1.27436 11.0941 1.31878 11.0281 1.37475 10.9726C1.43071 10.9172 1.49711 10.8734 1.5701 10.8438C1.64309 10.8141 1.72123 10.7993 1.8 10.8H3.6V15.5981C3.6 15.7558 3.56894 15.912 3.50858 16.0577C3.44823 16.2034 3.35977 16.3358 3.24825 16.4473C3.13674 16.5588 3.00435 16.6473 2.85864 16.7076C2.71294 16.768 2.55677 16.7991 2.39906 16.7991C2.24135 16.7991 2.08519 16.768 1.93948 16.7076C1.79378 16.6473 1.66139 16.5588 1.54987 16.4473C1.43835 16.3358 1.34989 16.2034 1.28954 16.0577C1.22919 15.912 1.19813 15.7558 1.19813 15.5981ZM16.7962 14.9963C16.7963 15.4736 16.6066 15.9315 16.269 16.269C15.9315 16.6066 15.4736 16.7962 14.9963 16.7962H4.46625C4.68135 16.4335 4.79591 16.0199 4.79812 15.5981V1.8C4.79812 1.64037 4.86154 1.48728 4.97441 1.37441C5.08728 1.26154 5.24037 1.19813 5.4 1.19813H16.2C16.3596 1.19813 16.5127 1.26154 16.6256 1.37441C16.7385 1.48728 16.8019 1.64037 16.8019 1.8L16.7962 14.9963Z"
          fill="#3D3D3D"
        />
        <path
          d="M13.7978 11.4013H7.80159C7.71805 11.3931 7.63373 11.4026 7.55405 11.429C7.47438 11.4554 7.40111 11.4982 7.33896 11.5546C7.2768 11.6111 7.22714 11.6799 7.19315 11.7566C7.15916 11.8334 7.1416 11.9164 7.1416 12.0003C7.1416 12.0843 7.15916 12.1673 7.19315 12.244C7.22714 12.3208 7.2768 12.3896 7.33896 12.446C7.40111 12.5024 7.47438 12.5452 7.55405 12.5716C7.63373 12.598 7.71805 12.6075 7.80159 12.5994H13.7978C13.8814 12.6075 13.9657 12.598 14.0454 12.5716C14.1251 12.5452 14.1983 12.5024 14.2605 12.446C14.3226 12.3896 14.3723 12.3208 14.4063 12.244C14.4403 12.1673 14.4578 12.0843 14.4578 12.0003C14.4578 11.9164 14.4403 11.8334 14.4063 11.7566C14.3723 11.6799 14.3226 11.6111 14.2605 11.5546C14.1983 11.4982 14.1251 11.4554 14.0454 11.429C13.9657 11.4026 13.8814 11.3931 13.7978 11.4013Z"
          fill="#3D3D3D"
        />
        <path
          d="M13.7978 14.4013H7.80159C7.71805 14.3931 7.63373 14.4026 7.55405 14.429C7.47438 14.4554 7.40111 14.4982 7.33896 14.5546C7.2768 14.6111 7.22714 14.6799 7.19315 14.7566C7.15916 14.8334 7.1416 14.9164 7.1416 15.0003C7.1416 15.0843 7.15916 15.1673 7.19315 15.244C7.22714 15.3208 7.2768 15.3896 7.33896 15.446C7.40111 15.5024 7.47438 15.5452 7.55405 15.5716C7.63373 15.598 7.71805 15.6075 7.80159 15.5994H13.7978C13.8814 15.6075 13.9657 15.598 14.0454 15.5716C14.1251 15.5452 14.1983 15.5024 14.2605 15.446C14.3226 15.3896 14.3723 15.3208 14.4063 15.244C14.4403 15.1673 14.4578 15.0843 14.4578 15.0003C14.4578 14.9164 14.4403 14.8334 14.4063 14.7566C14.3723 14.6799 14.3226 14.6111 14.2605 14.5546C14.1983 14.4982 14.1251 14.4554 14.0454 14.429C13.9657 14.4026 13.8814 14.3931 13.7978 14.4013Z"
          fill="#3D3D3D"
        />
        <path
          d="M8.512 7.016V6.24H9.928C10.2533 6.24 10.512 6.152 10.704 5.976C10.9013 5.79467 11 5.54133 11 5.216C11 4.89067 10.904 4.64267 10.712 4.472C10.52 4.296 10.2613 4.208 9.936 4.208H9.312V3.832H9.936C10.2133 3.832 10.4747 3.856 10.72 3.904C10.9653 3.94667 11.1813 4.02133 11.368 4.128C11.56 4.23467 11.7093 4.38133 11.816 4.568C11.9227 4.75467 11.976 4.98933 11.976 5.272C11.976 5.64533 11.8853 5.96267 11.704 6.224C11.528 6.48 11.2907 6.67733 10.992 6.816C10.6933 6.94933 10.3573 7.016 9.984 7.016H8.512ZM8.512 7.016L9.592 6.872L12.016 9H10.72L8.512 7.016ZM8.512 5.616V4.888H12.696V5.616H8.512ZM8.512 4.208V3.48H12.696V4.208H8.512Z"
          fill="#3D3D3D"
        />
      </g>
      <defs>
        <clipPath id="clip0_2100_1416">
          <rect width={18} height={18} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  export const SettingIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      version="1.1"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
    >
      <g fill="#333">
        <path d="M30.391 12.68l-3.064-.614a12.098 12.098 0 00-.537-1.289l1.736-2.604a1.999 1.999 0 00-.25-2.523l-1.924-1.924a1.996 1.996 0 00-2.523-.25l-2.604 1.735a12.09 12.09 0 00-1.291-.536L19.32 1.61A1.998 1.998 0 0017.359.002h-2.72a2 2 0 00-1.961 1.608l-.614 3.065c-.443.154-.873.335-1.289.536L8.172 3.476a1.996 1.996 0 00-2.524.25L3.725 5.65a2 2 0 00-.25 2.523l1.735 2.604c-.202.417-.382.847-.536 1.29l-3.066.613A2 2 0 000 14.641v2.72a2 2 0 001.608 1.961l3.065.615c.154.443.335.873.536 1.289L3.475 23.83a1.999 1.999 0 00.25 2.523l1.924 1.924a1.996 1.996 0 002.523.25l2.604-1.736c.417.203.847.383 1.29.537l.613 3.064a2 2 0 001.961 1.609h2.72a2 2 0 001.961-1.609l.615-3.064c.443-.154.873-.336 1.289-.537l2.604 1.736a1.998 1.998 0 002.523-.25l1.924-1.924a2 2 0 00.25-2.523l-1.736-2.604c.203-.418.383-.848.537-1.291l3.064-.613A2 2 0 0032 17.361v-2.72c0-.953-.674-1.774-1.609-1.961zm-3.457 5.295a1.997 1.997 0 00-1.496 1.305c-.129.369-.279.727-.447 1.074a1.996 1.996 0 00.135 1.982l1.736 2.604-1.924 1.924-2.604-1.736a1.997 1.997 0 00-1.98-.137c-.348.168-.705.32-1.076.449a1.998 1.998 0 00-1.303 1.496l-.615 3.066h-2.72l-.613-3.066a1.997 1.997 0 00-1.304-1.496 10.17 10.17 0 01-1.075-.447 1.994 1.994 0 00-1.981.135l-2.604 1.736-1.924-1.924 1.735-2.604c.393-.59.444-1.344.137-1.98a10.106 10.106 0 01-.448-1.076 2 2 0 00-1.496-1.303l-3.065-.615L2 14.641l3.066-.613a1.999 1.999 0 001.496-1.304c.129-.369.278-.728.447-1.075a1.995 1.995 0 00-.136-1.981L5.139 7.064 7.062 5.14l2.604 1.735a1.997 1.997 0 001.98.137 10.09 10.09 0 011.075-.448 1.999 1.999 0 001.304-1.496l.614-3.065 2.72-.001.613 3.066a1.998 1.998 0 001.305 1.496c.369.129.727.278 1.074.447a1.998 1.998 0 001.982-.136l2.604-1.735 1.924 1.924-1.736 2.604a2.006 2.006 0 00-.137 1.98c.168.347.32.705.449 1.075a1.997 1.997 0 001.496 1.304l3.064.614.003 2.72-3.066.614z"></path>
        <path d="M16 9.001A7 7 0 1016.001 23 7 7 0 0016 9zm0 13.126a6.126 6.126 0 116.125-6.125A6.127 6.127 0 0116 22.127z"></path>
        <path d="M16 12.001a4 4 0 100 8 4 4 0 000-8zm0 7.001a3.001 3.001 0 010-6 3.001 3.001 0 010 6z"></path>
      </g>
    </svg>
  );
  export const BranchManagementIcon = () => {
    return (
      <svg
        width={18}
        height={18}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2100_1405)">
          <path
            d="M16.7143 7.71401C17.4234 7.71401 18 7.13735 18 6.42826V5.52631C18 5.05251 17.7409 4.61857 17.3231 4.39421L9.30472 0.0766628C9.11507 -0.0255543 8.88557 -0.0255543 8.69593 0.0766628L0.676929 4.39421C0.259714 4.61857 0 5.05251 0 5.52631V6.42826C0 7.13735 0.576643 7.71401 1.28571 7.71401H1.92857V14.1428H1.28571C0.576643 14.1428 0 14.7194 0 15.4285V16.7143C0 17.4233 0.576643 18 1.28571 18H16.7143C17.4234 18 18 17.4233 18 16.7143V15.4285C18 14.7194 17.4234 14.1428 16.7143 14.1428H16.0714V7.71401H16.7143ZM1.28571 5.52631L9 1.3727L16.7143 5.52631V6.42826H1.28571V5.52631ZM9.64286 14.1428V7.71401H11.5714V14.1428H9.64286ZM6.42857 14.1428V7.71401H8.35714V14.1428H6.42857ZM3.21429 7.71401H5.14286V14.1428H3.21429V7.71401ZM16.7149 16.7143H1.28571V15.4285H16.7143L16.7149 16.7143ZM14.7864 14.1428H12.8578V7.71401H14.7864V14.1428Z"
            fill="#3D3D3D"
          />
        </g>
        <defs>
          <clipPath id="clip0_2100_1405">
            <rect width={18} height={18} fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  };
  export const ProfileIcon = () => {
    return (
      <i className="fa-regular fa-user" />
    );
  };
  export const ChangePasswordIcon = () => {
    return (
      <i className="fa-solid fa-key" />
    );
  };
  export const LogoutIcon = () => {
    return (
      <i className="fa-solid fa-power-off" />
    );
  };
  export const branchIcon = () => {
   return(
    <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2100_1405)">
      <path
        d="M16.7143 7.71401C17.4234 7.71401 18 7.13735 18 6.42826V5.52631C18 5.05251 17.7409 4.61857 17.3231 4.39421L9.30472 0.0766628C9.11507 -0.0255543 8.88557 -0.0255543 8.69593 0.0766628L0.676929 4.39421C0.259714 4.61857 0 5.05251 0 5.52631V6.42826C0 7.13735 0.576643 7.71401 1.28571 7.71401H1.92857V14.1428H1.28571C0.576643 14.1428 0 14.7194 0 15.4285V16.7143C0 17.4233 0.576643 18 1.28571 18H16.7143C17.4234 18 18 17.4233 18 16.7143V15.4285C18 14.7194 17.4234 14.1428 16.7143 14.1428H16.0714V7.71401H16.7143ZM1.28571 5.52631L9 1.3727L16.7143 5.52631V6.42826H1.28571V5.52631ZM9.64286 14.1428V7.71401H11.5714V14.1428H9.64286ZM6.42857 14.1428V7.71401H8.35714V14.1428H6.42857ZM3.21429 7.71401H5.14286V14.1428H3.21429V7.71401ZM16.7149 16.7143H1.28571V15.4285H16.7143L16.7149 16.7143ZM14.7864 14.1428H12.8578V7.71401H14.7864V14.1428Z"
        fill="#3D3D3D"
      />
    </g>
    <defs>
      <clipPath id="clip0_2100_1405">
        <rect width={18} height={18} fill="white" />
      </clipPath>
    </defs>
  </svg>
   )
  };
  export const TargetIcon = () => {
    return(
      <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id="target-icon"
    >
      <g clipPath="url(#clip0_2427_2223)">
        <mask
          id="mask0_2427_2223"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={18}
          height={18}
        >
          <path
            d="M17.4 17.4V0.600002H0.6V17.4H17.4Z"
            fill="white"
            stroke="white"
            strokeWidth="1.2"
          />
        </mask>
        <g mask="url(#mask0_2427_2223)">
          <path
            d="M16.0851 4.68046C16.8539 5.9387 17.2969 7.41758 17.2969 9C17.2969 13.5822 13.5822 17.2969 9 17.2969C4.41773 17.2969 0.703125 13.5822 0.703125 9C0.703125 4.41777 4.41773 0.703127 9 0.703127C10.5824 0.703127 12.0613 1.14613 13.3195 1.91486"
            stroke="#3D3D3D"
            strokeWidth="1.2"
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.8414 8.99823C13.8414 11.6722 11.6738 13.8398 8.99982 13.8398C6.32587 13.8398 4.1582 11.6722 4.1582 8.99823C4.1582 6.32428 6.32587 4.15662 8.99982 4.15662"
            stroke="#3D3D3D"
            strokeWidth="1.2"
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.7656 6.23436L9 9"
            stroke="#3D3D3D"
            strokeWidth="1.2"
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.5312 0.703138L11.7656 3.46877V6.23438H14.5312L17.2969 3.46877H14.5312V0.703138Z"
            stroke="#3D3D3D"
            strokeWidth="1.2"
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2427_2223">
          <rect width={18} height={18} fill="white" />
        </clipPath>
      </defs>
    </svg>
    
    )
  };
  export const PincodeIcon = () => {
    return(
      <svg
  width={18}
  height={18}
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  className="pincode-icon"
>
  <mask
    id="mask0_2100_1424"
    style={{ maskType: "luminance" }}
    maskUnits="userSpaceOnUse"
    x={0}
    y={0}
    width={18}
    height={18}
  >
    <path
      d="M17.4 17.4V0.600002H0.6V17.4H17.4Z"
      fill="white"
      stroke="white"
      strokeWidth="1.2"
    />
  </mask>
  <g mask="url(#mask0_2100_1424)">
    <path
      d="M10.0228 12.2691C12.5315 12.4043 14.4278 13.0272 14.4278 13.7753C14.4278 14.6221 11.9977 15.3086 9.00004 15.3086C6.00234 15.3086 3.57227 14.6221 3.57227 13.7753C3.57227 13.0272 5.46852 12.4043 7.9772 12.2691"
      stroke="#3D3D3D"
      strokeWidth="1.2"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.78924 13.0823C0.989437 13.5217 0.527344 14.0395 0.527344 14.5941C0.527344 16.1839 4.3207 17.4727 9 17.4727C13.6793 17.4727 17.4727 16.1839 17.4727 14.5941C17.4727 14.0395 17.0106 13.5217 16.2108 13.0823"
      stroke="#3D3D3D"
      strokeWidth="1.2"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.99988 6.00976C8.20292 6.00976 7.55686 5.36369 7.55686 4.56677C7.55686 3.76981 8.20292 3.12374 8.99988 3.12374C9.79684 3.12374 10.4429 3.76981 10.4429 4.56677C10.4429 5.36369 9.79684 6.00976 8.99988 6.00976ZM8.99988 0.528511C6.5837 0.528511 4.625 2.48717 4.625 4.90328C4.625 6.58097 7.07623 10.8024 8.30906 12.8195C8.6249 13.3362 9.37486 13.3362 9.6907 12.8195C10.9235 10.8024 13.3748 6.58097 13.3748 4.90328C13.3748 2.48717 11.4161 0.528511 8.99988 0.528511Z"
      stroke="#3D3D3D"
      strokeWidth="1.2"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
</svg>

    
    )
  };