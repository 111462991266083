import React from "react";

const ExcelFilePreview = ({ fileData }) => {
  return (
    <div>
      {fileData && (
        <div className="preview-h">
          <table style={styles.table}>
            <thead>
              <tr>
                {Object.keys(fileData[0]).map((key) => (
                  <th style={styles.th} key={key}>
                    {key.toLowerCase()}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {fileData.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, i) => (
                    <td style={styles.td} key={i}>
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const styles = {
  table: {
    margin: "10px",
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    border: "4px solid #dddddd",
    padding: "8px",
    maxWidth: "150px",
    wordWrap: "break-word",
    overflow: "hidden",
    whiteSpace: "normal",
    position: "sticky",
    top: "0", // Stick to the top
    backgroundColor: "#f0f0f0",
    zIndex: "0", // Ensure it stays above other elements
  },
  td: {
    border: "4px solid #dddddd",
    padding: "8px",
    maxWidth: "150px",
    wordWrap: "break-word",
    overflow: "hidden",
    whiteSpace: "normal",
  },
};

export default ExcelFilePreview;
