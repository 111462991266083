import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { Role } from "../../utils/constant/Constant";
import AntdTable from "../../components/AntdTable/Table";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePincode,
  fetchAllPincodes,
  resetSelectedPincode,
} from "../../redux/slices/pincodeSlice";
import SearchBar from "../../components/SearchBar";

function PincodeManagement() {
  const dispatch = useDispatch();
  const { pincodes,status, total } = useSelector((state) => state.pincode);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(resetSelectedPincode());
  }, []);

  useEffect(() => {
    fetchPincodeData();
  }, [dispatch, pageNo, pageSize, searchQuery]);

  const fetchPincodeData = () => {
    const body = {
      pageNo: pageNo,
      pageSize: pageSize,
      search: searchQuery,
    };
    dispatch(fetchAllPincodes(body));
  };

  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.auth);

  //Delete Document
  const handleDeletePincode = async (pincode) => {
    Swal.fire({
      title: "Delete",
      text: `Are you sure you want to delete Pincode?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = { pincode_id: parseInt(pincode.id) };
        dispatch(deletePincode(body))
          .unwrap()
          .then(() => {
            fetchPincodeData();
          })
          .catch((err) => {});
      }
    });
  };

  const onredirect = async (pincode) => {
    const data = {
      pincode_id: pincode.id,
    };
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/pincode/add/", {
        state: data,
      });
    } else {
      navigate("/login");
    }
  };

  const columns = [
    {
      title: "#",
      key: "index",
      width: "10%",
      render: (_, __, index) => (pageNo - 1) * pageSize + (index + 1),
      className: "dt-left",
    },
    {
      dataIndex: "pincode",
      title: "Pincode",
      className: "dt-left",
      width: "20%",
    },
    { dataIndex: "area", title: "Area", className: "dt-left", width: "20%",},
    { dataIndex: "district", title: "City", className: "dt-left",width: "20%", },
    { dataIndex: "state_name", title: "State Name", className: "dt-left" ,width: "20%",},
    {
      title: "Action",
      key: "action",
      width: "20%",
      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <EditOutlined
            style={{ color: "#000000", marginRight: 10, cursor: "pointer" }}
            onClick={() => onredirect(record)} // Function to handle edit
          />
          <DeleteOutlined
            style={{ color: "#D92E38", cursor: "pointer" }}
            onClick={() => handleDeletePincode(record)} // Function to handle delete
          />
        </div>
      ),
      className: "dt-center",
    },
  ];

  return (
    <>
      <div className="main-contant">
        <div className="container-fluid">
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-5">
                  <div className="title-box">
                    <h5>Pincode Management</h5>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="d-flex justify-content-end ">
                    <SearchBar
                      setSearchQuery={setSearchQuery}
                      fetchData={fetchPincodeData}
                    />
                    <div className="button-box">
                      <ul className="">
                        <li>
                          <Link
                            to={
                              [Role.SUPER_ADMIN].some(
                                (role) => userData.role == role
                              )
                                ? "/super-admin/pincode/add"
                                : ""
                            }
                            className="blue-btn"
                          >
                            + Add New
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AntdTable
              dataSource={pincodes}
              columns={columns}
              status={status}
              pageNo={pageNo}
              pageSize={pageSize}
              setMinHeight={true}
              setPageNo={setPageNo}
              setPageSize={setPageSize}
              total={total}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PincodeManagement;
