import React, { useState, useEffect, useRef } from "react";
import api from "../../services/Api";
import { useToast } from "../../utils/helpers/CommFun";

import * as XLSX from "xlsx";
import ExcelFilePreview from "../../components/ExcelFilePreview";
import { API_URL } from "../../utils/constant/Constant";
import { Form, Button, Select, Row, Col, Upload } from "antd";

import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { fetchAllClientBranches } from "../../redux/slices/clientBranchSlice";
import { fetchAllClients } from "../../redux/slices/clientSlice";

function AddMultipleCase() {
  const dispatch = useDispatch();
  const { clientBranches } = useSelector((state) => state.clientBranch);
  const { userData, token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(fetchAllClientBranches({}));
    dispatch(fetchAllClients({}));
  }, [dispatch]);

  const [excelForm] = Form.useForm();
  const [excelFormValues, setExcelFormValues] = useState();

  const [pointList, setPointList] = useState([
    { id: 21, name: "BRT" },
    { id: 22, name: "Pool Purchase" },
    { id: 23, name: "Partial Disbursement" },
  ]);
  const [excelFileData, setExcelFileData] = useState(null);
  const previewRef = useRef(null);

  //sweet alert
  const Toast = useToast();

  //handle modal open & close
  const handleClose = () => {
    window.location.reload();
  };

  const handleFileUpload = (excelFile) => {
    const file = excelFile;
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet);
      const snakeCaseData = data.map((item) => {
        let transformedItem = {};
        for (let key in item) {
          if (item.hasOwnProperty(key)) {
            // Transform key to snake_case
            const snakeCaseKey = key.trim().replace(/\s+/g, "_").toLowerCase();

            // Rename specific keys as needed
            switch (snakeCaseKey) {
              case "no._of_buildings":
                transformedItem["no_of_building"] = item[key];
                break;
              case "no._of_phases":
                transformedItem["no_of_phases"] = item[key];
                break;
              case "no._of_floors":
                transformedItem["no_of_floors"] = item[key];
                break;
              case "no._of_dwelling_entire_project":
                transformedItem["no_of_dwelling_entire_project"] = item[key];
                break;
              case "no._of_dwelling_each_building":
                transformedItem["no_of_dwelling_each_building"] = item[key];
                break;
              default:
                transformedItem[snakeCaseKey] = item[key];
            }
          }
        }
        return transformedItem;
      });

      setExcelFileData(snakeCaseData);
      // setTimeout(() => {
      //   previewRef.current.click();
      // }, 100);
      // filePreviewPage();
    };
    reader.readAsBinaryString(file);
  };

  const handleSubmit = async (event) => {
    const body = {
      verification_type: excelFormValues.verificationType,
      parent_id: userData.parent_id,
      bm_id: userData.bm_id,
      user_id: userData.id,
      branch_id: excelFormValues.branch,
      jsonData: excelFileData,
    };
    const response = await api.post(
      API_URL.CREATE_CASE_FROM_EXCEL,
      body,
      token
    );
    if (response.status === 200) {
      window.location.reload();
      Toast.fire({
        icon: "success",
        title: response.message,
      });
    } else {
      Toast.fire({
        icon: "error",
        title: response.message,
      });
    }
  };

  const handleExcelChange = (changedValues) => {
    setExcelFormValues((prev) => {
      return { ...prev, ...changedValues };
    });
  };

  return (
    <>
      <div className="main-contant">
        <div className="container-fluid">
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-5">
                  <div className="title-box">
                    <h5 className="bottom-line">Add Multiple Case</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-3">
              <div className="card-body row">
                <Form
                  form={excelForm}
                  name="excelForm"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ margin: "0 auto" }}
                  onValuesChange={handleExcelChange}
                  autoComplete="off"
                >
                  <Row gutter={[16, 24]}>
                    <Col span={6}>
                      <Form.Item
                        label="Verification Type"
                        name="verificationType"
                        rules={[
                          {
                            required: true,
                            message: "Please select a verification type",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder="Select an option"
                          style={{ width: "100%" }}
                          popupClassName="custom-dropdown"
                        >
                          {pointList.map((option, i) => (
                            <Select.Option key={i} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Branch"
                        name="branch"
                        rules={[
                          { required: true, message: "Please select a branch" },
                        ]}
                      >
                        <Select
                          allowClear
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          placeholder="Select an option"
                          style={{ width: "100%" }}
                          popupClassName="custom-dropdown"
                        >
                          {clientBranches.map((option, i) => (
                            <Select.Option key={i} value={option.id}>
                              {option.branch_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Excel File"
                        name="excelFile"
                        extra={
                          <a
                            href="/favicon.ico"
                            download="favicon.ico"
                            className="mx-3 text-decoration-underline text-primary fs-6"
                          >
                            Sample File
                          </a>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please upload an Excel file",
                          },
                          () => ({
                            validator(_, value) {
                              if (
                                !excelFormValues.verificationType ||
                                !excelFormValues.branch
                              ) {
                                return Promise.reject(
                                  "Please select verification type and branch first"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Upload
                          name="logo"
                          accept=".xlsx, .xls"
                          customRequest={({ file, onSuccess }) => {
                            onSuccess();
                          }}
                          disabled={
                            !excelFormValues?.verificationType ||
                            !excelFormValues?.branch
                          }
                          onChange={(e) => {
                            handleFileUpload(e.file.originFileObj);
                          }}
                          maxCount={1}
                          style={{ maxWidth: "150px" }}
                        >
                          {excelFormValues?.excelFile?.fileList.length >
                          0 ? null : (
                            <Button icon={<UploadOutlined />}>
                              Click to upload
                            </Button>
                          )}
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      {excelFileData && excelFileData.length > 0 ? (
                        <Button
                          type="default"
                          className="blue-btn"
                          ref={previewRef}
                          data-bs-toggle="modal"
                          data-bs-target="#excelFileDataModal"
                          classNames="mt-3"
                        >
                          Preview
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="excelFileDataModal"
        tabIndex={-1}
        aria-labelledby="excelFileDataModalLabel"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered "
          style={{ maxWidth: "80%" }}
        >
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h5 className="modal-title" id="excelFileDataModalLabel">
                File Preview
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            {/* Modal Body */}
            <div className="modal-body">
              <div className="table-responsive">
                <ExcelFilePreview fileData={excelFileData} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "2%",
                }}
              >
                <Button style={{ marginLeft: "2%" }} onClick={handleClose}>
                  Cancel
                </Button>
                <Button style={{ marginLeft: "2%" }} onClick={handleSubmit}>
                  Proceed
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddMultipleCase;
