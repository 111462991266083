import React, { useEffect, useState } from 'react'
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useLocation } from 'react-router-dom';
import api from "../../services/Api";
import { useToast } from '../../utils/helpers/CommFun';
import { useSelector } from 'react-redux';

// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export const PDFViewerPage = () => {
    //get token
  const { token } = useSelector((state) => state.auth);
    const Toast = useToast();

    const location = useLocation();
    const [pdfFile, setPdfFile] = useState();
    const defaultLayoutPluginInstance = defaultLayoutPlugin();


    const handleFile = async (e) => {
        let selectedFile = e.target.files[0];
        let reader = new FileReader();
        // const body = {
        //     point_id: location.state.id,
        //     case_id: location.state.case_id,
        //     verification_type: location.state.type
        // }
        reader.readAsDataURL(selectedFile);
        reader.onloadend = (e) => {
            let result = e.target.result;
            // body.report_url = e.target.result;
            setPdfFile(e.target.result);
            // uploadFile(body);
        }

    }
    const uploadFile = async (body) => {
        try {
            const response = await api.post(`/case/update_points`, body, token);
            if (response.status === 200) {
                Toast.fire({
                    icon: "success",
                    title: response.message,
                });
            } else {
                Toast.fire({
                    icon: "error",
                    title: response.message,
                });
            }
        } catch (error) {
            console.error(error);
        } finally {

        }
    }
    return (
        <div className="main-contant">
            <div className="container-fluid">
                <div className="contant-box">
                    <input type='file' onChange={handleFile}></input>
                    <div style={{ display: "flex", height: "500px", width: "auto" }}>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            {pdfFile ? <Viewer plugins={[defaultLayoutPluginInstance]} fileUrl={pdfFile} defaultScale={1} /> : "No Pdf to Display"}
                        </Worker>
                    </div>
                </div>
            </div>
        </div>
    )
}
