import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import logoSm from "../../assets/images/logo-sm.png";
import { API_URL, Role } from "../../utils/constant/Constant";
import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import Swal from "sweetalert2";
import store from "../../redux/store/store";
import { useToast } from "../../utils/helpers/CommFun";
import {
  BranchManagementIcon,
  CaseDetailIcon,
  ClientManagementIcon,
  DashboardIcon,
  HolidayManagementIcon,
  PaymentManagementIcon,
  PickedIcon,
  SettingIcon,
  UserManagementIcon,
  TargetIcon,
  PincodeIcon,
  ProfileIcon,
  ChangePasswordIcon,
  LogoutIcon,
} from "./SidebarIcons";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
import { FileAddOutlined } from "@ant-design/icons";

function Sidebar({ collapsed }) {
  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    if (levelKeys[latestOpenKey] === 1) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    } else {
      setOpenKeys(keys);
    }
  };

  const { userData } = useSelector((state) => state.auth);

  const [items, setItems] = useState([]);
  const [levelKeys, setLevelKeys] = useState({});
  const [isactive, setIsactive] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [current, setCurrent] = useState();
  const [url, setUrl] = useState("");
  const [stateOpenKeys, setStateOpenKeys] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [initialValue, setInitialValue] = useState(null);
  const navigate = useNavigate();
  const Toast = useToast();
  const dispatch = useDispatch();

  useEffect(() => {
    switch (true) {
      case window.location.pathname.endsWith("/profile"):
        setCurrent("Profile");
        break;
      case window.location.pathname.endsWith("/changepassword"):
        setCurrent("ChangePassword");
        break;

      case window.location.pathname.endsWith("/user"):
        setCurrent("UserManagement");
        break;
      case window.location.pathname.endsWith("/dashboard"):
        setCurrent("Dashboard");
        break;

      case window.location.pathname.endsWith("/pincode"):
        setCurrent("PincodeManagement");
        break;
      case window.location.pathname.endsWith("/setting"):
        setCurrent("Settings");
        break;
      case window.location.pathname.endsWith("/setting/privacypolicy"):
        setCurrent("PrivacyPolicy");
        break;
      case window.location.pathname.endsWith("/setting/term&conditions"):
        setCurrent("Term&Conditions");
        break;
      case window.location.pathname.endsWith("/case"):
        setCurrent("CaseListing");
        break;
      case window.location.pathname.endsWith("/case/addCase"):
        setCurrent("AddNewCase");
        break;
      case window.location.pathname.endsWith("/case/addMultipleCase"):
        setCurrent("AddMultipleCase");
        break;
      case window.location.pathname.endsWith("/client"):
        setCurrent("ClientManagement");
        break;
      case window.location.pathname.endsWith("/clientbranch"):
        setCurrent("ClientBranchManagement");
        break;
      case window.location.pathname.endsWith("/branch"):
        setCurrent("BranchManagement");
        break;
      case window.location.pathname.endsWith("/payment"):
        setCurrent("PaymentManagement");
        break;
      case window.location.pathname.endsWith("/documentconfiguration"):
        setCurrent("DocConfig");
        break;
      case window.location.pathname.endsWith("/categoryManagement"):
        setCurrent("Category");
        break;
      case window.location.pathname.endsWith("/target"):
        setCurrent("Target");
        break;
      case window.location.pathname.endsWith("/target/add"):
        setCurrent("NewTarget");
        break;
      case window.location.pathname.endsWith("/target/history"):
        setCurrent("TargetHistory");
        break;
      case window.location.pathname.endsWith("/field"):
        setCurrent("Field");
        break;
      case window.location.pathname.endsWith("holiday"):
        setCurrent("HolidayManagement");
        break;
      case window.location.pathname.endsWith("payment"):
        setCurrent("PaymentManagement");
        break;
      case window.location.pathname.endsWith("pickedcases"):
        setCurrent("PickedCases");
        break;
      default:
        break;
    }
  }, [window.location.pathname]);

  const handleLogout = async () => {
    Swal.fire({
      title: "Logout",
      text: `Are you sure you want to Logout?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Logout",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(logout());
        store.dispatch({ type: "RESET_STORE" });
        navigate("/login");
      }
    });
  };

  useEffect(() => {
    const baseItems = [
      {
        key: "Dashboard",
        label: <span className="label-margin">Dashboard</span>,
        icon: <DashboardIcon />,
        roles: [
          Role.SUPER_ADMIN,
          Role.BANKS,
          Role.MASTER_VENDOR,
          Role.BRANCH_MANAGER,
          Role.TEAM_LEAD,
          Role.BACK_OFFICE,
        ],
      },
      {
        key: "UserManagement",
        label: (
          <span className="label-margin">
            {userData.role === Role.SUPER_ADMIN ? "Vendors" : "Users"}
          </span>
        ),
        icon: <UserManagementIcon />,
        roles: [
          Role.SUPER_ADMIN,
          Role.BANKS,
          Role.BRANCH_MANAGER,
          Role.MASTER_VENDOR,
          Role.TEAM_LEAD,
        ],
      },
      // {
      //   key: "CaseDetails",
      //   label: <span className="label-margin">Case Detail</span>,
      //   icon: <CaseDetailIcon />,
      //   roles: [Role.BRANCH_MANAGER],
      // },
      {
        key: "CaseManagement",
        label: <span className="label-margin">Cases</span>,
        icon: <CaseDetailIcon />,
        roles: [Role.BRANCH_MANAGER, Role.TEAM_LEAD, Role.BACK_OFFICE],
        children: [
          {
            key: "CaseListing",
            label: <span className="label-margin">Case List</span>,
          },
          {
            key: "AddNewCase",
            label: <span className="label-margin">Add New Case</span>,
          },
          // {
          //   key: "AddMultipleCase",
          //   label: <span className="label-margin">Add Multiple Case</span>,
          // },
        ],
      },
      {
        key: "Client",
        label: <span className="label-margin">Client</span>,
        icon: <ClientManagementIcon />,
        roles: [Role.MASTER_VENDOR, Role.BRANCH_MANAGER],
        children: [
          ...(userData.role !== Role.BRANCH_MANAGER
            ? [
                {
                  key: "ClientManagement",
                  label: <span className="label-margin">Client</span>,
                },
              ]
            : []),
          {
            key: "ClientBranchManagement",
            label: <span className="label-margin">Client Branch</span>,
          },
        ],
      },
      {
        key: "PointConfig",
        label: <span className="label-margin">Point Config</span>,
        icon: <TargetIcon />,
        roles: [Role.MASTER_VENDOR],
        children: [
          {
            key: "Category",
            label: <span className="label-margin">Category</span>,
          },
          {
            key: "Field",
            label: <span className="label-margin">Field</span>,
          },
          {
            key: "DocConfig",
            label: <span className="label-margin">Point Config</span>,
          },
        ],
      },
      {
        key: "BranchManagement",
        label: (
          <span className="label-margin">
            {userData.role === Role.MASTER_VENDOR ? "Branch" : "My Branches"}
          </span>
        ),
        icon: <BranchManagementIcon />,
        roles: [Role.MASTER_VENDOR, Role.BRANCH_MANAGER],
      },
      {
        key: "HolidayManagement",
        label: <span className="label-margin">Holiday</span>,
        icon: <HolidayManagementIcon />,
        roles: [Role.BRANCH_MANAGER],
      },
      {
        key: "PickedCases",
        label: <span className="label-margin">Picked Case</span>,
        icon: <PickedIcon />,
        roles: [Role.TEAM_LEAD, Role.BACK_OFFICE],
      },
      {
        key: "PaymentManagement",
        label: <span className="label-margin">Payment</span>,
        icon: <PaymentManagementIcon />,
        roles: [Role.MASTER_VENDOR, Role.BRANCH_MANAGER],
      },
      {
        key: "Target",
        label: <span className="label-margin">Target</span>,
        icon: <TargetIcon />,
        roles: [Role.MASTER_VENDOR, Role.BRANCH_MANAGER],
        children: [
          ...(userData.role !== Role.MASTER_VENDOR
            ? [
                {
                  key: "NewTarget",
                  label: <span className="label-margin">New Target</span>,
                },
              ]
            : []),
          {
            key: "TargetHistory",
            label: <span className="label-margin">Target History</span>,
          },
        ],
      },
      {
        key: "PincodeManagement",
        label: <span className="label-margin">Pincode</span>,
        icon: <PincodeIcon />,
        roles: [Role.SUPER_ADMIN],
      },
      {
        key: "Settings",
        label: <span className="label-margin">Settings</span>,
        icon: <SettingIcon />,
        roles: [Role.SUPER_ADMIN],
        children: [
          {
            key: "PrivacyPolicy",
            label: <span className="label-margin">Privacy Policy</span>,
          },
          {
            key: "Terms&Conditions",
            label: <span className="label-margin">Terms & Conditions</span>,
          },
        ],
      },
      {
        key: "Profile",
        label: <span className="label-margin">Profile</span>,
        icon: <ProfileIcon />,
      },
      {
        key: "ChangePassword",
        label: <span className="label-margin">Change Password</span>,
        icon: <ChangePasswordIcon />,
      },
      {
        key: "Logout",
        label: <span className="label-margin">Logout</span>,
        icon: <LogoutIcon />,
        onClick: handleLogout,
      },
    ];

    // Filter items based on user role
    const newItems = baseItems.filter(
      (item) => !item.roles || item.roles.includes(userData.role)
    );

    setItems(newItems);
    setLevelKeys(getLevelKeys(newItems));
  }, [userData]);

  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };

  const toggleSubMenu = () => {
    setSubMenuOpen(!subMenuOpen);
  };

  const rolePathMap = {
    [Role.SUPER_ADMIN]: {
      Dashboard: "/super-admin/dashboard",
      UserManagement: "/super-admin/user",
      Profile: "/super-admin/profile",
      ChangePassword: "/super-admin/changepassword",
      PincodeManagement: "/super-admin/pincode",
      Settings: "/super-admin/setting",
      PrivacyPolicy: "/super-admin/setting/privacypolicy",
      "Term&Conditions": "/super-admin/setting/term&conditions",
    },
    [Role.MASTER_VENDOR]: {
      Dashboard: "/admin/dashboard",
      UserManagement: "/admin/user",
      Profile: "/admin/profile",
      ChangePassword: "/admin/changepassword",
      CaseListing: "/admin/case",
      ClientManagement: "/admin/client",
      ClientBranchManagement: "/admin/clientbranch",
      BranchManagement: "/admin/branch",
      PaymentManagement: "/admin/payment",
      DocConfig: "/admin/documentconfiguration",
      Category: "/admin/categoryManagement",
      Target: "/admin/target",
      NewTarget: "/admin/target/add",
      TargetHistory: "/admin/target/history",
      Field: "/admin/field",
    },
    [Role.BANKS]: {
      Dashboard: "/admin/dashboard",
      UserManagement: "/admin/user",
      Profile: "/admin/profile",
      ChangePassword: "/admin/changepassword",
    },
    [Role.BRANCH_MANAGER]: {
      Dashboard: "/user/dashboard",
      UserManagement: "/user/user",
      CaseListing: "/user/case",
      AddNewCase: "/user/case/addCase",
      AddMultipleCase: "/user/case/addMultipleCase",
      Profile: "/user/profile",
      ChangePassword: "/user/changepassword",
      ClientBranchManagement: "/user/clientbranch",
      BranchManagement: "/user/branch",
      HolidayManagement: "/user/holiday",
      DocConfig: "/user/documentconfiguration",
      Category: "/user/categoryManagement",
      Target: "/user/target",
      NewTarget: "/user/target/add",
      TargetHistory: "/user/target/history",
      PaymentManagement: "/user/payment",
    },
    [Role.TEAM_LEAD]: {
      Dashboard: "/user/dashboard",
      UserManagement: "/user/user",
      CaseListing: "/user/case",
      AddNewCase: "/user/case/addCase",
      AddMultipleCase: "/user/case/addMultipleCase",
      PickedCases: "/user/pickedcases",
      Profile: "/user/profile",
      ChangePassword: "/user/changepassword",
    },
    [Role.BACK_OFFICE]: {
      Dashboard: "/user/dashboard",
      UserManagement: "/user/user",
      CaseListing: "/user/case",
      AddNewCase: "/user/case/addCase",
      AddMultipleCase: "/user/case/addMultipleCase",
      PickedCases: "/user/pickedcases",
      Profile: "/user/profile",
      ChangePassword: "/user/changepassword",
    },
    [Role.FE_PE]: {
      Dashboard: "/user/dashboard",
      Profile: "/user/profile",
      ChangePassword: "/user/changepassword",
    },
  };

  const handleClick = (e) => {
    const role = userData.role;
    const key = e.key;
    if (e.key == "Logout") {
      return;
    }
    setCurrent(key);
    // Set default path
    let targetPath = rolePathMap[role]?.Dashboard || "/";

    // Update the path based on the selected key, if applicable
    if (rolePathMap[role]?.[key]) {
      targetPath = rolePathMap[role][key];
    }
    if (levelKeys[key] === 1) {
      setOpenKeys(key ? [key] : []);
    }

    setUrl(targetPath);
    navigate(targetPath);
  };

  return (
    <>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="main-navbar">
          <div className="nav">
            <div className="nav-logo">
              <Link to="#" className="full-logo">
                <img src={logo} alt="logo" />
              </Link>
              <Link to="#" className="sm-logo">
                <img src={logoSm} alt="logo" />
              </Link>
            </div>
            <div
              style={{
                width: "100%",
              }}
            >
              <Menu
                mode="inline"
                openKeys={openKeys} // Updated to use openKeys
                onOpenChange={onOpenChange} // Updated onOpenChange handler
                selectedKeys={[current]} // Ensure the current selection is highlighted
                items={items}
                onClick={handleClick}
              />
            </div>
          </div>
        </div>
      </Sider>
    </>
  );
}

export default Sidebar;
