import React, { useEffect, useState } from "react";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { useLocation, useNavigate } from "react-router-dom";
import { Steps } from "antd";
import { Role } from "../../utils/constant/Constant";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllClientBranches } from "../../redux/slices/clientBranchSlice";
import SkeletonForm from "../../components/DynamicForm/SkeletonForm";
import { fetchAllPickedCases } from "../../redux/slices/pickedCaseSlice";
import {
  createOrUpdateCase,
  fetchAllCases,
  fetchCaseDetails,
  resetCaseDetails,
} from "../../redux/slices/caseSlice";
import dayjs from "dayjs";
import { createUrlObj, validateStr } from "../../utils/helpers/CommFun";

const AddNewCaseWithApplicant = () => {
  const dispatch = useDispatch();
  const { clientBranches } = useSelector((state) => state.clientBranch);
  const { pickedCases } = useSelector((state) => state.pickedCase);


  const { userData } = useSelector((state) => state.auth);
  const [currentStep, setCurrentStep] = useState(0); // Tracks current step in the form process
  const navigate = useNavigate();

  const location = useLocation();
  const [caseId, setCaseId] = useState(location.state || null);

  const { CaseDetails } = useSelector((state) => state.Case);

  useEffect(() => {
    dispatch(fetchAllPickedCases({ non_attached: true, raw_case_id: CaseDetails?.raw_case_id }));
    dispatch(fetchAllClientBranches({}));
  }, [dispatch]);

  useEffect(() => {
    setInitialValue({
      type: 1,
      received_at: dayjs(Date.now()),
    });
  }, []);
  useEffect(() => {
    if (caseId) {
      const body = {
        case_id: caseId.id,
      };
      dispatch(fetchCaseDetails(body));
    } else {
      dispatch(resetCaseDetails());
    }
  }, [dispatch, caseId]);

  useEffect(() => {
    if (
      caseId &&
      CaseDetails &&
      CaseDetails.applicant_data &&
      CaseDetails.applicant_data.length > 0
    ) {
      setInitialValue({
        branchId: CaseDetails.branch_id,
        received_at: dayjs(CaseDetails.received_at),
        loan_type: CaseDetails.loan_type,
        raw_case_pdf: CaseDetails.raw_case_pdf ? createUrlObj("raw_case_pdf", CaseDetails.raw_case_pdf) : undefined,
        raw_case_id: CaseDetails.raw_case_id,
        loan_amount: CaseDetails.loan_amount,
        first_name: CaseDetails.applicant_data[0]?.first_name,
        middle_name: CaseDetails.applicant_data[0]?.middle_name,
        last_name: CaseDetails.applicant_data[0]?.last_name,
        type: 1,
        mobile_no: CaseDetails.applicant_data[0].mobile_no,
        mobile_no_2: CaseDetails.applicant_data[0].mobile_no_2,
        res_no: CaseDetails.applicant_data[0].res_no,
        office_no: CaseDetails.applicant_data[0].office_no,
        case_id: CaseDetails.id,
        applicant_id: CaseDetails.applicant_data[0].id,
      });
    }
  }, [CaseDetails, caseId]);

  const [initialValue, setInitialValue] = useState();

  const clear = () => {
    navigate(-1);
  };

  const caseFormConfig = [
    {
      label: "Branch",
      name: "branchId",
      type: "select",
      options: clientBranches.map((item) => ({
        value: item.id,
        label: item.branch_name,
      })),
      colSpan: 12,
      rules: [{ required: true, message: "Please Select One Option" }],
    },
    {
      label: "Received",
      name: "received_at",
      type: "date",
      showTime: { format: "hh:mm A" },
      displayFormat: "DD-MM-YYYY hh:mm A",
      colSpan: 12,
      rules: [{ required: true, message: "Please Select Date" }],
    },
    {
      label: "Loan Type",
      name: "loan_type",
      type: "select",
      options: [
        { value: 1, label: "Home Loan" },
        { value: 2, label: "Car Loan" },
        { value: 3, label: "Business Loan" },
        { value: 4, label: "Personal Loan" },
      ],
      colSpan: 12,
    },
    {
      label: "Loan Amount",
      name: "loan_amount",
      type: "number-input",
      maxLength: 11,
      colSpan: 12,
      rules: [
        {
          validator: (_, value) => {
            if (value === undefined || value === null || value === "") {
              // If the field is empty, do not show an error
              return Promise.resolve();
            }
            if (value <= 0) {
              return Promise.reject(new Error("Loan amount must be greater than 0."));
            }
            return Promise.resolve();
          },
        },
      ],
    },
    {
      label: "Picked Case",
      name: "raw_case_id",
      type: "select",
      options: pickedCases.map((raw) => ({
        value: raw.id,
        label: `${raw.id}  (${raw.title || raw.name})`,
      })),
      showSearch: true,
      colSpan: 12,
    },
    {
      label: "PDF File",
      name: "raw_case_pdf",
      type: "upload_new",
      accept: ".pdf",
      colSpan: 12,
    },
    {
      label: "case_id",
      name: "case_id",
      type: "hidden",
      initialValue: null,
    },
  ];

  // Form configuration for the second form
  const applicantFormConfig = [
    {
      label: "Type",
      name: "type",
      type: "select",
      options: [{ value: 1, label: "Applicant" }],
      rules: [{ required: true, message: "Please Select One Option" }],
      colSpan: 12,
      disabled: true,
    },
    {
      label: "First Name",
      name: "first_name",
      type: "input",
      maxLength: 15,
      rules: [{ required: true, message: "Please Enter First Name" }],
      colSpan: 12,
    },
    {
      label: "Middle Name",
      name: "middle_name",
      type: "input",
      maxLength: 15,
      colSpan: 12,
    },
    {
      label: "Last Name",
      name: "last_name",
      type: "input",
      maxLength: 15,
      rules: [{ required: true, message: "Please Enter Last Name" }],
      colSpan: 12,
    },
    {
      label: "Mobile 1",
      name: "mobile_no",
      type: "number-input",
      maxLength: 10,
      rules: [
        { required: true, message: "Please Enter Mobile 1" },
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 12,
    },
    {
      label: "Mobile 2",
      name: "mobile_no_2",
      type: "number-input",
      maxLength: 10,
      rules: [
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 12,
    },
    {
      label: "Resident Mobile Number",
      name: "res_no",
      type: "number-input",
      maxLength: 10,
      rules: [
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 12,
    },
    {
      label: "Office Number",
      name: "office_no",
      type: "number-input",
      maxLength: 10,
      rules: [
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 12,
    },
    {
      label: "applicant_id",
      name: "applicant_id",
      type: "hidden",
      initialValue: null,
    },
  ];

  const redirctToCaseDetails = async () => {
    if (userData.role == Role.SUPER_ADMIN) {
      navigate("/super-admin/case");
    } else if (
      userData.role == Role.BANKS ||
      userData.role == Role.MASTER_VENDOR
    ) {
      navigate("/admin/case");
    } else if (
      userData.role == Role.BRANCH_MANAGER ||
      userData.role == Role.TEAM_LEAD ||
      userData.role == Role.BACK_OFFICE
    ) {
      navigate("/user/case");
    } else {
      navigate("/login");
    }
  };

  //ADD NEW APPLICANT
  const CreateApplicant = async () => {
    const body = {
      branch_id: initialValue.branchId,
      received_at: initialValue.received_at,
      type: initialValue.type,
      name: initialValue.first_name + " " + initialValue.last_name || "",
      first_name: initialValue.first_name || "",
      middle_name: initialValue.middle_name || "",
      last_name: initialValue.last_name || "",
      mobile_no: initialValue.mobile_no,
      mobile_no_2: initialValue.mobile_no_2,
      res_no: initialValue.res_no,
      office_no: initialValue.office_no,
      loan_type: initialValue.loan_type || 0,
      // raw_case_pdf: initialValue.raw_case_pdf || 0,
      raw_case_id: initialValue.raw_case_id || null,
      loan_amount: initialValue.loan_amount || 0,
      user_id: userData.id,
    };
    const formData = new FormData();

    Object.keys(body).forEach((key) => {
      if (validateStr(body[key]))
        formData.append(key, body[key]);
    });

    const appendFile = async (key, file) => {
      if (file && file.length > 0) {
        formData.append(key, file[0].originFileObj);
      }
    };

    appendFile("raw_case_pdf", initialValue.raw_case_pdf ?? undefined);
    if (initialValue.case_id) {
      formData.append("case_id", initialValue.case_id);
      formData.append("applicant_id", initialValue.applicant_id);
      // body.case_id = initialValue.case_id;
      // body.applicant_id = initialValue.applicant_id;
    }
    const isUpdate = false;
    dispatch(createOrUpdateCase({ formData, isUpdate }))
      .unwrap()
      .then((response) => {
        dispatch(fetchAllCases({}));
        redirctToCaseDetails();
      })
      .catch((error) => {
        console.error("Error creating/updating client:", error);
      });
  };

  // Handle form submission
  const handleSubmit = (values) => {
    if (currentStep === 0 && !checkCaseDetailsExistance()) {
      setCurrentStep(1);
    } else if (currentStep === 1 || checkCaseDetailsExistance()) {
      CreateApplicant();
    }
  };

  const handleFieldChange = (changedValues) => {
    setInitialValue((prev) => {
      return { ...prev, ...changedValues };
    });
  };
  const checkCaseDetailsExistance = () => !!CaseDetails && (Array.isArray(CaseDetails) ? CaseDetails.length : Object.keys(CaseDetails).length);

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5 className="bottom-line">Add New Case</h5>
                </div>
              </div>
            </div>
          </div>
          <section id="add-new-user">
            <div className="add_user-box">
              <div className="add_user-form">
                {checkCaseDetailsExistance() ? ""
                  : <Steps
                    size="large"
                    current={currentStep}
                    items={[{ title: "Case" }, { title: "Applicant" }]}
                    className="mb-3"
                  />
                }
                {caseFormConfig && caseId && !initialValue ? (
                  <SkeletonForm
                    config={
                      currentStep === 0 ? caseFormConfig : applicantFormConfig
                    }
                    key={CaseDetails}
                    submitText={(currentStep === 0 && !checkCaseDetailsExistance()) ? "Next" : "Submit"}
                    initialValues={initialValue}
                    handleFieldChange={handleFieldChange}
                    onCancel={clear}
                    onFinish={handleSubmit}
                    showBack={currentStep === 1 ? true : false}
                    onBack={() => setCurrentStep(0)}
                  />
                ) : (caseFormConfig && caseId && initialValue) ||
                  (caseFormConfig && !caseId) ? (
                  <DynamicForm
                    config={
                      currentStep === 0 ? caseFormConfig : applicantFormConfig
                    }
                    key={CaseDetails}
                    submitText={(currentStep === 0 && !checkCaseDetailsExistance()) ? "Next" : "Submit"}
                    initialValues={initialValue}
                    handleFieldChange={handleFieldChange}
                    onCancel={clear}
                    onFinish={handleSubmit}
                    showBack={currentStep === 1 ? true : false}
                    onBack={() => setCurrentStep(0)}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddNewCaseWithApplicant;
