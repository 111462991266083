// features/user/userSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";

import { createOrUpdateData, deleteData, fetchData } from "../utils/apiActions";

export const fetchAllPayments = fetchData("payment", API_URL.GET_PAYMENT);

export const createOrUpdatePayment = createOrUpdateData(
  "payment",
  API_URL.CREATE_PINCODE,
  API_URL.UPDATE_PINCODE
);

export const deletePayment = deleteData("payment", API_URL.REMOVE_PAYMENT);

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    payments: [],
    selectedPayment: null,
    status: STATUS_TYPE.IDLE,
    total: 0,
    error: null,
  },
  reducers: {
    paymentStatusReset: (state) => {
      state.status = STATUS_TYPE.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPayments.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllPayments.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.payments = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchAllPayments.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(createOrUpdatePayment.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(createOrUpdatePayment.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        // Optional: Handle additional logic on successful payment creation/updation
      })
      .addCase(createOrUpdatePayment.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(deletePayment.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(deletePayment.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(deletePayment.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      });
  },
});

export const { paymentStatusReset } = paymentSlice.actions;
export default paymentSlice.reducer;
