// features/user/userSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import {
  createOrUpdateData,
  deleteData,
  fetchData,
  updateStatus,
} from "../utils/apiActions";

export const fetchAllField = fetchData("field", API_URL.GET_ALL_FIELD);

export const createOrUpdateField = createOrUpdateData(
  "field",
  API_URL.CREATE_FIELD,
  API_URL.UPDATE_FIELD
);
export const fetchPerticularField = fetchData(
  "clientBranch",
  API_URL.GET_PARTICULAR_FIELD,
  "fetchPerticular"
);

export const deleteField = deleteData("field", API_URL.REMOVE_FIELD);

const fieldSlice = createSlice({
  name: "field",
  initialState: {
    fields: [],
    selectedField: null,
    status: STATUS_TYPE.IDLE,
    total: 0,
    error: null,
  },
  reducers: {
    fieldStatusReset: (state) => {
      state.status = STATUS_TYPE.IDLE;
    },
    resetSelectedField: (state) => {
      state.selectedField = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllField.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllField.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.fields = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchAllField.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchPerticularField.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchPerticularField.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.selectedField = action.payload;
      })
      .addCase(fetchPerticularField.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(createOrUpdateField.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(createOrUpdateField.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        // Optional: Handle additional logic on successful field creation/updation
      })
      .addCase(createOrUpdateField.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(deleteField.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(deleteField.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(deleteField.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      });
  },
});
export const { fieldStatusReset, resetSelectedField } = fieldSlice.actions;
export default fieldSlice.reducer;
