import React, { useEffect, useState } from "react";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { validateStr } from "../../utils/helpers/CommFun";
import { Role, STATUS_TYPE } from "../../utils/constant/Constant";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonForm from "../../components/DynamicForm/SkeletonForm";
import {
  createOrUpdateClientBranch,
  fetchPerticularClientBranch,
} from "../../redux/slices/clientBranchSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllClients,
  fetchClientPayment,
  resetClientPayment,
} from "../../redux/slices/clientSlice";
import {
  fetchStateCity,
  resetStateCity,
} from "../../redux/slices/stateCitySlice";
import { fetchAllVendorBranches } from "../../redux/slices/vendorBranchSlice";

const AddClientBranch = () => {
  const dispatch = useDispatch();

  const { selectedClientBranch, status: clientBranchStatus } = useSelector(
    (state) => state.clientBranch
  );

  const {
    clients,
    clientPayments,
    status: clientStatus,
  } = useSelector((state) => state.client);

  const { vendorBranches, status: vendorBranchStatus } = useSelector(
    (state) => state.vendorBranch
  );

  const { pincodeId, city, state } = useSelector((state) => state.stateCity);

  const { userData } = useSelector((state) => state.auth);
  const location = useLocation();
  const [clientBranchId, setClientBranchId] = useState(
    location.state?.clientBranchId || null
  );

  const navigate = useNavigate();
  const [initialValue, setInitialValue] = useState();

  useEffect(() => {
    dispatch(resetStateCity());
    dispatch(fetchAllVendorBranches({}));
    dispatch(fetchAllClients({}));
  }, [dispatch]);

  useEffect(() => {
    if (clientBranchId) {
      const body = {
        branch_id: clientBranchId,
      };
      dispatch(fetchPerticularClientBranch(body));
    }
  }, [clientBranchId]);

  useEffect(() => {
    if (selectedClientBranch) {
      setInitialValue({
        clientBranchId: selectedClientBranch.id,
        clientId: selectedClientBranch.client_id.id,
        branchName: selectedClientBranch.branch_name,
        vendorBranch: selectedClientBranch.vendor_branch_id.id,
        branchCode: selectedClientBranch.branch_code,
        address: selectedClientBranch.address,
        primaryNumber: selectedClientBranch.phone_number,
        secondaryNumber: selectedClientBranch.secondary_phone_number,
        receiveEmail: selectedClientBranch.recieve_email,
        sendEmail: selectedClientBranch.send_email_using,
        pincode: selectedClientBranch.pincode_id.pincode,
        contactName: selectedClientBranch.contact_name,
        designation: selectedClientBranch.designation,
        tatType: JSON.stringify(selectedClientBranch.tat_type),
        tat: selectedClientBranch.tat,
        // priceType: selectedClientBranch.price_type,
        // price: selectedClientBranch.price,
        // target: selectedClientBranch.target,
        state: selectedClientBranch.pincode_id.state_name,
        city: selectedClientBranch.pincode_id.district,
        clRate: selectedClientBranch.payment_id?.cl_rate ?? "",
        oclRate: selectedClientBranch.payment_id?.ocl_rate ?? "",
        clientData: selectedClientBranch.payment_id?.title ?? "",
        paymentType: selectedClientBranch.payment_id
          ? selectedClientBranch.payment_id.payment_type === 1
            ? "Applicant"
            : selectedClientBranch.payment_id.payment_type === 2
            ? "Case"
            : "Point Verification"
          : "",
      });
    }
  }, [selectedClientBranch]);

  useEffect(() => {
    setInitialValue((prev) => {
      return {
        ...prev,
        pincodeId,
        city,
        state,
      };
    });
  }, [pincodeId]);

  // useEffect(() => {
  //   getMasterBranchList();
  // }, []);

  // Create Branch list
  const handleCreateOrUpdateClientBranch = async (values) => {
    const body = {
      client_id: parseInt(values.clientId),
      branch_name: values.branchName,
      vendor_branch_id: parseInt(values.vendorBranch),
      branch_code: values.branchCode,
      pincode: parseInt(values.pincode),
      address: values.address,
      phone_number: parseInt(values.primaryNumber),
      secondary_phone_number: parseInt(values.secondaryNumber),
      parent_id: userData.id,
      pincode: values.pincode,
      state: values.state,
      city: values.city,
      tat: values.tat,
      tat_type: parseInt(values.tatType),
      payment_id: parseInt(values.clientData),
      gst: values.gst,
      pincode_id: values.pincodeId,
      // price: values.price,
      // price_type: parseInt(priceType),
      // target: parseInt(values.target),
      send_email_using: values.sendEmail,
      recieve_email: values.receiveEmail,
      contact_name: values.contactName,
      designation: values.designation,
      user_id: userData.id,
    };

    if (clientBranchId) {
      body.branch_id = clientBranchId;
      if (validateStr(values.password)) body.password = values.password;
    } else {
      body.password = values.password;
    }

    body.master_id =
      userData.role == Role.MASTER_VENDOR ? userData.id : userData.master_id;

    const isUpdate = clientBranchId ? true : false;
    dispatch(createOrUpdateClientBranch({ body, isUpdate }))
      .unwrap()
      .then((response) => {
        navigate(-1);
      })
      .catch((error) => {});
  };

  const clear = () => {
    setInitialValue(null);
    navigate(-1);
  };

  const handleClientChange = async (val) => {
    if (val) {
      const body = {
        client_id: val,
      };
      dispatch(fetchClientPayment(body));
    } else {
      dispatch(resetClientPayment());
    }
  };

  //get vendor branch
  //Get the Branch list
  // const getMasterBranchList = async () => {
  //   try {
  //     let url =
  //       userData.role == Role.TEAM_LEAD
  //         ? API_URL.GET_USER_MASTER_BRANCH
  //         : API_URL.GET_ALL_MASTER_BRANCH;
  //     const response = await api.post(url, {}, token);
  //     if (response.status === 200) {
  //       let masterBranchList = response.result.map((branch) => ({
  //         value: branch.id,
  //         label: branch.name,
  //       }));
  //       if (userData.role == Role.TEAM_LEAD) {
  //         masterBranchList = [
  //           ...masterBranchList,
  //           { value: userData.branch_id.id, label: userData.branch_id.name },
  //         ];
  //       }
  //       setMasterBranchList(masterBranchList);
  //     } else {
  //       console.error("Failed to fetch client list");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //   }
  // };

  const setPaymentDetails = async (val) => {
    const obj = clientPayments.find((payment) => payment.id == val);
    setInitialValue((prev) => {
      return {
        ...prev,
        clRate: obj.cl_rate,
        oclRate: obj.ocl_rate,
        paymentType:
          obj.payment_type == 1
            ? "Applicant"
            : obj.payment_type == 2
            ? "Case"
            : "Point Verification",
      };
    });
  };

  const validateTat = (rule, value) => {
    if (initialValue?.tatType == "1") {
      if (parseInt(value) > 9) {
        return Promise.reject(new Error("TAT value should be less than 9"));
      }
      return Promise.resolve();
    } else if (initialValue?.tatType == "2") {
      if (parseInt(value) > 24) {
        return Promise.reject(new Error("TAT value should be less than 24"));
      }
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("Select TAT Type"));
    }
  };

  const formConfig = [
    {
      label: "Client",
      name: "clientId",
      type: "select",
      options: clients.map((client) => ({
        value: client.id,
        label: client.name,
      })),
      colSpan: 8,
      onChange: (val) => {
        handleClientChange(val);
      },
    },
    {
      label: "Payment",
      name: "clientData",
      type: "select",
      colSpan: 8,
      options: clientPayments.map((payment) => ({
        value: payment.id,
        label: payment.title,
      })),
      rules: [{ required: true, message: "Please Select Payment Option" }],
      onChange: (val) => {
        setPaymentDetails(val); // Fetch payment details based on selected client type
      },
    },
    {
      label: "Vendor Branch",
      name: "vendorBranch",
      type: "select",
      options: vendorBranches.map((branch) => ({
        value: branch.id,
        label: branch.name,
      })),
      rules: [{ required: true, message: "Please Select One Option" }],
      colSpan: 8,
    },
    validateStr(initialValue?.paymentType) && {
      label: "Payment Type",
      name: "paymentType",
      type: "input",
      colSpan: 8,
      disabled: true, // Input is read-only
    },
    validateStr(initialValue?.clRate) && {
      label: "CL Rate",
      name: "clRate",
      type: "input",
      maxLength:6,
      colSpan: 8,
      disabled: true, // Input is read-only
    },
    validateStr(initialValue?.oclRate) && {
      label: "OCL Rate",
      name: "oclRate",
      type: "input",
      maxLength:6,
      colSpan: 8,
      disabled: true, // Input is read-only
    },
    {
      label: "Branch Name",
      name: "branchName",
      type: "input",
      rules: [{ required: true, message: "Please Enter Branch Name" }],
      maxLength:50,
      colSpan: 8,
    },
    {
      label: "Branch Code",
      name: "branchCode",
      maxLength:10,
      type: "input",
      rules: [{ message: "Please Enter Branch Code" }],
      colSpan: 8,
    },
    {
      label: "Address",
      name: "address",
      type: "input",
      maxLength:250,
      rules: [{ required: true, message: "Please Enter Address" }],
      colSpan: 8,
    },
    {
      label: "Pincode",
      name: "pincode",
      type: "number-input",
      maxLength:6,
      colSpan: 8,
      rules: [
        { required: true, message: "Please Enter Pincode" },
        {
          pattern: /^[0-9]{6}$/,
          message: "Pincode must be exactly 6 digits",
        },
      ],
      maxLength: 6,
    },
    {
      label: "City",
      name: "city",
      type: "input",
      rules: [
        { required: true, message: "Please Enter Valid Pincode to Fetch City" },
      ],
      colSpan: 8,
      disabled: true,
    },
    {
      label: "State",
      name: "state",
      type: "input",
      rules: [
        {
          required: true,
          message: "Please Enter Valid Pincode to Fetch State",
        },
      ],
      disabled: true,
      colSpan: 8,
    },

    {
      label: "Contact Name",
      name: "contactName",
      maxLength:15,
      type: "input",
      rules: [{ required: true, message: "Please Enter Contact Name" }],
      colSpan: 8,
    },
    {
      label: "Contact No.",
      name: "primaryNumber",
      type: "number-input",
      rules: [
        { required: true, message: "Please Enter Phone Number" },
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 8,
      maxLength: 10,
    },
    {
      label: "Secondary Phone Number",
      name: "secondaryNumber",
      type: "number-input",
      rules: [
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 8,
      maxLength: 10,
    },
    {
      label: "Designation",
      name: "designation",
      type: "input",
      // rules: [{ required: true, message: "Please Enter Designation" },],
      colSpan: 8,
      maxLength:25,
    },
    {
      label: "Email Send",
      name: "sendEmail",
      type: "input",
      maxLength:50,
      rules: [{ type: "email", required: true, message: "Please Enter Email" }],
      colSpan: 8,
    },
    {
      label: "Email Recieve",
      name: "receiveEmail",
      type: "input",
      maxLength:50,
      rules: [{ type: "email", required: true, message: "Please Enter Email" }],
      colSpan: 8,
    },
    {
      label: "Password",
      name: "password",
      type: "password",
      maxLength:25,
      rules: !clientBranchId
        ? [{ required: true, message: "Please Enter Password" }]
        : [],
      colSpan: 8,
    },
    {
      label: "GST Number",
      name: "gst",
      type: "input",
      maxLength:15,
      colSpan: 8,
    },
    {
      label: "TAT TYPE",
      name: "tatType",
      type: "select",
      options: [
        { value: "1", label: "Day" },
        { value: "2", label: "Hour" },
      ],
      rules: [{ required: true, message: "Please Select One Option" }],
      colSpan: 8,
    },
    {
      label: "TAT",
      name: "tat",
      type: "number-input",
      rules: [
        { required: true, message: "TAT is required" },
        { pattern: /^[1-9][0-9]*$/, message: "TAT must be a positive number" },
        { validator: validateTat },
      ],
      colSpan: 8,
    },
    // {
    //   label: "Target",
    //   name: "target",
    //   type: "input",
    //   colSpan: 8,
    // },
    {
      label: "clientBranchId",
      name: "clientBranchId",
      type: "hidden",
      initialValue: null,
    },
    {
      label: "pincodeId",
      name: "pincodeId",
      type: "hidden",
      initialValue: null,
    },
  ];

  const handleFieldChange = (changedValues) => {
    if (changedValues.pincode) {
      if (String(changedValues.pincode).length === 6) {
        dispatch(fetchStateCity(changedValues.pincode));
      } else {
        dispatch(resetStateCity());
        setInitialValue((prev) => {
          return { ...prev, pincodeId: null, city: null, state: null };
        });
      }
    }
    if (changedValues.clientId) {
      setInitialValue((prev) => {
        return {
          ...prev,
          clientData: null,
          clRate: null,
          oclRate: null,
          paymentType: null,
        };
      });
    }
    setInitialValue((prev) => {
      return { ...prev, ...changedValues };
    });
  };

  return (
    <div className="main-contant">
      <div className="container-fluid">
        {!clientBranchId || clientBranchStatus == STATUS_TYPE.SUCCEEDED ? (
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-8">
                  <div className="title-box">
                    {clientBranchId ? (
                      <h5 className="bottom-line">Edit Client Branch </h5>
                    ) : (
                      <h5 className="bottom-line">Add New Client Branch</h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <section id="add-new-user">
              <div className="add_user-box">
                <div className="add_user-form">
          {
           (formConfig && clientBranchId && !initialValue)
            ? 
           (
              <SkeletonForm
              key={initialValue}
              config={formConfig}
              initialValues={initialValue}
              onFinish={handleCreateOrUpdateClientBranch}
              handleFieldChange={handleFieldChange}
              onCancel={clear}
                />
             
               ) : (
               (formConfig && (clientBranchId && initialValue)) || (formConfig && !clientBranchId) ? (
                  <DynamicForm
                    key={initialValue}
                    config={formConfig}
                    initialValues={initialValue}
                    onFinish={handleCreateOrUpdateClientBranch}
                    handleFieldChange={handleFieldChange}
                    onCancel={clear}
                  />
                ) : "" 
              )} 
                </div>
              </div>
            </section>
          </div>
        ) : (
          <div>Loading</div>
        )}
      </div>
    </div>
  );
};

export default AddClientBranch;
