/*
-------------------------------------------------------------------------------------

DO NOT DELETE - REMOVE ANY COMMENT FROM THIS FILE

-------------------------------------------------------------------------------------
*/

import React, { useEffect, useRef, useState } from "react";
import { Form, Select, Button, Row, Col } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "bootstrap";
import api from "../../services/Api";
import profile2 from "../../assets/images/profile-2.png"; //for profile2 image
import send from "../../assets/images/chat-send-btn-icon.png";
import { createUrlObj, createUrlObjForMultiple, triggerConfirmation, validateStr } from "../../utils/helpers/CommFun";
import Swal from "sweetalert2";
import { io } from "socket.io-client";
import {
  API_URL,
  APPLICANT_STATUS_TEXT,
  POINT_STATUS_ENUM,
  POINT_STATUS_TEXT,
  Role,
  VerList,
  originalVerificationList,
  points,
  titleList,
} from "../../utils/constant/Constant";
import AntdTable from "../../components/AntdTable/Table";
import { Space, Tooltip } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CommentOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import DynamicForm from "../../components/DynamicForm/DynamicForm";

// Import the formconfig
import {
  BRTVerificationFormConfig,
  BankStatementVerificationFormConfig,
  BuilderVerFormConfig,
  BusinessVerificationFormConfig,
  CarDealerVerFormConfig,
  newObjects,
  EmployementVerificationFormConfig,
  EmployerVerFormConfig,
  FinancialsVerFormConfig,
  Form_16FormConfig,
  GSTNVerificationFormConfig,
  GSTReturnsVerificationFormConfig,
  ITReturnVerificationFormConfig,
  IdentityVerAadharFormConfig,
  IdentityVerPANFormConfig,
  PaySlipVerificationFormConfig,
  PoolPurchaseVerFormConfig,
  QuotationVerFormConfig,
  ResidenceVerificationFormConfig,
  ShopActLicenseVerFormConfig,
  TeleVerificationReportFormConfig,
  UdyamRegistrationVerFormConfig,
  Form_26FormConfig,
  PartialDisbursementVerFormConfig,
} from "./VerificationFormConfing";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrUpdateCase,
  createOrUpdatePoint,
  deleteApplicant,
  deletePoint,
  fetchCaseDetails,
  fetchFEList,
  fetchVerificationType,
  resetCaseDetails,
  resetFEList,
} from "../../redux/slices/caseSlice";
import CaseDetailsPage from "./CaseDetailsPage";
import dayjs from "dayjs";

const { Option } = Select;

function AddNewCase() {
  const dispatch = useDispatch();
  const { VerificationTypes, FEList, CaseDetails } = useSelector(
    (state) => state.Case
  );
  const [pointsList, setPointsList] = useState([]);



  const { userData, token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  //Verification Point List for the type list
  const [verificationList, setVerificationList] = useState([]);

  //Location hook
  const location = useLocation();
  //CaseId,
  const [caseId, setCaseId] = useState(location.state?.case_id || null);

  const [applicantId, setApplicantId] = useState(null);

  const [currentMessage, setCurrentMessage] = useState("");

  const [form] = Form.useForm();

  //Static Values that Won't Change on edit
  const [verificationType, setVerificationType] = useState("");

  const [isPointUpdate, setisPointUpdate] = useState(false);

  const [applicantInitialValue, setApplicantInitialValue] = useState({});
  const [pointInitialValue, setPointInitialValue] = useState({});

  //Applicant Modal Ref and Functions
  const applicantCloseRef = useRef();
  const applicantModalClose = () => {
    setApplicantInitialValue(null);
    applicantCloseRef.current.click();
  };

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const applicantOpenRef = useRef();
  const applicantModalOpen = () => applicantOpenRef.current.click();

  //Point Modal Ref and Functions
  const pointCloseRef = useRef();
  const pointModalClose = () => {
    setPointInitialValue(null);
    form.setFieldsValue({ verificationType: null });
    pointCloseRef.current.click();
  };
  const pointOpenRef = useRef();
  const pointModalOpen = () => pointOpenRef.current.click();

  //Chat Modal Ref and Functions
  const chatCloseRef = useRef();
  const chatModalClose = () => chatCloseRef.current.click();
  const chatOpenRef = useRef();
  const chatModalOpen = () => chatOpenRef.current.click();


  const [socket, setSocket] = useState(null);

  const SOCKET_SERVER_URL = process.env.BASE_API_URL;
  // const socket = io(SOCKET_SERVER_URL, {
  //   transports: ["websocket"],
  //   query: { userId: userData.id },
  // });

  const [chat, setChat] = useState([]);

  useEffect(() => {
    setVerificationList(VerificationTypes);
  }, [VerificationTypes]);

  // Fetch chat messages when component mounts
  useEffect(() => {
    // getChatboxList();
  }, [userData.id]);

  useEffect(() => {
    if (caseId) getCaseDetails();
    else dispatch(resetCaseDetails());
  }, [dispatch]);

  const getCaseDetails = () => {
    const body = {
      case_id: caseId,
    };
    dispatch(fetchCaseDetails(body));
  };

  useEffect(() => {
    // getChatboxList();
  }, []);

  // useEffect(() => {
  //   getPointList({ id: applicantId });
  // }, [applicantId]);

  // useEffect(() => {
  //   filterVerificationList();
  // }, [pointsList]);

  const onFinish = (values) => {
    setPointInitialValue(null);
    verificationClicked();
  };
  //Get Branch List
  const getVerificationList = async () => {
    const body = { branch_id: CaseDetails.branch.id };
    dispatch(fetchVerificationType(body));
  };

  //Get point
  const getPointList = async (data) => {
    const applicant = CaseDetails.applicant_data.find(
      (element) => element.id == data.id
    );
    setApplicantId(applicant.id);
    let pointArray = points.map((point) => applicant[point]).filter(Boolean);
    pointArray = pointArray.map((point) => {
      if (point.length < 1)
        return;

      //-----NOTE----------
      // DO not change the owner_applicant_name this variable name with any other, else it will create a big bug in all the varification points
      // comment by Umang Dev
      //-----NOTE----------
      return point.map((element) => ({
        ...element,
        owner_applicant_name: `${applicant.first_name} ${applicant?.middle_name ? applicant?.middle_name : ''} ${applicant.last_name}`,
      }));
    }).filter(Boolean);
    setPointsList(pointArray.flat());
  };
  // get  fields
  const getFields = async (pin) => {
    const body = {
      pincode: pin,
      vendor_branch_id: CaseDetails.branch.vendor_branch_id.id,
    };
    dispatch(fetchFEList(body));
  };
  // get type list
  const getDocConfigList = async (data) => {
    try {

      // Check if template exists and has values
      if (data.template && Object.keys(data.template).length > 0) {
        // Create a deep copy of the template to avoid modifying the original object
        let clonedTemplate = JSON.parse(JSON.stringify(data.template));

        // Iterate over the cloned template and modify it
        Object.entries(clonedTemplate).forEach(([key, value]) => {
          clonedTemplate[key] = value.map((ele) => {
            if (ele.fieldType === "File" && ele.fieldValue) {
              const UrlArray = ele.fieldValue.map((url) => (`${CaseDetails.base_url}/uploads/points_attachment/${url}`));
              return {
                ...ele,
                fieldValue: createUrlObjForMultiple(ele.fieldName, UrlArray)
              };
            } else {
              return ele;
            }
          });
        });

        data.template = clonedTemplate;
      }


      const response = await api.post(API_URL.DOC_CONFIG, { type: data.verification_type, branch_id: CaseDetails.branch_id }, token);

      if (response.status === 200) {
        const formData = {
          applicant_id: applicantId,
          case_id: caseId,
          type: data.verification_type,
          getData: data.template && Object.keys(data.template).length > 0 ? data : response.result.getData,
          pointData: pointsList.find((point) => point.verification_type == response.result.getData.points_id),
        };
        openPointsForm(formData);
      } else {
        console.error("Failed to fetch Fields list");
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const onApplicantEditModal = async (data) => {
    setApplicantInitialValue({
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      mobile_no: data.mobile_no,
      mobile_no_2: data.mobile_no_2,
      res_no: data.res_no,
      office_no: data.office_no,
      type: data.type,
      applicantId: data.id,
    });
    setApplicantId(data.id);
    applicantModalOpen();
  };

  //ADD NEW APPLICANT
  const CreateApplicant = async (values) => {
    const body = {
      branch_id: CaseDetails.branch_id,
      case_id: CaseDetails.id,
      received_at: dayjs(CaseDetails.received_at),
      applicant_id: values.applicantId || null,
      type: values.type,
      name: values.first_name + " " + values.last_name || "",
      first_name: values.first_name || "",
      middle_name: values.middle_name || "",
      last_name: values.last_name || "",
      mobile_no: values.mobile_no,
      mobile_no_2: values.mobile_no_2,
      res_no: values.res_no,
      office_no: values.office_no,
      user_id: userData.id,
    };

    const formData = new FormData();

    Object.keys(body).forEach((key) => {
      if (validateStr(body[key]))
        formData.append(key, body[key]);
    });

    const isUpdate = false;
    dispatch(createOrUpdateCase({ formData, isUpdate }))
      .unwrap()
      .then((response) => {
        getCaseDetails();
        applicantModalClose();
      })
      .catch((error) => {
        console.error("Error creating/updating client:", error);
      });
  };
  //Contains the Verification Form Configs
  const verificationForms = {
    [VerList.Partial_Disbursement]: PartialDisbursementVerFormConfig,
    [VerList.Pool_Purchase]: PoolPurchaseVerFormConfig,
    [VerList.BRT]: BRTVerificationFormConfig,
    [VerList.BuilderVerification]: BuilderVerFormConfig,
    [VerList.IdentityVerification_PAN]: IdentityVerPANFormConfig,
    [VerList.IdentityVerification_AADHAR]: IdentityVerAadharFormConfig,
    [VerList.Employer_Verification]: EmployerVerFormConfig,
    [VerList.Car_Dealer_Verification]: CarDealerVerFormConfig,
    [VerList.Quotation_Verification]: QuotationVerFormConfig,
    [VerList.Balance_Sheet_Financials]: FinancialsVerFormConfig,
    [VerList.Tele_Verification_Report]: TeleVerificationReportFormConfig,
    [VerList.Shop_Act_License_Verification]: ShopActLicenseVerFormConfig,
    [VerList.Udyam_Registration_Certificate]: UdyamRegistrationVerFormConfig,
    [VerList.GST_Returns]: GSTReturnsVerificationFormConfig,
    [VerList.GSTN_Certificate]: GSTNVerificationFormConfig,
    [VerList.Form_26_AS]: Form_26FormConfig,
    [VerList.Form_16]: Form_16FormConfig,
    [VerList.Bank_Statement_Verification]: BankStatementVerificationFormConfig,
    [VerList.IT_Returns_Verification]: ITReturnVerificationFormConfig,
    [VerList.Payslip_Verification]: PaySlipVerificationFormConfig,
    [VerList.Business_Verification]: BusinessVerificationFormConfig,
    [VerList.Employment_Verification]: EmployementVerificationFormConfig,
    [VerList.Residence_Verification]: ResidenceVerificationFormConfig,
  };
  const handlePointFieldChange = (changedValues) => {
    if (changedValues.pincode) {
      if (String(changedValues.pincode).length === 6) {
        getFields(changedValues.pincode);
      } else {
        dispatch(resetFEList());
      }
    }
    setPointInitialValue((prev) => {
      return { ...prev, ...changedValues };
    });
  };
  //Renders the Verification Form
  const renderVerificationForm = (config) => {
    return (
      <div className="mt-3">
        <DynamicForm
          config={config}
          initialValues={pointInitialValue}
          handleFieldChange={handlePointFieldChange}
          onFinish={handleAddUpdatePoint}
          onCancel={pointModalClose}
        />
      </div>
    );
  };
  //Desides and Returns the Veirfication Form
  const commonFEFields = [
    {
      label: "Pincode",
      name: "pincode",
      type: "input",
      rules: [
        {
          pattern: /^[0-9]{6}$/,
          message: "Pincode must be exactly 6 digits",
        },
      ],
      colSpan: 12,
    },
    {
      label: "Assign",
      name: "assign_id",
      type: "select",
      options:
        FEList.length > 0
          ? FEList.map((element) => ({
            value: element.id,
            label: `${element.first_name} ${element.middle_name ? element.middle_name : ""
              } ${element.last_name ? element.last_name : ""} (${titleList[element.role - 1]
              })`,
          }))
          : [],
      colSpan: 12,
    },
  ];
  const verificationClicked = () => {
    let formConfig = verificationForms[verificationType];
    if (
      [
        VerList.Residence_Verification,
        VerList.Employment_Verification,
        VerList.Business_Verification,
        VerList.Payslip_Verification,
        VerList.Bank_Statement_Verification,
        VerList.Form_16,
        VerList.Shop_Act_License_Verification,
        VerList.Quotation_Verification,
        VerList.Car_Dealer_Verification,
        VerList.Employer_Verification,
        VerList.IdentityVerification_AADHAR,
        VerList.IdentityVerification_PAN,
        VerList.BuilderVerification,
        VerList.BRT,
        VerList.Pool_Purchase,
        VerList.Partial_Disbursement,
      ].includes(parseInt(verificationType))
    )
      formConfig = [...formConfig, ...commonFEFields];

    if (formConfig) {
      formConfig = [...formConfig, ...newObjects];
      if (!pointInitialValue?.point_id)
        formConfig = formConfig.filter((element) => (element.name != "status"))
      return renderVerificationForm(formConfig);
    }
    return null;
  };


  //add point
  const onaddPoint = async (data) => {
    form.setFieldsValue({ verificationType: null });
    pointModalOpen();
    setPointInitialValue(null);
    getVerificationList();
    // filterVerificationList();
    setisPointUpdate(false);
    setVerificationType("");
    setApplicantId(data.id);
  };

  //add Point Details
  const openPointsForm = (data) => {
    if (userData.role === Role.SUPER_ADMIN) {
      navigate("/super-admin/case/addpointform", { state: data });
    } else if (
      userData.role === Role.BANKS ||
      userData.role === Role.MASTER_VENDOR
    ) {
      navigate("/admin/case/addpointform", { state: data });
    } else if (
      userData.role === Role.BRANCH_MANAGER ||
      userData.role === Role.TEAM_LEAD ||
      userData.role === Role.BACK_OFFICE
    ) {
      navigate("/user/case/addpointform", { state: data });
    } else {
      navigate("/login");
    }
  };

  const handleAddUpdatePoint = async (data) => {
    const body = {
      case_id: JSON.parse(caseId),
      applicant_id: JSON.parse(applicantId),
      verification_type: JSON.parse(verificationType),
      type: JSON.parse(verificationType),
      assign_id: data.assign_id,
      user_id:
        data.assign_id === "" ? userData.parent_id : data.assign_id,
      ...data,
    };
    let isUpdate;
    if (body.point_id) {
      isUpdate = true;
      body.point_id = JSON.parse(data.point_id);
    } else {
      body.status = POINT_STATUS_ENUM.PENDING;
    }
    const formData = new FormData();
    const appendFile = async (key, file) => {
      if (file) {
        formData.append(key, file[0].originFileObj);
      }
    };

    Object.keys(body).forEach((key) => {
      if (key == "attachment" && validateStr(body[key])) {
        appendFile("attachment", body[key] ?? "");
      }
      else if (validateStr(body[key]))
        formData.append(key, body[key]);
    });

    dispatch(createOrUpdatePoint({ formData, isUpdate }))
      .unwrap()
      .then((response) => {
        pointModalClose();
        getCaseDetails();
        applicantModalClose();
        getPointList({ id: applicantId });
      })
      .catch((error) => {
        console.error("Error creating/updating client:", error);
      });
  };

  const convertDateFields = (data) => {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(value)) {
        data[key] = dayjs(value);
      }
    });
    return data;
  };

  //update point
  const onUpdatePoint = (data) => {
    setisPointUpdate(true);
    getVerificationList();
    // filterVerificationList();
    const perticularPointsValue = {
      ...convertDateFields(data),
      attachment: data.attachment ? createUrlObj("attachment", data?.attachment) : undefined,
      pincode: JSON.stringify(data?.pincode),
      point_id: data.id,
      assign_id: data.assign_id ? data.assign_id : null,
      // ...Object.fromEntries(Object.entries(data).filter(([key, value]) => typeof value === 'string' && !isNaN(Date.parse(value))).map(([key, value]) => [key, dayjs(value)])),
    }

    setPointInitialValue(perticularPointsValue);
    getFields(data.pincode);
    form.setFieldsValue({
      verificationType: originalVerificationList.find(
        (element) => element.id == data.verification_type
      ).name,
    });
    setVerificationType(data.verification_type);
    verificationClicked();
    pointModalOpen();
  };

  //Delete the case
  const handleDeletePoint = async (point) => {
    Swal.fire({
      title: "Delete",
      text: `Are you sure you want to delete point?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = {
          verification_type: JSON.parse(point.verification_type),
          point_id: point.id,
          applicant_id: applicantId,
        };
        dispatch(deletePoint(body))
          .unwrap()
          .then(() => {
            getCaseDetails();
            getPointList({ id: applicantId });
          })
          .catch((err) => { });
      }
    });
  };

  //Delete the applicant
  const handleDeleteApplicant = async (applicant, forceDelete = false) => {
    const confirmationMessage = forceDelete
      ? "Applicant has Points, Do you still want to delete?"
      : "Are you sure you want to delete this applicant?";

    await triggerConfirmation(
      "Delete",
      confirmationMessage,
      "warning",
      "Delete"
    ).then(async (result) => {
      const body = {
        applicant_id: parseInt(applicant.id),
        force_delete: forceDelete,
      };
      dispatch(deleteApplicant(body))
        .unwrap()
        .then(() => {
          getCaseDetails();
          setPointsList([]);
        })
        .catch((err) => {
          if (err.endsWith("Can Not Be Deleted")) {
            return;
          }
          handleDeleteApplicant(applicant, true);
        });
    });
  };

  //DO NOT DELETE ________---------------_____----___-----__----
  //chatbox get message
  // const getChatboxList = async () => {
  //   try {
  //     const body = {
  //       to_id: 9, // Replace with actual recipient ID
  //       from_id: userData.id,
  //     };

  //     const response = await api.post(API_URL.GET_CHAT, body, token);

  //     if (response.status === 200) {
  //       setChat(response.result.messages);
  //     } else {
  //       console.error("Failed to fetch chat list");
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  //DO NOT DELETE ________---------------_____----___-----__----
  // const sendMessage = async () => {
  //   if (currentMessage !== "") {
  //     const messageData = {
  //       Authorization: "Bearer " + token, // Adjust token logic as needed
  //       from_id: userData.id,
  //       to_id: "9", // Adjust recipient ID as needed
  //       message: currentMessage,
  //       client_id: new Date().getTime(),
  //     };
  //     setChat((prevChat) => [...prevChat, messageData]);
  //     if (socket) {
  //       const response = await api.post(
  //         API_URL.SEND_MESSAGE,
  //         messageData,
  //         token
  //       );
  //       socket.emit("chat_message", messageData); // Emit message
  //       setCurrentMessage("");
  //     }
  //   }
  // };
  //handleclick
  const handleClick = (e) => {
    e.preventDefault();
    // sendMessage();
  };

  const viewPdf = (point) => {
    const data = {
      ...point,
      case_id: caseId,
    };
    if (userData.role === Role.SUPER_ADMIN)
      navigate("/super-admin/pdfpage", { state: data });
    else if (
      userData.role === Role.BANKS ||
      userData.role === Role.MASTER_VENDOR
    )
      navigate("/admin/pdfpage", { state: data });
    else if (
      userData.role === Role.BRANCH_MANAGER ||
      userData.role === Role.TEAM_LEAD ||
      userData.role === Role.BACK_OFFICE
    )
      navigate("/user/pdfpage", { state: data });
    else navigate("/login");
  };

  //Applicant Table Columns
  const applicantTableColumns = [
    {
      title: "#",
      key: "srNo",
      render: (_, __, index) => index + 1, // Renders the row number
      className: "dt-left",
      width: "5%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "dt-left",
      render: (_, record) =>
        `${record
          ? `${record.first_name} ${record.middle_name ? record.middle_name : ""
          } ${record.last_name}`
          : ""
        }`,
      width: "20%",
    },
    {
      title: "Mobile No",
      dataIndex: "mobile_no",
      key: "mobileNo",
      className: "dt-left",
      render: (mobile_no) => mobile_no || "-", // Render mobile number or "-"
      width: "15%",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      className: "dt-left",
      render: (type) => {
        switch (type) {
          case 1:
            return "Applicant";
          case 2:
            return "Co-Applicant";
          case 3:
            return "Guarantor";
          default:
            return "-";
        }
      },
      width: "15%",
    },
    {
      title: "Total Point",
      dataIndex: "totalPoint",
      key: "totalPoint",
      className: "dt-left",
      render: (_, record) => {
        return points.map((point) => record[point]).flat().filter(Boolean).length;
      },
      width: "15%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "dt-left",
      render: (_, record) => {
        return APPLICANT_STATUS_TEXT[record.status];
      },
      width: "15%",
    },
    {
      title: "Action",
      key: "action",
      className: "dt-center",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          {userData.role === Role.BACK_OFFICE && (
            <Tooltip title="Chat">
              <CommentOutlined
                style={{ color: "#000", cursor: "pointer" }}
              // onClick={() => handleChat(record)} // Implement handleChat function
              />
            </Tooltip>
          )}
          <Tooltip title="Add">
            <PlusOutlined
              style={{ color: "#000", cursor: "pointer" }}
              onClick={() => onaddPoint(record)} // Implement openAddModal function
            />
          </Tooltip>
          <Tooltip title="Edit">
            <EditOutlined
              style={{ color: "#000", cursor: "pointer" }}
              onClick={() => onApplicantEditModal(record)} // Implement openEditModal function
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined
              style={{ color: "#D92E38", cursor: "pointer" }}
              onClick={() => handleDeleteApplicant(record)} // Implement deleteRecord function
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  //Point Table Columns
  const pointTableColumn = [
    {
      title: "#",
      key: "srNo",
      render: (_, __, index) => index + 1, // Renders the row number
      className: "dt-left",
      width: "5%",
    },
    {
      title: "Name",
      key: "owner_applicant_name",
      dataIndex: "owner_applicant_name",
      className: "dt-left",
      width: "20%",
    },
    {
      title: "Point",
      dataIndex: "verification_type",
      key: "verification_type",
      className: "dt-left",
      render: (type) => {
        switch (type) {
          case 1:
            return "Residence Verification";
          case 2:
            return "Employment Verification";
          case 3:
            return "Business Verification";
          case 4:
            return "Payslip Verification";
          case 5:
            return "IT Returns Verification";
          case 6:
            return "Bank Statement Verification";
          case 7:
            return "Form 16";
          case 8:
            return "Form 26 AS";
          case 9:
            return "GSTN Certificate";
          case 10:
            return "GST Returns";
          case 11:
            return "Udyam Registration Certificate";
          case 12:
            return "Shop Act License Verification";
          case 13:
            return "Tele Verification Report";
          case 14:
            return "Balance Sheet/Financials";
          case 15:
            return "Quotation Verification";
          case 16:
            return "Car Dealer Verification";
          case 17:
            return "Employer Verification";
          case 18:
            return "Identity Verification AADHAR";
          case 19:
            return "Identity Verification PAN";
          case 20:
            return "Builder Verification";
          case 21:
            return "BRT";
          case 22:
            return "Pool Purchase";
          case 23:
            return "Partial Disbursement";
          default:
            return "-";
        }
      },
      width: "15%",
    },

    {
      title: "Assign",
      dataIndex: "assign",
      key: "user",
      className: "dt-left",
      render: (user) => (user != null ? user.first_name : "Pending"),
      width: "15%",
    },
    {
      title: "Completed By",
      key: "user",
      className: "dt-left",
      render: (_) => "Pending",
      width: "15%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "dt-left",
      render: (_, record) => {
        return POINT_STATUS_TEXT[record.status];
      },
      width: "15%",
    },
    {
      title: "Action",
      key: "action",
      className: "dt-center",
      width: "15%",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="View">
            <EyeOutlined
              style={{ color: "#2e4fd9", cursor: "pointer" }}
              onClick={() => viewPdf(record)}
            />
          </Tooltip>
          <Tooltip title="Add">
            <PlusOutlined
              style={{ color: "#000", cursor: "pointer" }}
              onClick={() => getDocConfigList(record)}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <EditOutlined
              style={{ color: "#000", cursor: "pointer" }}
              onClick={() => onUpdatePoint(record)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined
              style={{ color: "#D92E38", cursor: "pointer" }}
              onClick={() => handleDeletePoint(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];


  //DO NOT DELETE ________---------------_____----___-----__----
  // const socket1 = io(SOCKET_SERVER_URL, {
  //   transports: ["websocket"],
  //   query: { userId: userData.id }, // Pass the user ID to the server
  // });

  // useEffect(() => {
  //   const socketInstance = io(SOCKET_SERVER_URL, {
  //     transports: ["websocket"],
  //     query: { userId: userData.id }, // Pass the user ID to the server
  //   });

  //   socketInstance.on("connect", () => {
  //     console.log("Connected to the server");
  //   });

  //   socket1.on("chat_message", (data) => {
  //     console.log("Received message:", data);
  //     setChat((prevChat) => {
  //       const updatedChat = [...prevChat, data];
  //       console.log("Updated chat state:", updatedChat);
  //       return updatedChat;
  //     });
  //   });

  //   setSocket(socketInstance);

  //   return () => {
  //     socketInstance.disconnect();
  //     console.log("Socket disconnected");
  //   };
  // }, [userData]);

  //Applicant Form Config
  const applicantFormConfig = [
    {
      label: "Type",
      name: "type",
      type: "select",
      options: [
        { value: 1, label: "Applicant" },
        { value: 2, label: "Co-Applicant" },
        { value: 3, label: "Guarantor" },
      ],
      rules: [{ required: true, message: "Please Select One Option" }],
      colSpan: 12,
    },
    {
      label: "First Name",
      name: "first_name",
      type: "input",
      rules: [{ required: true, message: "Please Enter First Name" }],
      colSpan: 12,
    },
    {
      label: "Middle Name",
      name: "middle_name",
      type: "input",
      colSpan: 12,
    },
    {
      label: "Last Name",
      name: "last_name",
      type: "input",
      rules: [{ required: true, message: "Please Enter Last Name" }],
      colSpan: 12,
    },
    {
      label: "Mobile 1",
      name: "mobile_no",
      type: "number-input",
      rules: [
        { required: true, message: "Please Enter Mobile 1" },
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 12,
      maxLength: 10
    },
    {
      label: "Mobile 2",
      name: "mobile_no_2",
      type: "number-input",
      rules: [
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 12,
      maxLength: 10
    },
    {
      label: "Resident Mobile Number",
      name: "res_no",
      type: "number-input",
      rules: [
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 12,
      maxLength: 10
    },
    {
      label: "Office",
      name: "office_no",
      type: "number-input",
      rules: [
        {
          pattern: /^[0-9]{10}$/,
          message: "Phone number must be exactly 10 digits",
        },
      ],
      colSpan: 12,
      maxLength: 10
    },
    {
      label: "applicantId",
      name: "applicantId",
      type: "hidden",
      initialValue: null,
    },
  ];
  //Handle Applicant Field Changes
  const handleApplicantFieldChange = (changedValues) => {
    setApplicantInitialValue((prev) => {
      return { ...prev, ...changedValues };
    });
  };
  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5>Case Information</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="button-box">
                  <ul className="">
                    <li>
                      <Link
                        to="/"
                        className="blue-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#ApplicantModal"
                      >
                        + Add Applicant
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <CaseDetailsPage CaseDetails={CaseDetails} />
          <section id="add-new-case">
            <div className="row">
              {/* left table  */}
              <div className="col-md-12">
                <div className="table-responsive left rounded-table">
                  <AntdTable
                    showPagination={
                      CaseDetails.applicant_data?.length > 5 ? true : false
                    }
                    pageNo={pageNo}
                    pageSize={pageSize}
                    setPageNo={setPageNo}
                    setPageSize={setPageSize}
                    total={5}
                    dataSource={CaseDetails.applicant_data}
                    columns={applicantTableColumns}
                    onRowClick={getPointList}
                  />
                </div>
              </div>
            </div>
            {pointsList.length > 0 ? (
              <div className="row">
                {/* right table  */}
                <div className="col-md-12">
                  <div className="table-responsive right rounded-table">
                    <AntdTable
                      showPagination={false}
                      dataSource={pointsList}
                      columns={pointTableColumn}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </section>

          {/* Add/Edit Points Modal Start*/}
          <div
            className="modal fade"
            id="PointsModal"
            tabIndex={-1}
            aria-labelledby="PointsModal"
            data-bs-backdrop="static"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-xl">
              {/* Modal content*/}
              <div className="modal-content">
                {/* modal header  */}
                <div className="modal-header">
                  <h5 className="text-center">
                    {isPointUpdate === true ? "Edit " : "Add "}Points
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={pointCloseRef}
                  />
                </div>
                <div className="sub-header">
                  <h6>Verification Point</h6>
                </div>
                {/* modal body  */}
                <div className="modal-body">
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{ verificationType }}
                  >
                    <Row gutter={[16, 24]}>
                      <Col span={24}>
                        <Form.Item
                          name="verificationType"
                          label="Type"
                          rules={[
                            {
                              required: true,
                              message: "Please select an option",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select Option"
                            disabled={isPointUpdate}
                            onChange={(val) => setVerificationType(val)}
                            allowClear
                            showSearch={true}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {verificationList?.length > 0 &&
                              verificationList.map((obj) => (
                                <Option key={obj.id} value={obj.id}>
                                  {obj.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      {/* <Col span={6} className="pt-3 d-flex align-items-end">
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="submit-btn"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Col> */}
                    </Row>
                  </Form>
                  {verificationClicked()}
                </div>
              </div>
            </div>
            {/* Add/Edit Points Modal End*/}
          </div>

          {/* Add/Edit Applicant modal  Start*/}
          <div
            className="modal fade"
            id="ApplicantModal"
            tabIndex={-1}
            aria-labelledby="ApplicantModal"
            data-bs-backdrop="static"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              {/* Modal content*/}
              <div className="modal-content">
                {/* modal header  */}
                <div className="modal-header">
                  <h5 className="text-center">Add/Edit Applicant</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      applicantModalClose();
                    }}
                    ref={applicantCloseRef}
                  />
                </div>
                <div className="sub-header">
                  <h6>Applicant</h6>
                </div>
                {/* modal body  */}
                <div className="modal-body">
                  <DynamicForm
                    config={applicantFormConfig}
                    initialValues={applicantInitialValue}
                    handleFieldChange={handleApplicantFieldChange}
                    onFinish={CreateApplicant}
                    onCancel={applicantModalClose}
                  />
                </div>
              </div>
            </div>
            {/* Add/Edit Applicant modal End */}
          </div>

          {/* Chat Modal Start*/}
          <div
            className="modal fade"
            id="ChatModal"
            tabIndex={-1}
            aria-labelledby="ChatModal"
            data-bs-backdrop="static"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-lg">
              {/* Modal content*/}
              <div div className="modal-content">
                {/* modal header  */}
                <div className="modal-header">
                  <h5 className="text-center">Chat with FE/PE/Branch</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={chatCloseRef}
                  />
                </div>
                <div className="sub-header profile-header">
                  <div className="profile-img-box">
                    <img
                      src={profile2}
                      alt="profile2"
                      style={{ height: 50, width: 50 }}
                    />
                  </div>
                  <h6>Avinash Parera</h6>
                </div>

                {/* modal body  */}
                <div className="modal-body">
                  {/* {error && <div className="error-message">{error}</div>} */}
                  <section className="msger">
                    <main className="msger-chat">
                      {chat.length > 0 ? (
                        chat.map((message, index) => (
                          <div
                            key={index}
                            className={
                              message.from_id === userData.id
                                ? "msg right-msg"
                                : "msg left-msg"
                            }
                          >
                            <div className="msg-bubble">
                              <div className="msg-text">{message.message}</div>
                              <div className="msg-info">
                                <div className="msg-info-time">
                                  {message.time}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>No messages available</div>
                      )}
                    </main>

                    <form className="msger-inputarea">
                      <input
                        type="text"
                        className="msger-input"
                        placeholder="Text type here...."
                        value={currentMessage}
                        onChange={(event) => {
                          setCurrentMessage(event.target.value);
                        }}
                      />
                      <button
                        type="submit"
                        className="msger-send-btn"
                        onClick={handleClick}
                      >
                        <img src={send} alt="Send" />
                      </button>
                    </form>
                  </section>
                </div>
              </div>
            </div>
            {/* Chat Modal End*/}
          </div>
        </div>
      </div>
      <div
        href="/"
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#ApplicantModal"
        ref={applicantOpenRef}
      ></div>
      <div
        href="/"
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#PointsModal"
        ref={pointOpenRef}
      ></div>
      <div
        href="/"
        className="d-none"
        data-bs-toggle="modal"
        data-bs-target="#ChatModal"
        ref={chatOpenRef}
      ></div>
    </div>
  );
}
export default AddNewCase;
