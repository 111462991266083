import React, { useState } from "react";
import { Form, Input, Checkbox, Button, Alert } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useToast, validateEmail } from "../../utils/helpers/CommFun";
import api from "../../services/Api";
import loginBg from "../../assets/images/login-bg.png";
import { API_URL, Role } from "../../utils/constant/Constant";
import logo from "../../assets/images/logo.png";
import logoSm from "../../assets/images/logo-sm.png";
import { useDispatch } from "react-redux";
import { login, fetchUserData } from "../../redux/slices/authSlice";
const Login = () => {
  const [form] = Form.useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();
  const Toast = useToast();
  const dispatch = useDispatch();

  const handleLogin = async (values) => {
    const { email, password } = values;
    try {
      if (!email.trim()) {
        Toast.fire({
          icon: "warning",
          title: "Please enter your email",
        });
        return;
      } else if (!validateEmail(email)) {
        Toast.fire({
          icon: "warning",
          title: "Please enter a valid email",
        });
        return;
      } else if (!password.trim()) {
        Toast.fire({
          icon: "warning",
          title: "Please enter your password",
        });
        return;
      }

      const body = {
        email,
        password,
      };

      const deviceToken = localStorage.getItem("deviceToken") || null;
      if (deviceToken) {
        body.device_token = deviceToken;
        body.device_type = 0;
      }
      let user;
      dispatch(login(body))
        .unwrap()
        .then(async (response) => {
          user = response;
          if (user.status === 200) {
            Toast.fire({
              icon: "success",
              title: user.message,
            });

            const userId = user.result.id;
            if (user.result.is_sys_password === 1) {
              navigate("/password", { state: { user_id: userId } });
            } else {
              const body = { user_id: user.result.id };
              dispatch(fetchUserData(body));
              switch (user.result.role) {
                case Role.SUPER_ADMIN:
                  navigate("/super-admin/dashboard");
                  break;
                case Role.BANKS:
                case Role.MASTER_VENDOR:
                  navigate("/admin/dashboard");
                  break;
                case Role.BRANCH_MANAGER:
                case Role.TEAM_LEAD:
                case Role.BACK_OFFICE:
                  navigate("/user/dashboard");
                  break;
                default:
                  navigate("/login");
                  break;
              }
            }
          } else {
            Toast.fire({
              icon: "error",
              title: user.message,
            });
          }
        })
        .catch((error) => {});
    } catch (error) {
      console.error("Error logging in:", error);
      Toast.fire({
        icon: "warning",
        title: "Invalid Username or password",
      });
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <section id="login" className="com-form">
      <div className="login-part">
        <div className="container-fluid">
          <div className="login-box">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="left">
                  <div className="img-box">
                    <img src={loginBg} alt="Login Background" />
                    <div className="text-box">
                      <p>Nice to see you again</p>
                      <h3>Welcome Back</h3>
                      <div className="line"></div>
                      <h4>Login Now</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right-box">
                  <div className="login-box">
                    <div className="form-box">
                      <div className="logo-box">
                        <img src={logo} alt="logo" />
                      </div>
                      <Form
                        form={form}
                        onFinish={handleLogin}
                        layout="vertical"
                      >
                        <Form.Item
                          label="User Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your email!",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            maxLength={30}
                            placeholder="Enter User Email"
                            autoComplete="off"
                            className="form-custom-control"
                          />
                        </Form.Item>

                        <Form.Item
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your password!",
                            },
                          ]}
                        >
                          <Input.Password
                            maxLength={50}
                            placeholder="Enter password"
                            iconRender={(visible) =>
                              visible ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                            className="form-custom-control"
                          />
                        </Form.Item>

                        <div className="Remember-box">
                          <Form.Item>
                            <Checkbox className="form-check-label">
                              Keep me sign in
                            </Checkbox>
                            <Link
                              to="/reset_password"
                              style={{
                                float: "right",
                                marginTop: "8px",
                                color: "blue",
                                fontSize: "16px",
                              }}
                              className="forget-link"
                            >
                              Forgot Password?
                            </Link>
                          </Form.Item>
                        </div>
                        <Form.Item>
                          <Button htmlType="submit" className="btns submit-btn">
                            Login
                          </Button>
                        </Form.Item>
                      </Form>

                      {/*  <form onSubmit={handleLogin}>
                        <div className="input-box">
                          <label htmlFor="username" className="form-label">
                            User Email
                          </label>
                          <input
                            type="text"
                            id="username"
                            className="form-control"
                            maxLength={30}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            autoComplete="off"
                            placeholder="Enter User Email"
                          />
                        </div>
                        <div className="input-box">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <div className="pass">
                            <input
                              type={passwordVisible ? 'text' : 'password'}
                              id="password"
                              className="form-control"
                              maxLength={50}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                              placeholder="Enter password"
                            />
                            <i className="toggle-password fa-solid fa-eye-slash" />
                            <i
                              className={`toggle-password fas ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'}`}
                              onClick={togglePasswordVisibility}
                            />
                          </div>
                        </div>
                        <div className="input-box Remember-box">
                          <div className="Remember">
                            <input class="form-check-input" type="checkbox" value="" id=""/>
                            <label class="form-check-label" for="">
                            Remember me
                            </label>
                          </div>
                          <div className="forget">
                          <Link to="/reset_password" className='forget-link'>
                            Forgot Password?
                          </Link>
                          </div>
                        </div>
                        {error && <div className="error">{error}</div>}
                        <div className="button-box">
                          <button type="submit" className="btns submit-btn">
                            Login
                          </button>
                        </div>
                      </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
