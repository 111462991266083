import React, { useEffect, useState } from "react";
import DynamicForm from "../../../components/DynamicForm/DynamicForm";
import { daysOfWeek } from "../../../utils/constant/Constant";
import dayjs from "dayjs";
import { addMasterBranchHours } from "../../../redux/slices/vendorBranchSlice";
import { useDispatch } from "react-redux";

const BranchHourForm = ({ setActiveKey, formValues, FetchBranchDetails }) => {
  const dispatch = useDispatch();

  const [initialValue, setInitialValue] = useState();

  const generateDayConfig = (day, initialValue) => [
    {
      label: "Opens At",
      name: `${day}_open_time`,
      type: "time",
      colSpan: 11,
      rules: [
        initialValue?.[`${day}_close`]
          ? {}
          : {
              required: true,
              message: `Please Enter value of ${day} Open Time`,
            },
      ],
      disabled: initialValue?.[`${day}_close`],
    },
    {
      label: "Closes At",
      name: `${day}_close_time`,
      type: "time",
      colSpan: 11,
      rules: [
        initialValue?.[`${day}_close`]
          ? {}
          : {
              required: true,
              message: `Please Enter value of ${day} Close Time`,
            },
      ],
      disabled: initialValue?.[`${day}_close`],
    },
    {
      label: "Closed",
      name: `${day}_close`,
      type: "checkbox",
      colSpan: 2,
    },
  ];

  const hoursFormConfig = daysOfWeek.flatMap((day) =>
    generateDayConfig(day, initialValue)
  );
  function extractTime(dateString) {
    // Create a new Date object from the date string
    const date = new Date(dateString);

    // Extract hours and minutes
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    // Format time as HH:mm
    return `${hours}:${minutes}`;
  }

  const formatData = (Value) => {
    return daysOfWeek.map((day, index) => ({
      day_type: index + 1,
      is_open_time: Value[`${day}_open_time`]
        ? extractTime(Value[`${day}_open_time`])
        : null,
      is_close_time: Value[`${day}_close_time`]
        ? extractTime(Value[`${day}_close_time`])
        : null,
      is_closed: Value[`${day}_close`] ? 1 : 0, // Convert true to 1, false to 0
    }));
  };

  useEffect(() => {
    const formattedData = formValues?.working_hours.reduce((acc, current) => {
      const day = daysOfWeek[current.day_type - 1];

      // Convert the time string to a dayjs object
      acc[`${day}_open_time`] = current.is_open_time
        ? dayjs(current.is_open_time, "HH:mm")
        : null;
      acc[`${day}_close_time`] = current.is_close_time
        ? dayjs(current.is_close_time, "HH:mm")
        : null;
      acc[`${day}_close`] = current.is_closed === 1;

      return acc;
    }, {});

    setInitialValue(formattedData);
  }, [formValues]);

  //HANDLE FIELD CHANGE
  const handleFieldChange = (changedValues) => {
    for (const key in changedValues) {
      if (key.endsWith('_close') && changedValues[key]) {
        const day = key.split('_')[0];
        changedValues[`${day}_open_time`] = null;
        changedValues[`${day}_close_time`] = null;
      }
    }

    setInitialValue((prev) => ({ ...prev, ...changedValues }));
  };
  const addHours = async (values) => {
    const body = {
      working_hours: formatData(values),
      branch_id: parseInt(formValues.branchId),
    };
    dispatch(addMasterBranchHours({ body, isUpdate: true }))
      .unwrap()
      .then((response) => {
        FetchBranchDetails();
        setActiveKey("1");
      })
      .catch((error) => {});
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div
          className="d-flex flex-column mt-2 mx-2 fs-5 fw-semibold"
          style={{ rowGap: "62px" }}
        >
          <div>Monday</div>
          <div>Tuesday</div>
          <div>Wednesday</div>
          <div>Thursday</div>
          <div>Friday</div>
          <div>Saturday</div>
          <div>Sunday</div>
        </div>
        <div>
          <DynamicForm
            key={initialValue}
            initialValues={initialValue}
            config={hoursFormConfig}
            handleFieldChange={handleFieldChange}
            onFinish={(data) => addHours(data)}
            onCancel={() => setActiveKey("3")}
            submitText="Submit"
          />
        </div>
      </div>
    </>
  );
};

export default BranchHourForm;
