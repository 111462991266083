import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import TopLoadingBar from "../TopLoadingBar/TopLoadingBar";

const Layout = () => {
  
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <section id="main-box">
      <div className="container-box">
        <Sidebar collapsed={collapsed} />
        <div className="right-sidebar">
          <Header collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
          <div>
            {/* <TopLoadingBar isVisible={isLoading} progress={progress} /> */}
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Layout;
