import React, { useState, useEffect, useMemo } from "react";
import { Select, Table, Input, message, DatePicker } from "antd";
import { useNavigate } from "react-router-dom";
import api from "../../services/Api";
import { API_URL } from "../../utils/constant/Constant";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllTargets,
  createOrUpdateTarget,
} from "../../redux/slices/targetSlice";
import dayjs from "dayjs";

// const { Option } = Select;

function AddNewTarget() {
  const [clientList, setClientList] = useState([]);
  const [val, setVal] = useState([]);
  const { targets } = useSelector((state) => state.target);
  const [selectedMonth, setSelectedMonth] = useState("August");
  const [monthsArray, setMonthsArray] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [masterBranchList, setMasterBranchList] = useState([]);
  const [currentEditedBranch, setCurrentEditedBranch] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData, token } = useSelector((state) => state.auth);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getPreviousMonth = (month) => {
    const index = months.indexOf(month);
    return index > 0 ? months[index - 1] : months[months.length - 1];
  };

  const getMonthTwoBefore = (month) => {
    const index = months.indexOf(month);
    return index >= 2 ? months[index - 2] : months[(index - 2 + 12) % 12];
  };

  useEffect(() => {
    const previousMonth = getPreviousMonth(selectedMonth);
    const monthTwoBefore = getMonthTwoBefore(selectedMonth);
    setMonthsArray([selectedMonth, previousMonth, monthTwoBefore]);

    const initialData = masterBranchList.map((branch) => ({
      key: branch.value,
      name: branch.label,
      [selectedMonth]: { editable: "5000", nonEditable: "-7000" },
      [previousMonth]: "9000-10000",
      [monthTwoBefore]: "7000-8000",
    }));
    setVal(initialData);
  }, [selectedMonth, masterBranchList]);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const body = { user_id: userData?.id };
        const response = await api.post(API_URL.GET_ALL_TARGET, body, token);
        if (response.status === 200) {
          setClientList(
            response.result.map((client) => ({
              value: client?.client_id,
              label: client?.client_name,
            }))
          );
        } else {
          message.error("Failed to fetch client list");
        }
      } catch (error) {
        message.error("Error fetching client list");
        console.error("Error fetching client list:", error);
      }
    };

    fetchClientData();
  }, [userData.id, token]);

  useEffect(() => {
    if (clientId) {
      getMasterBranchList();
    }
  }, [clientId]);

  useEffect(() => {
    if (clientId && masterBranchList.length > 0) {
      const body = {
        month: monthsArray.map((e) => months.indexOf(e) + 1),
        client_id: clientId,
      };
      dispatch(fetchAllTargets(body))
        .unwrap()
        .catch((error) => {
          message.error("Failed to fetch targets");
          console.error("Failed to fetch targets:", error);
        });
    }
  }, [clientId, monthsArray, dispatch, masterBranchList]);

  const handleInputChange = (value, key, month) => {
    const isValid = value && !isNaN(value) && parseFloat(value) >= 0;
    setCurrentEditedBranch(key);
    setVal((prevData) =>
      prevData.map((item) => ({
        ...item,
        [month]: { editable: isValid ? value : item[month]?.editable, isValid },
      }))
    );
  };

  const handleUserChange = async (value) => {
    setClientId(value);
  };

  const handleMonthChange = (date) => {
    if (date) {
      const monthName = date.format("MMMM");
      setSelectedMonth(monthName);
    }
  };

  const getMasterBranchList = async () => {
    const body = {
      client_id: clientId,
    };
   
    try {
      const response = await api.post(API_URL.GET_ALL_BRANCH, body, token);
      if (response.status === 200) {
        const masterBranchList = response.result.data.map((branch) => ({
          value: branch.id,
          label: branch.branch_name,
        }));
        setMasterBranchList(masterBranchList);
      } else {
        console.error("Failed to fetch master branches");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateOrUpdateTarget = async (branchId, targetValue) => {
    try {
      const body = {
        branch_id: branchId,
        target: targetValue,
      };

      await dispatch(createOrUpdateTarget({ body })).unwrap();
      message.success("successfully Target data saved !!!");
    } catch (error) {
      console.error("Error creating/updating target:", error);
      message.error("Failed to save target data.");
    }
  };

  const columns = useMemo(
    () => [
      {
        title: "Branch Name",
        dataIndex: "name",
        key: "name",
        width: "24%",
        className: "dt-left",
      },
      {
        title: selectedMonth,
        dataIndex: selectedMonth,
        key: selectedMonth.toLowerCase(),
        width: "24%",
        className: "dt-left",
        render: (data, record) => {
          const editableValue = data?.editable || "";
          const isUpdate = currentEditedBranch === record.key;

          return (
            <div
              style={{
                display: "flex",
                gap: "5px",
                width: 150,
                alignItems: "center",
              }}
            >
              <Input
                value={editableValue}
                onChange={(e) =>
                  handleInputChange(e.target.value, record.key, selectedMonth)
                }
              />
              <i
                className={`fa-solid fa-check ${isUpdate ? "" : "disabled"}`}
                style={{
                  fontSize: "25px",
                  color: isUpdate ? "green" : "white",
                  marginTop: 5,
                  cursor: "pointer",
                }}
                onClick={() =>
                  isUpdate &&
                  handleCreateOrUpdateTarget(record.key, editableValue)
                }
              />
              <span>{data?.nonEditable || ""}</span>
            </div>
          );
        },
      },
      {
        title: getPreviousMonth(selectedMonth),
        dataIndex: getPreviousMonth(selectedMonth),
        key: getPreviousMonth(selectedMonth).toLowerCase(),
        width: "24%",
        className: "dt-left",
        render: (text) => <span style={{ width: 100 }}>{text}</span>,
      },
      {
        title: getMonthTwoBefore(selectedMonth),
        dataIndex: getMonthTwoBefore(selectedMonth),
        key: getMonthTwoBefore(selectedMonth).toLowerCase(),
        width: "24%",
        className: "dt-left",
        render: (text) => <span style={{ width: 100 }}>{text}</span>,
      },
    ],
    [selectedMonth, currentEditedBranch]
  );

  return (
    <div>
      <div className="main-contant">
        <div className="container-fluid">
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-5">
                  <div className="title-box">
                    <h5>Target Management</h5>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="d-flex justify-content-end">
                    <Select
                      style={{ width: 200, margin: 10 }}
                      onChange={handleUserChange}
                      placeholder="Select Client"
                      options={clientList}
                    />
                    <DatePicker
                      picker="month"
                      style={{ width: 200, marginTop: 11, height: 40 }}
                      onChange={handleMonthChange}
                      value={dayjs()
                        .month(months.indexOf(selectedMonth))
                        .startOf("month")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <section id="target-management">
              {clientId && masterBranchList.length > 0 ? (
                <Table
                  columns={columns}
                  dataSource={val}
                  // total={total}
                />
              ) : (
                <div>
                  <Table columns={columns} />
                </div>
              )}
              <div className="col-md-12 mt-4">
                <div className="cta-button-box">
                  <button
                    type="button"
                    className="btn submit-btn gray-btn"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn submit-btn"
                    onClick={handleCreateOrUpdateTarget}
                  >
                    Save
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewTarget;
