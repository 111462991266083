import { createAsyncThunk } from "@reduxjs/toolkit";
import { useToast } from "../../utils/helpers/CommFun";
import api from "../../services/Api";

// Utility function for fetching data
export const fetchData = (entity, apiUrl, prefix = "") =>
  createAsyncThunk(
    `${prefix}${entity}/fetchData`,
    async (body, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem("token");
        const response = await api.post(apiUrl, body, token);
        if (response.status === 200) {
          return response.result;
        } else {
          return rejectWithValue(response.message);
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

// Utility function for creating or updating data
export const createOrUpdateData = (entity, createUrl, updateUrl, prefix = "") =>
  createAsyncThunk(
    `${prefix}${entity}/createOrUpdate`,
    async ({ body, isUpdate }, { rejectWithValue, dispatch }) => {
      const Toast = useToast();
      try {
        const token = localStorage.getItem("token");
        const url = isUpdate ? updateUrl : createUrl;
        const response = await api.post(url, body, token);
        if (response.status === 200) {
          Toast.fire({
            icon: "success",
            title: response.message,
          });
          return;
        } else {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
          return rejectWithValue(response.message);
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
export const createOrUpdateFormData = (
  entity,
  createUrl,
  updateUrl,
  prefix = ""
) =>
  createAsyncThunk(
    `${prefix}${entity}/createOrUpdate`,
    async ({ formData, isUpdate }, { rejectWithValue, dispatch }) => {
     
      const Toast = useToast();
      try {
        const token = localStorage.getItem("token");
        const url = isUpdate ? updateUrl : createUrl;
        const response = await api.postFormData(url, formData, token);
        if (response.status === 200) {
          Toast.fire({
            icon: "success",
            title: response.message,
          });
          return;
        } else {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
          return rejectWithValue(response.message);
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

// Utility function for updating status
export const updateStatus = (entity, apiUrl) =>
  createAsyncThunk(
    `${entity}/updateStatus`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const token = localStorage.getItem("token");
        const Toast = useToast();

        const response = await api.post(apiUrl, body, token);
        if (response.status === 200) {
          Toast.fire({
            icon: "success",
            title: "Status updated successfully",
          });
          return body;
        } else {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
          return rejectWithValue(response.message);
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

// Utility function for deleting data
export const deleteData = (entity, apiUrl, prefix = "") =>
  createAsyncThunk(
    `${prefix}${entity}/delete`,
    async (body, { rejectWithValue, dispatch }) => {
      try {
        const token = localStorage.getItem("token");
        const Toast = useToast();

        const response = await api.post(apiUrl, body, token);
        if (response.status === 200) {
          Toast.fire({
            icon: "success",
            title: response.message,
          });
        } else {
          Toast.fire({
            icon: "error",
            title: response.message,
          });
          return rejectWithValue(response.message);
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );
