import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/auth/Login";
import ResetPassword from "./pages/auth/ResetPassword";
import Password from "./pages/password/password";
import AuthenticatedApp from "./AuthenticatedApp";

function App() {
  return (
    <Router>
      <Routes>
        {/* Routes that do not require Layout */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset_password/:reset_id?" element={<ResetPassword />} />
        <Route path="/password" element={<Password />} />

        {/* Protected routes */}
        <Route
          path="*"
          element={
            <AuthenticatedApp />
            // isAuthenticated ? (
            // <AuthenticatedApp /> // Directly render if lazy loading is not needed
            // ):(" ")
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
