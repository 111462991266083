// features/user/userSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import {
  createOrUpdateData,
  createOrUpdateFormData,
  deleteData,
  fetchData,
  updateStatus,
} from "../utils/apiActions";

export const fetchAllCases = fetchData("Case", API_URL.GET_ALL_CASE, "case");
export const fetchCaseApplicants = fetchData(
  "Case",
  API_URL.GET_CASE_APPLICANT,
  "applicant"
);
export const fetchCasePoints = fetchData(
  "Case",
  API_URL.GET_CASE_POINTS,
  "points"
);
export const fetchCaseDetails = fetchData(
  "Case",
  API_URL.GET_CASE_DETAILS,
  "caseDetails"
);
export const fetchVerificationType = fetchData(
  "Case",
  API_URL.GET_VERIFICATION_TYPE,
  "VerificationType"
);
export const fetchFEList = fetchData(
  "Case",
  API_URL.GET_FIELD_EXECUTIVE,
  "FEList"
);

export const createOrUpdateCase = createOrUpdateFormData(
  "Case",
  API_URL.CREATE_APPLICANT,
  API_URL.CREATE_APPLICANT
);
export const createOrUpdatePoint = createOrUpdateFormData(
  "Case",
  API_URL.ADD_POINTS,
  API_URL.UPDATE_POINTS,
  "points"
);

export const updateCaseStatus = updateStatus(
  "Case",
  API_URL.UPDATE_CLIENT_STATUS
);

export const deleteCase = deleteData("Case", API_URL.DELETE_CASE, "case");
export const deleteApplicant = deleteData(
  "Case",
  API_URL.DELETE_APPLICANT,
  "applicant"
);
export const deletePoint = deleteData("Case", API_URL.DELETE_POINTS, "point");

const CaseSlice = createSlice({
  name: "Case",
  initialState: {
    Cases: [],
    CaseDetails: [],
    Applicants: [],
    Points: [],
    VerificationTypes: [],
    FEList: [],
    selectedCase: null,
    status: STATUS_TYPE.IDLE,
    total: 0,
    applicantTotal: 0,
    error: null,
  },
  reducers: {
    CaseStatusReset: (state) => {
      state.status = STATUS_TYPE.IDLE;
    },
    resetCaseDetails: (state) => {
      state.CaseDetails = [];
    },
    resetPoints: (state) => {
      state.Points = [];
    },
    resetApplicants: (state) => {
      state.Applicants = [];
    },
    resetVerificationTypes: (state) => {
      state.VerificationTypes = [];
    },
    resetFEList: (state) => {
      state.FEList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCases.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllCases.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.Cases = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchAllCases.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchCaseDetails.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchCaseDetails.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.CaseDetails = action.payload;
      })
      .addCase(fetchCaseDetails.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchCaseApplicants.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchCaseApplicants.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.Applicants = action.payload.applicant_data;
        state.selectedCase = action.payload.case_data;
        state.applicantTotal = action.payload.total;
      })
      .addCase(fetchCaseApplicants.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchCasePoints.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchCasePoints.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.Points = action.payload;
      })
      .addCase(fetchCasePoints.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchVerificationType.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchVerificationType.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.VerificationTypes = action.payload;
      })
      .addCase(fetchVerificationType.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchFEList.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchFEList.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.FEList = action.payload;
      })
      .addCase(fetchFEList.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(updateCaseStatus.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(updateCaseStatus.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        const data = action.payload;
        state.Cases = state.Cases.map((Case) =>
          Case.id === data.Case_id ? { ...Case, status: data.status } : Case
        );
      })
      .addCase(updateCaseStatus.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload; // Error message from `rejectWithValue`
      })
      .addCase(createOrUpdateCase.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(createOrUpdateCase.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(createOrUpdateCase.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(createOrUpdatePoint.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(createOrUpdatePoint.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(createOrUpdatePoint.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(deleteCase.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(deleteCase.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(deleteCase.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(deleteApplicant.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(deleteApplicant.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(deleteApplicant.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(deletePoint.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(deletePoint.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(deletePoint.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      });
  },
});

export const {
  CaseStatusReset,
  resetPoints,
  resetApplicants,
  resetFEList,
  resetCaseDetails,
} = CaseSlice.actions;
export default CaseSlice.reducer;
