// features/user/userSlice.js

import { createSlice } from "@reduxjs/toolkit";

const SearchSlice = createSlice({
  name: "Search",
  initialState: {
    searchText: null,
  },
  reducers: {
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    resetSearchText: (state) => {
      state.searchText = null;
    },
  },
});

export const { resetSearchText, setSearchText } = SearchSlice.actions;
export default SearchSlice.reducer;
