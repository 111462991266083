import { createSlice } from "@reduxjs/toolkit";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import { createOrUpdateData, fetchData } from "../utils/apiActions";

export const fetchAllTargets = fetchData("target", API_URL.GET_ALL_TARGET);

export const createOrUpdateTarget = createOrUpdateData(
  "target",
  API_URL.CREATE_TARGET
  // API_URL.UPDATE_TARGET
);

const targetSlice = createSlice({
  name: "target",
  initialState: {
    targets: [],
    selectedTarget: null,
    status: STATUS_TYPE.IDLE,
    total: 0,
    error: null,
  },
  reducers: {
    targetStatusReset: (state) => {
      state.status = STATUS_TYPE.IDLE;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTargets.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllTargets.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.targets = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchAllTargets.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(createOrUpdateTarget.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(createOrUpdateTarget.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        // Handle additional logic on successful target creation/updation if needed
      })
      .addCase(createOrUpdateTarget.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message; // or action.payload if error is structured differently
      });
  },
});

export const { targetStatusReset } = targetSlice.actions;
export default targetSlice.reducer;
