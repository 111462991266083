import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  Image,
  Modal,
  DatePicker,
  Row,
  Col,
  Checkbox,
  TimePicker,
  Skeleton,
} from "antd";
import { UploadOutlined, PlusOutlined, DotChartOutlined } from "@ant-design/icons";

// Function to convert file to base64 for preview
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

// General Form Component
const SkeletonForm = ({
  config,
  initialValues,
  onFinish,
  onCancel,
  onBack,
  showBack = false,
  showCancle = true,
  handleFieldChange,
  submitText = "Submit",
  cancelText = "Cancel",
}) => {
  const [form] = Form.useForm();
  const [fileLists, setFileLists] = useState({});
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState({});
  const [formKey, setFormKey] = useState(0);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      // Initialize file lists based on initial values if they contain files
      const initFileLists = {};
      config.forEach((field) => {
        if (
          (field.type === "upload" || field.type === "multi-upload") &&
          initialValues[field.name]
        ) {
          initFileLists[field.name] = initialValues[field.name];
        }
      });
      setFileLists(initFileLists);
    } else {
      form.resetFields(); // Reset fields if no initialValues are provided
    }
  }, [initialValues, config, form]);

  const handlePreview = async (file, fieldName) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage((prev) => ({
      ...prev,
      [fieldName]: file.url || file.preview,
    }));
    setPreviewOpen(true);
  };

  const handleChange = (info, fieldName) => {
    const { fileList } = info;

    setFileLists((prev) => ({
      ...prev,
      [fieldName]: [...fileList],
    }));

    if (info.file.status === "done" && info.file.originFileObj) {
      setFormKey(formKey + 1);
    }
  };

  const rows = config ? Array.isArray(config[0]) ? config : [config] : [];

  return (
    <>
      <Form
        form={form}
        name="dynamic_form"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        style={{ margin: "0 auto" }}
        onFinish={onFinish}
        onValuesChange={handleFieldChange}
        autoComplete="off"
        key={formKey}
      >
        {rows.map((rowConfig, rowIndex) => (
          <Row gutter={[16, 24]}>
            {rowConfig?.map((field, index) => {
              switch (field.type) {
                case "input":
                  return (
                    <Col span={field.colSpan}>
                      <div key={field.name} style={{ marginBottom: '16px' }} >
                        {/* Display the actual label name */}
                        <div style={{ marginBottom: '8px' }}>
                          {field.label}
                        </div>
                        {/* Skeleton for the input */}
                        <div className="skeleton-form" style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                          <Skeleton.Input style={{ width: '100%' }} active size="large" />
                        </div>
                      </div>
                      {/* <Form.Item
                      key={index}
                      label={field.label}
                      name={field.name}
                      rules={field.rules}
                    >
                      <Input
                        placeholder={field.placeholder || ""}
                        disabled={field.disabled || false}
                        className="form-custom-control"
                      />
                    </Form.Item> */}
                    </Col>
                  );
                  case "textarea":
                    return (
                      <Col span={24} key={field.name}>
                      <div key={field.name} style={{ marginBottom: '16px' }} >
                        {/* Display the actual label name */}
                        <div style={{ marginBottom: '8px' }}>
                          {field.label}
                        </div>
                        {/* Skeleton for the input */}
                        <div className="skeleton-form" style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                          <Skeleton.Input style={{ width: '100%', height:'100px' }} active size="large" rows={4}  />
                        </div>
                      </div>
                    </Col>
                 );
                case "password":
                  return (
                    <Col span={field.colSpan}>
                      <div key={field.name} style={{ marginBottom: '16px' }} >
                        {/* Display the actual label name */}
                        <div style={{ marginBottom: '8px' }}>
                          {field.label}
                        </div>
                        {/* Skeleton for the input */}
                        <div className="skeleton-form" style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                          <Skeleton.Input style={{ width: '100%' }} active size="large" />
                        </div>
                      </div>
                      {/* <Form.Item
                      key={index}
                      label={field.label}
                      name={field.name}
                      rules={field.rules}
                    >
                      <Input.Password
                        disabled={field.disabled || false}
                        className="form-custom-control"
                      />
                    </Form.Item> */}
                    </Col>
                  );
                case "checkbox":
                  return (
                    <Col span={field.colSpan} key={index}>
                      <Form.Item
                        name={field.name}
                        valuePropName="checked"
                        rules={field.rules}
                      >
                        <Checkbox disabled={field.disabled || false}>
                          {field.label}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  );
                case "checkbox-group":
                  return (
                    <Col span={field.colSpan} key={index}>
                      <Form.Item
                        label={field.label}
                        name={field.name}
                        rules={field.rules}
                      >
                        <Checkbox.Group
                          options={field.options}
                          disabled={field.disabled || false}
                        />
                      </Form.Item>
                    </Col>
                  );

                case "date":
                  return (
                    <Col span={field.colSpan}>
                      <div key={field.name} style={{ marginBottom: '16px' }} >
                        {/* Display the actual label name */}
                        <div style={{ marginBottom: '8px' }}>
                          {field.label}
                        </div>
                        {/* Skeleton for the input */}
                        <div className="skeleton-form" style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                          <Skeleton.Input style={{ width: '100%' }} active size="large" />
                        </div>
                      </div>
                      {/* <Form.Item
                      key={index}
                      label={field.label}
                      name={field.name}
                      rules={field.rules}
                    >
                      <DatePicker
                        showTime={field.showTime || false}
                        className="form-custom-control"
                        style={{ width: "100%" }}
                        getPopupContainer={(trigger) =>
                          trigger.closest(".ant-form-item")
                        }
                        dropdownStyle={{
                          zIndex: 1, // Ensure dropdown is above other elements
                          width: "10px", // Set the width of the dropdown
                          Height: "5px", // Set the max height of the dropdown
                        }}
                        format={field.displayFormat || "DD-MM-YYYY"}
                        dropdown="custom-date-picker-dropdown" // Use a custom class
                        disabled={field.disabled || false}
                      />
                    </Form.Item> */}
                    </Col>
                  );
                // case "date":
                //   return (
                //     <Col span={field.colSpan}>
                //       <Form.Item
                //       showTime
                //         key={index}
                //         label={field.label}
                //         name={field.name}
                //         rules={field.rules}
                //       >
                //         <DatePicker
                //           className="form-custom-control"
                //           style={{ width: "100%" }}
                //           getPopupContainer={(trigger) =>
                //             trigger.closest(".ant-form-item")
                //           }
                //           dropdownStyle={{
                //             zIndex: 1, // Ensure dropdown is above other elements
                //             width: "10px", // Set the width of the dropdown
                //             Height: "5px", // Set the max height of the dropdown
                //           }}
                //           dropdown="custom-date-picker-dropdown" // Use a custom class
                //           disabled={field.disabled || false}
                //         />
                //       </Form.Item>
                //     </Col>
                //   );
                case "time":
                  return (
                    <Col span={field.colSpan}>
                      <Form.Item
                        key={index}
                        label={field.label}
                        name={field.name}
                        rules={field.rules}
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          className="form-custom-control"
                          style={{ width: "100%" }}
                          getPopupContainer={(trigger) =>
                            trigger.closest(".ant-form-item")
                          }
                          dropdownStyle={{
                            zIndex: 1, // Ensure dropdown is above other elements
                            width: "10px", // Set the width of the dropdown
                            Height: "5px", // Set the max height of the dropdown
                          }}
                          dropdown="custom-date-picker-dropdown" // Use a custom class
                          disabled={field.disabled || false}
                        />
                      </Form.Item>
                    </Col>
                  );

                case "select":
                  return (
                    <Col span={field.colSpan}>
                      <div key={field.name} style={{ marginBottom: '16px' }} >
                        {/* Display the actual label name */}
                        <div style={{ marginBottom: '8px' }}>
                          {field.label}
                        </div>
                        {/* Skeleton for the input */}
                        <div className="skeleton-form" style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                          <Skeleton.Input style={{ width: '100%' }} active size="large" />
                        </div>
                      </div>
                      {/* <Form.Item
                      key={index}
                      label={field.label}
                      name={field.name}
                      rules={field.rules}
                    >
                      <Select
                        allowClear
                        showSearch={field.showSearch || false}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        placeholder="Select an option"
                        style={{ width: "100%", zIndex: 1 }}
                        popupClassName="custom-dropdown"
                        onChange={field.onChange}
                        disabled={field.disabled || false}
                      >
                        {field?.options.map((option, i) => (
                          <Select.Option key={i} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>  */}
                    </Col>
                  );
                case "multiple-select":
                  return (
                    <Col span={field.colSpan}>
                      <div key={field.name} style={{ marginBottom: '16px', gap: "16px", height: "100%" }} >
                        {/* Display the actual label name */}
                        <div style={{ marginBottom: '8px', gap: '8px' }}>
                          {field.label}
                        </div>
                        {/* Skeleton for the input */}
                        <div className="skeleton-form" style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                          <Skeleton.Input style={{ width: '100%', gap: "16px" }} active size="large" />
                        </div>
                      </div>
                      {/* <Form.Item
                      key={index}
                      label={field.label}
                      name={field.name}
                      rules={field.rules}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select options"
                        style={{ width: "100%" }}
                        popupClassName="custom-dropdown"
                        onSearch={field.onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {field.options.map((option, i) => (
                          <Select.Option key={i} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item> */}
                    </Col>
                  );
                case "hidden":
                  return (
                    <Form.Item
                      key={index}
                      name={field.name}
                      initialValue={field.initialValue}
                    >
                      <Input type="hidden" />
                    </Form.Item>
                  );
                case "upload":
                  return (
                    <Col  style={{padding:0, margin:0, maxWidth:"14%"}}  key={field.name}>
                      <div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
                        {/* Display the actual label name */}
                        <div style={{ marginBottom: '8px', fontSize: '16px', color: '#333' }}>
                          {field.label}
                        </div>

                        {/* Skeleton for the input */}
                        <div className="skeleton-form" style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                          <Skeleton.Node active={true} style={{ width: '100px', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <DotChartOutlined style={{ fontSize: '0px', color: '#bfbfbf' }} />
                          </Skeleton.Node>
                          {/* You can add more skeletons here if needed */}
                        </div>
                      </div>



                    </Col>
                  );
                case "upload_new":
                  return (
                    <Col span={field.colSpan}>
                     <div key={field.name} style={{ marginBottom: '16px', gap: "16px", height: "100%" }} >
                        {/* Display the actual label name */}
                        <div style={{ marginBottom: '8px', gap: '8px' }}>
                          {field.label}
                        </div>
                        {/* Skeleton for the input */}
                        <div className="skeleton-form" style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                          <Skeleton.Input style={{ width: '100%', gap: "16px" }} active size="large" />
                        </div>
                      </div>
                    </Col>
                  );
                case "multi-upload":
                  return (
                    <Col span={field.colSpan}>
                      <Form.Item
                        key={index}
                        label={field.label}
                        name={field.name}
                        valuePropName="fileList"
                        getValueFromEvent={({ fileList }) => fileList}
                        rules={field.rules}
                      >
                        <Upload
                          listType="picture-card"
                          fileList={fileLists[field.name] || []}
                          onChange={(info) => handleChange(info, field.name)}
                          onPreview={(file) => handlePreview(file, field.name)}
                          disabled={field.disabled || false}
                          customRequest={({ onSuccess }) => {
                            onSuccess();
                          }}
                        >
                          {/* {fileLists[field.name] && */}
                          {/* fileLists[field.name].length > 0 ? null : ( */}
                          <PlusOutlined />
                          {/* )} */}
                        </Upload>
                        {previewImage[field.name] && (
                          <Image
                            wrapperStyle={{
                              display: "none",
                            }}
                            style={{ width: "inherit !important" }}
                            preview={{
                              visible: previewOpen,
                              onVisibleChange: (visible) =>
                                setPreviewOpen(visible),
                              afterOpenChange: (visible) =>
                                !visible && setPreviewImage(""),
                            }}
                            src={previewImage[field.name]}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  );
                case "button":
                  return (
                    <Col span={field.colSpan} key={index}>
                      <div key={field.name} style={{ marginBottom: '16px', gap: "16px", height: "100%" }} >
                        {/* Skeleton for the input */}
                        <div className="skeleton-form" style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                          <Skeleton.Input style={{ width: '100%', gap: "16px" }} active size="large" />
                        </div>
                      </div>
                    </Col>
                  );
                case "add-button":
                  return (
                    <Col span={field.colSpan} key={index}>
                      <Form.Item label={field.label} name={field.name}>
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={field.onClick}
                        ></Button>
                      </Form.Item>
                    </Col>
                  );
                case "number-input":
                  return (
                    <Col span={field.colSpan} key={index}>
                      <div key={field.name} style={{ marginBottom: '16px' }} >
                        {/* Display the actual label name */}
                        <div style={{ marginBottom: '8px' }}>
                          {field.label}
                        </div>
                        {/* Skeleton for the input */}
                        <div className="skeleton-form" style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                          <Skeleton.Input style={{ width: '100%' }} active size="large" />
                        </div>
                      </div>
                      {/* <Form.Item
                      label={field.label}
                      name={field.name}
                      rules={[
                        ...(field.rules || []),
                        {
                          pattern: /^[0-9]*$/,
                          message: "Input must be a number",
                        },
                      ]}
                    >
                      <Input
                        type="number"
                        maxLength={field.maxLength}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[0-9]*$/.test(value)) {
                            form.setFieldsValue({ [field.name]: value });
                          }
                        }}
                        disabled={field.disabled || false}
                        className="form-custom-control"
                      />
                    </Form.Item> */}
                    </Col>
                  );
                default:
                  return null;
              }
            })}
          </Row>
        ))}
        <Form.Item>
          <div className="cta-button-box">
            {showBack ? (
              <Button
                className="btn"
                style={{ width: "8rem" }}
                htmlType="button"
                onClick={() => {
                  onBack();
                }}
              >
                Back
              </Button>
            ) : (
              ""
            )}
            {showCancle ? (
              <Button
                className="btn submit-btn gray-btn"
                htmlType="button"
                onClick={() => {
                  form.resetFields();
                  onCancel();
                }}
              >
                {cancelText}
              </Button>
            ) : (
              ""
            )}

            <Button htmlType="submit" className="btn submit-btn">
              {submitText}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default SkeletonForm;

// const formConfig = [
//     {
//       label: 'Name',
//       name: 'name',
//       type: 'input',
//       rules: [{ required: true, message: 'Please Enter Name' }],
//     },
//     {
//       label: 'Email',
//       name: 'email',
//       type: 'input',
//       rules: [{ type: 'email', required: true, message: 'Please Enter Email' }],
//     },
//     {
//       label: 'Password',
//       name: 'password',
//       type: 'password',
//       rules: [{ required: true, message: 'Please Enter Password' }],
//     },
//     {
//       label: 'Phone Number',
//       name: 'phone',
//       type: 'input',
//       rules: [
//         { required: true, message: 'Please Enter Phone Number' },
//         { pattern: /^[0-9]{10}$/, message: 'Phone number must be exactly 10 digits' },
//       ],
//     },
//     {
//       label: 'Select Option',
//       name: 'select',
//       type: 'select',
//       options: [
//         { value: 'option1', label: 'Option 1' },
//         { value: 'option2', label: 'Option 2' },
//       ],
//     },
//     {
//       label: 'Multiple Select',
//       name: 'multipleSelect',
//       type: 'multiple-select',
//       options: [
//         { value: 'option1', label: 'Option 1' },
//         { value: 'option2', label: 'Option 2' },
//         { value: 'option3', label: 'Option 3' },
//       ],
//     },
//     {
//       label: 'Hidden Field',
//       name: 'hiddenField',
//       type: 'hidden',
//       initialValue: 'hiddenValue',
//     },
//     {
//       label: 'Upload Image',
//       name: 'upload',
//       type: 'upload',
//     },
//    {
//      type: "number-input",
//      name: "age",
//      label: "Age",
//      rules: [{ required: true, message: "Please input your age!" }],
//      maxLength: 3,
//    },
//   ];

//   const initialValues = {
//     name: 'John Doe',
//     email: 'john.doe@example.com',
//     phone: '1234567890',
//     select: 'option1',
//     multipleSelect: ['option1', 'option2'],
//     hiddenField: 'hiddenValue',
//   };
