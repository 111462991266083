// features/user/userSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import {
  createOrUpdateData,
  createOrUpdateFormData,
  deleteData,
  fetchData,
  updateStatus,
} from "../utils/apiActions";

export const fetchAllPickedCases = fetchData(
  "pickedCase",
  API_URL.GET_ALL_RAW_CASE,
  "fetchAll"
);
export const fetchPerticularPickedCase = fetchData(
  "pickedCase",
  API_URL.GET_PARTICULAR_RAW_CASE,
  "fetchPerticular"
);

export const createOrUpdatePickedCase = createOrUpdateFormData(
  "pickedCase",
  API_URL.CREATE_RAW_CASE,
  API_URL.UPDATE_RAW_CASE
);

export const deletePickedCase = deleteData(
  "pickedCase",
  API_URL.DELETE_RAW_CASE
);

const pickedCaseSlice = createSlice({
  name: "pickedCase",
  initialState: {
    pickedCases: [],
    selectedPickedCase: null,
    selectedRawApplicant: [],
    status: STATUS_TYPE.IDLE,
    total: 0,
    error: null,
  },
  reducers: {
    pickedCaseStatusReset: (state) => {
      state.status = STATUS_TYPE.IDLE;
    },
    resetPickedCaseState: (state) => {
      state.pickedCases = [];
      state.selectedRawApplicant = [];
      state.selectedPickedCase = null;
      state.total = 0;
      state.status = STATUS_TYPE.IDLE;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPickedCases.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllPickedCases.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.pickedCases = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchAllPickedCases.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchPerticularPickedCase.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchPerticularPickedCase.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.selectedPickedCase = action.payload.findOne;
        state.selectedRawApplicant = action.payload.rawApplicant;
      })
      .addCase(fetchPerticularPickedCase.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(createOrUpdatePickedCase.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(createOrUpdatePickedCase.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        // Optional: Handle additional logic on successful pickedCase creation/updation
      })
      .addCase(createOrUpdatePickedCase.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(deletePickedCase.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(deletePickedCase.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(deletePickedCase.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      });
  },
});

export const { pickedCaseStatusReset, resetPickedCaseState } =
  pickedCaseSlice.actions;
export default pickedCaseSlice.reducer;
