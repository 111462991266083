import React, { useEffect } from "react";
import { Skeleton } from "antd";

export const SkeletonTable = ({ columns }) => {

  const renderSkeletonTable = (columns) => {
    const totalWidth = columns.reduce(
      (acc, col) => acc + (col.width ? parseFloat(col.width) : 0),
      0
    );
    const remainingWidth = 100 - totalWidth; // Calculate the remaining width
    const autoWidth =
      remainingWidth / columns.filter((col) => !col.width).length; // Calculate the auto width

    return (
      <div>
        {/* Skeleton for Table Data Rows */}
        {Array.from({ length: 10 }).map((_, rowIndex) => (
          <div
            key={rowIndex}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              gap:"10px"
            }}
          >
            {columns.map((col) => (
              <div
                key={col.key}
                style={{
                  width: col.width ? col.width : `${autoWidth}%`, // Use the calculated auto width if width is not specified
                  flexBasis: col.width ? col.width : `${autoWidth}%`, // Add flexBasis to make it work with flexbox
                  marginTop: "20px",
                }}
              >
                <Skeleton.Input active block={true} />
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div>{renderSkeletonTable(columns)}</div>
    </>
  );
};
