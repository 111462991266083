import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import api from "../../services/Api";
import { useToast } from "../../utils/helpers/CommFun";
import Swal from "sweetalert2";
import { API_URL, Role } from "../../utils/constant/Constant";
import AntdTable from "../../components/AntdTable/Table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Space, Tooltip } from "antd";
import { useSelector } from "react-redux";

function ZoneManagement() {
  const [zoneList, setZoneList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [initialValue, setInitialValue] = useState(null);

  const Toast = useToast();
  const { userData,token } = useSelector((state) => state.auth);


  const closeRef = useRef();
  const openRef = useRef();

  const closeModal = () => {
    setIsUpdate(false);
    clear();
    closeRef.current.click();
    setInitialValue(null);
  };

  const openModal = () => {
    openRef.current.click();
  };

  // Clear form fields
  const clear = () => {
    setIsUpdate(false);
    setInitialValue(null);
  };

  // Create or update zone
  const createZone = async (values) => {
    try {
      const body = {
        name: values.name,
        states: values.states,
        parent_id: userData.id,
        master_id: userData.master_id,
      };
      if (isUpdate) body.id = values.id;

      const endpoint = isUpdate ? API_URL.UPDATE_ZONE : API_URL.CREATE_ZONE;
      const response = await api.post(endpoint, body, token);

      if (response.status === 200) {
        Toast.fire({
          icon: "success",
          title: response.message,
        });
        closeModal();
        getZoneList(); // Refresh the zone list
      } else {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
        console.error("Failed to create/update zone");
      }
      clear();
    } catch (error) {
      console.error(error);
    }
  };

  // Get the zone list
  const getZoneList = async () => {
    try {
      setZoneList([]);
      const body = { zone_id: userData.id };
      const response = await api.post(API_URL.GET_ZONE, body, token);

      if (response.status === 200) {
        setZoneList(response.result);
      } else {
        console.error("Failed to fetch zone list");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Open modal for update
  const openModalforUpdate = async (zone) => {
    setIsUpdate(true);
    setInitialValue({
      name: zone.name,
      states: zone.states,
      id: zone.id,
    });
    openModal();
  };

  // Update zone status
  const updateZoneStatus = async (zone) => {
    try {
      const updatedStatus = zone.status === 1 ? 0 : 1;
      const body = {
        id: zone.id,
        status: updatedStatus,
        name: zone.name,
        states: zone.states,
        parent_id: userData.id,
        master_id: userData.master_id,
      };
      const response = await api.post(API_URL.UPDATE_ZONE, body, token);

      if (response.status === 200) {
        Toast.fire({
          icon: "success",
          title: response.message,
        });
        getZoneList(); // Refresh the zone list
      } else {
        Toast.fire({
          icon: "error",
          title: response.message,
        });
        console.error("Failed to update zone status");
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Delete zone
  const deleteZone = async (zone) => {
    Swal.fire({
      title: "Delete",
      text: "Are you sure you want to delete this zone?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const body = { id: zone.id }; // Ensure the field name matches the API
          const response = await api.post(API_URL.DELETE_ZONE, body, token);
          if (response.status === 200) {
            Toast.fire({
              icon: "success",
              title: response.message,
            });
          } else {
            Toast.fire({
              icon: "error",
              title: response.message,
            });
            console.error("Failed to delete zone:", response.message);
          }
        } catch (error) {
          console.error("Error deleting zone:", error);
        }
        getZoneList();
      }
    });
  };

  const getStateList = async () => {
    try {
      const response = await api.get(API_URL.GET_ALL_STATE, token);

      if (response.status === 200) {
        const states = response.result.map((state) => ({
          value: state,
          label: state,
        }));
        setStateList(states);
      } else {
        Toast.fire({
          icon: "error",
          title: "Failed to fetch state list.",
        });
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: "An error occurred. Please try again.",
      });
    }
  };
  const columns = [
    {
      title: "Sr No.",
      key: "srNo",
      render: (_, __, index) => index + 1, // Renders the row number
      className: "dt-left",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "dt-left",

      sortable: true, // Enable sort
    },
    {
      title: "No. of States",
      dataIndex: "states",
      key: "states",
      className: "dt-left",
      render: (states) => states?.length || 0, // Combine states array into a comma-separated string

      sortable: true, // Enable sort
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <EditOutlined
              style={{ color: "#000", cursor: "pointer" }}
              onClick={() => openModalforUpdate(record)} // Implement handleEdit function
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined
              style={{ color: "#D92E38", cursor: "pointer" }}
              onClick={() => deleteZone(record)} // Implement handleDelete function
            />
          </Tooltip>
        </Space>
      ),
      className: "dt-center",
    },
  ];
  
  const formConfig = [
    {
      label: "Name",
      name: "name",
      type: "input",
      colSpan: 24,
      rules: [{ required: true, message: "Please Enter Name" }],
    },
    {
      label: "States",
      name: "states",
      type: "multiple-select",
      colSpan: 24,
      options: stateList,
      rules: [{ required: true, message: "Please Enter State" }],
    },
    {
      label: "Assign User",
      name: "assignUser",
      type: "select",
      colSpan: 24,
      options: [
        { value: "user1", label: "user1" },
        { value: "user2", label: "user2" },
      ],
      rules: [{ required: true, message: "Please Enter State" }],
    },
    {
      label: "zoneId",
      name: "id",
      type: "hidden",
      initialValue: null,
    },
  ];

  useEffect(() => {
    getStateList();
    getZoneList();
  }, []);

  return (
    <div className="main-contant">
      <div className="container-fluid">
        <div className="contant-box">
          <div className="sub-header">
            <div className="row">
              <div className="col-md-8">
                <div className="title-box">
                  <h5>Zone Management</h5>
                </div>
              </div>
              <div className="col-md-4">
                <div className="button-box">
                  <ul className="">
                    <li>
                      <Link
                        to="/"
                        className="blue-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#addEditZoneModal"
                        ref={openRef}
                      >
                        + Add New
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <section id="zone-management">
            {/* <Table
              data={zoneList}
              columns={columns}
              onupdate={openModalforUpdate}
              ondelete={deleteZone}
              onupdatestatus={updateZoneStatus}
            /> */}
            <AntdTable columns={columns} dataSource={zoneList} />

            <div
              className="modal fade"
              id="addEditZoneModal"
              tabIndex={-1}
              aria-labelledby="addEditZoneModal"
              data-bs-backdrop="static"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="addEditZoneModal">
                      {isUpdate ? "Update Zone" : "Add New Zone"}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      ref={closeRef}
                      onClick={clear}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <DynamicForm
                      config={formConfig}
                      onFinish={createZone}
                      initialValues={initialValue}
                      onCancel={closeModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ZoneManagement;
