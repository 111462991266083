import { createSlice } from "@reduxjs/toolkit";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import {
  createOrUpdateData,
  deleteData,
  fetchData,
  updateStatus,
} from "../utils/apiActions";

export const fetchAllDocConfigs = fetchData(
  "docConfig",
  API_URL.GET_CLIENT_DOC
);
export const fetchDocPoints = fetchData(
  "docConfig",
  API_URL.GET_DOC_POINTS,
  "fetchDocPoints"
);
export const fetchPerticularDocConfig = fetchData(
  "docConfig",
  API_URL.GET_CLIENT_DOC_DETAILS,
  "fetchPerticular"
);

export const createOrUpdateDocConfig = createOrUpdateData(
  "docConfig",
  API_URL.ADD_CLIENT_DOC,
  API_URL.UPDATE_CLIENT_DOC
);

export const deleteDocConfig = deleteData("docConfig", API_URL.DELETE_DOC);

export const updateDocConfigStatus = updateStatus(
  "docConfig",
  API_URL.UPDATE_DOC_STATUS
);

const docConfigSlice = createSlice({
  name: "docConfig",
  initialState: {
    docConfigs: [],
    docPoints: [],
    selectedDocConfig: null,
    status: STATUS_TYPE.IDLE,
    total: 0,
    error: null,
  },
  reducers: {
    docConfigStatusReset: (state) => {
      state.status = STATUS_TYPE.IDLE;
    },
    resetSelectedDoc: (state) => {
      state.selectedDocConfig = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDocConfigs.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchAllDocConfigs.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.docConfigs = action.payload.data;
        state.total = action.payload.total;
      })
      .addCase(fetchAllDocConfigs.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchPerticularDocConfig.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchPerticularDocConfig.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.selectedDocConfig = action.payload;
      })
      .addCase(fetchPerticularDocConfig.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(fetchDocPoints.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(fetchDocPoints.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        state.docPoints = action.payload;
      })
      .addCase(fetchDocPoints.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.error.message;
      })
      .addCase(createOrUpdateDocConfig.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(createOrUpdateDocConfig.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        // Optional: Handle additional logic on successful docConfig creation/updation
      })
      .addCase(createOrUpdateDocConfig.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(deleteDocConfig.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(deleteDocConfig.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
      })
      .addCase(deleteDocConfig.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload;
      })
      .addCase(updateDocConfigStatus.pending, (state) => {
        state.status = STATUS_TYPE.LOADING;
      })
      .addCase(updateDocConfigStatus.fulfilled, (state, action) => {
        state.status = STATUS_TYPE.SUCCEEDED;
        const data = action.payload;
        state.docConfigs = state.docConfigs.map((docConfig) =>
          docConfig.id === data.doc_id
            ? { ...docConfig, status: data.status }
            : docConfig
        );
      })
      .addCase(updateDocConfigStatus.rejected, (state, action) => {
        state.status = STATUS_TYPE.FAILED;
        state.error = action.payload; // Error message from `rejectWithValue`
      });
  },
});

export const { docConfigStatusReset, resetSelectedDoc } =
  docConfigSlice.actions;
export default docConfigSlice.reducer;
