import React, { useState, useEffect, useRef } from "react";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import api from "../../services/Api";
import { createUrlObj, getBase64, useToast } from "../../utils/helpers/CommFun";
import { API_URL, STATUS_TYPE } from "../../utils/constant/Constant";
import { Select } from "antd";
import { data } from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllClientBranches } from "../../redux/slices/clientBranchSlice";
import { Table } from "antd";
import SkeletonForm from "../../components/DynamicForm/SkeletonForm";
import {
  createOrUpdatePickedCase,
  fetchAllPickedCases,
  fetchPerticularPickedCase,
  resetPickedCaseState,
} from "../../redux/slices/pickedCaseSlice";

const UpdateCase = () => {
  const dispatch = useDispatch();
  const { selectedPickedCase, status: pickedCaseStatus } = useSelector(
    (state) => state.pickedCase
  );
  const { selectedRawApplicant, status: rawApplicantStatus } = useSelector(
    (state) => state.pickedCase
  );
  const { clientBranches, status, error, total } = useSelector(
    (state) => state.clientBranch
  );

  const location = useLocation();
  const [pickedId, setPickedId] = useState(location.state?.picked_id || null);
  const navigate = useNavigate();
  const Toast = useToast();

  const { Option } = Select;
  const [initialValue, setInitialValue] = useState();
  const [caseType, setCaseType] = useState("");
  const [branchType, setBranchType] = useState("");
  const [template, setTemplate] = useState([]);
  const [rawApplicantList, setRawApplicantList] = useState([]);
  const { userData, token } = useSelector((state) => state.auth);
  const [type, setType] = useState(1);

  useEffect(() => {
    dispatch(resetPickedCaseState());
    setInitialValue({
      type: 1,
    });
  }, [dispatch]);
  useEffect(() => {
    if (status === STATUS_TYPE.IDLE) {
      dispatch(fetchAllClientBranches({}));
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (pickedId) {
      const body = {
        picked_id: pickedId,
      };
      dispatch(fetchPerticularPickedCase(body));
    }
  }, [pickedId]);

  useEffect(() => {
    if (selectedPickedCase) {
      setInitialValue({
        rawId: selectedPickedCase.id,
        name: selectedPickedCase.name || "",
        middleName: selectedPickedCase.middle_name || "",
        lastName: selectedPickedCase.last_name || "",
        branchId: selectedPickedCase.branch_id || "",
        pickedId: pickedId,
        title: selectedPickedCase.title || "",
        description: selectedPickedCase.description || "",
        mobile: parseInt(selectedPickedCase.mobile_number) || "",
        selectType: selectedPickedCase.type || "",
        loan_amount: selectedPickedCase.loan_amount || "",
        loan_type: parseInt(selectedPickedCase.loan_type) || 0,
        status: selectedPickedCase.status,
        type: 1,
      });
      setBranchType(selectedPickedCase.branch_id);
      setCaseType(selectedPickedCase.type);
    }
    if (selectedRawApplicant) {
      setRawApplicantList(selectedRawApplicant);
    }
  }, [selectedPickedCase, selectedRawApplicant]);

  const branchData = async (val) => {
    if (val) {
      setBranchType(val);
    }
  };

  // const getCaseDocuments = async (id) => {
  //   try {
  //     const body = {
  //       raw_case_id: id,
  //     };
  //     const response = await api.post(API_URL.GET_RAW_CASE_DOC, body, token);
  //     if (response.status === 200) {
  //       let fileArray = [];
  //       response.result.forEach((element) => {
  //         fileArray.push(createUrlObj("documentFile", element.document)[0]);
  //       });
  //       setInitialValue((prev) => ({
  //         ...prev,
  //         documentFile: fileArray,
  //       }));
  //     } else {
  //       console.error("Failed to fetch documents");
  //     }
  //   } catch (error) {
  //   } finally {
  //   }
  // };

  const getFileArray = async (docFiles) => {
    const files = [];
    docFiles.forEach(async (element) => {
      const file = await getBase64(element.originFileObj);
      files.push(file);
    });
    return files;
  };
  const applicantCloseRef = useRef();
  const applicantModalClose = () => {
    applicantCloseRef.current.click();
  };

  const appendFormData = (formData, key, value) => {
    if (value !== null && value !== undefined) {
      formData.append(key, value);
    }
  };
  const isValidUpload = (files) => {
    return files.length > 0;
  };

  const createUser = async (values) => {
    const formData = new FormData();
    // Append regular fields

    appendFormData(formData, "name", values.name);
    appendFormData(formData, "applicant_type", values.type);
    appendFormData(formData, "picked_case_id", pickedId);

    const appendFiles = async (key, files) => {
      if (files) {
        files.forEach((file) => {
          formData.append(key, file.originFileObj);
        });
      }
    };

    appendFiles("documentFile", values.documentFile ?? []);

    const url = pickedId ? API_URL.UPDATE_RAW_CASE : API_URL.CREATE_RAW_CASE;

    if (pickedId) {
      appendFormData(formData, "status", initialValue.status);
    }
    const isUpdate = Boolean(pickedId);


    try {
      const response = await dispatch(
        createOrUpdatePickedCase({ formData, isUpdate })
      ).unwrap();
      dispatch(fetchAllPickedCases({}));
      applicantModalClose();
      const body = {
        picked_id: pickedId,
      };
      dispatch(fetchPerticularPickedCase(body));
    } catch (error) {
      console.error(error);
      // Optionally display error to the user
    }
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();

    // Append regular fields
    appendFormData(formData, "name", values.name);
    appendFormData(formData, "middle_name", values.middle_name);
    appendFormData(formData, "last_name", values.last_name);
    appendFormData(formData, "pe_id", userData.id);
    appendFormData(formData, "raw_case_id", values.id);
    appendFormData(formData, "status", values.status);
    appendFormData(formData, "loan_type", values.loan_type);
    appendFormData(formData, "title", values.title);
    appendFormData(formData, "description", values.description);
    appendFormData(formData, "loan_amount", values.loan_amount || 0);
    appendFormData(formData, "mobile_number", parseInt(values.mobile));
    appendFormData(formData, "branch_id", parseInt(values.branchId));
    appendFormData(formData, "branch_name", values.branch_name);
    appendFormData(formData, "user_id", userData.id);
    appendFormData(formData, "type", values.selectType);
    appendFormData(formData, "applicantType", values.type);
    if (values.pickedId) {
      appendFormData(formData, "picked_id", values.pickedId);
    }
    const appendFiles = async (key, files) => {
      if (files) {
        files.forEach((file) => {
          formData.append(key, file.originFileObj);
        });
      }
    };

    appendFiles("documentFile", values.documentFile ?? []);

    const url = initialValue.pickedId
      ? API_URL.UPDATE_RAW_CASE
      : API_URL.CREATE_RAW_CASE;

    if (initialValue?.rawId) {
      appendFormData(formData, "id", initialValue.rawId);
      appendFormData(formData, "status", initialValue.status);
    }

    const isUpdate = Boolean(initialValue.pickedId);



    try {
      const response = await dispatch(
        createOrUpdatePickedCase({ formData, isUpdate })
      ).unwrap();
      dispatch(fetchAllPickedCases({}));
      navigate("/user/pickedcases");
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => { }, [initialValue?.report]);
  const onCancelButtonClick = () => {
    navigate(-1);
    clear();
  };
  const initialFormState = {
    type: null,
    name: "",
    mobile: "",
    documentFile: null,
    // loan_amount: "",
    // loan_type: "",
    // add other fields if needed
  };
  const clear = () => {
    setInitialValue(initialFormState);
  };
  const getCaseType = (e) => {
    setCaseType(e);
  };
  const isDisabled = pickedId;
  const formConfig = [
    {
      label: "Select Branch",
      name: "branchId",
      type: "select",
      options: clientBranches.map((branch) => ({
        value: branch.id,
        label: branch.branch_name,
      })),
      rules: [{ required: true }],
      colSpan: 12,
      onChange: (val) => branchData(val),
      disabled: !!pickedId,
    },
    branchType && {
      label: "Select Type",
      name: "selectType",
      type: "select",
      rules: [{ required: true, message: "Please Select an Option" }],
      options: [
        { value: 1, label: "Text" },
        { value: 2, label: "Upload Images" },
        { value: 3, label: "Upload Excel" },
      ],
      colSpan: 12,
      onChange: (val) => getCaseType(val),
      disabled: !!pickedId,
    },
    caseType == 2 && {
      label: "Type",
      name: "type",
      type: "select",
      options: [{ value: 1, label: "Applicant" }],
      colSpan: 12,
      disabled: true,
    },
    (caseType == 1 || caseType == 3) && {
      label: "Title",
      name: "title",
      type: "input",
      rules: [{ required: true, message: "Please Enter Title" }],
      colSpan: 24,
      disabled: !!pickedId,
    },
    (caseType == 1 || caseType == 3) && {
      label: "Description",
      name: "description",
      type: "textarea",
      rules: [{ required: true, message: "Please Enter Title" }],
      colSpan: 24,
      disabled: !!pickedId,
    },
    caseType == 2 && {
      label: "Name",
      name: "name",
      type: "input",
      rules: [{ required: true, message: "Please Enter Applicant Name" }],
      colSpan: 12,
      disabled: !!pickedId,
    },
    // caseType == 2 && {
    //   label: "Mobile",
    //   name: "mobile",
    //   type: "number-input",
    //   colSpan: 8,
    //   rules: [
    //     // { required: true, message: "Please enter mobile number" },
    //     {
    //       pattern: /^[0-9]{10}$/,
    //       message: "Phone number must be exactly 10 digits",
    //     },
    //   ],
    //   disabled: !!pickedId,
    // },
    (caseType == 1 || caseType == 2) && {
      label: "Loan Type",
      name: "loan_type == null || loan_type == 0 ? '' : loan_type",
      type: "select",
      options: [
        { value: 1, label: "Home Loan" },
        { value: 2, label: "Car Loan" },
        { value: 3, label: "Business Loan" },
        { value: 4, label: "Personal Loan" },
      ],
      colSpan: 12,
      disabled: !!pickedId,
    },
    (caseType == 1 || caseType == 2) && {
      label: "Loan Amount",
      name: "loan_amount",
      type: "input",
      maxLength: 11,
      colSpan: 12,
      disabled: !!pickedId,
    },
    pickedId
      ? {
        label: "Status",
        name: "status",
        type: "select",
        options: [
          { value: 0, label: "Pending" },
          { value: 1, label: "In Progress" },
          { value: 2, label: "Completed" },
        ],
        rules: [{ required: true, message: "Please Select One Option" }],
        colSpan: 8,
      }
      : null,
    (caseType == 2 || caseType == 3) && {
      label: "Document",
      name: "documentFile",
      type: "multi-upload",
      colSpan: 8,
      accept: `${caseType == 2 ? '.jpg,.jpeg,.png' : '.xlsx,.xls'}`,
      rules: [{ required: !pickedId, message: "Upload Atleast 1 File" }],
      disabled: !!pickedId,
    },

    // caseType == 2 && {
    //   label: " ",
    //   name: "",
    //   type: "add-button",
    //   onClick: () => {
    //     createCaseData();
    //   },
    //   colSpan: 2,
    // },
    caseType == 2 && {
      label: "rawId",
      name: "rawId",
      type: "hidden",
      colSpan: 8,
      initialValue: data.id,
    },
  ].filter(Boolean);

  const handleFieldChange = (changedValues) => {
    setInitialValue((prev) => {
      return { ...prev, ...changedValues };
    });
  };
  const columns = [
    {
      title: "#",
      key: "index",
      render: (_, __, index) => index + 1, // Displays row index starting from 1
      className: "dt-center",
    },
    {
      dataIndex: "type", // Specifies the field name from your data source
      title: "Type",
      className: "dt-center",
      render: (type) => {
        switch (type) {
          case 1:
            return "Applicant";
          case 2:
            return "Co-Applicant";
          case 3:
            return "Guarantor";

          default:
            return "-";
        }
      },
    },
    {
      dataIndex: "name", // Specifies the field name from your data source
      title: "Name",
      className: "dt-center",
      render: (name, record) => {
        // Return name if it's available, otherwise return name
        return name !== undefined ? name : record.type;
      },
    },
  ];
  const applicantFormConfig = [
    {
      label: "Type",
      name: "type",
      type: "select",
      options: [
        { value: 1, label: "Applicant" },
        { value: 2, label: "Co-Applicant" },
        { value: 3, label: "Guarantor" },
      ],
      rules: [{ required: true, message: "Please Select One Option" }],
      colSpan: 12,
    },
    {
      label: "Name",
      name: "name",
      type: "input",
      maxLength: 15,
      rules: [{ required: true, message: "Please Enter First Name" }],
      colSpan: 12,
    },
    (caseType == 2) && {
      label: "Document",
      name: "documentFile",
      type: "multi-upload",
      colSpan: 24,
      accept: ".jpg,.jpeg,.png",
      rules: [{ required: true, message: "Upload Atleast 1 image" }],
    },
  ];
  return (
    <>
      <div className="main-contant">
        <div className="container-fluid">
          <div className="contant-box">
            <div className="sub-header">
              <div className="row">
                <div className="col-md-7">
                  <div className="title-box">
                    {pickedId ? (
                      <h5 className="bottom-line">Edit Picked Case</h5>
                    ) : (
                      <h5 className="bottom-line">Add New Picked Case</h5>
                    )}
                  </div>
                </div>
                {pickedId ? (
                  <div className="col-md-5">
                    <div className="d-flex justify-content-end">
                      <div className="button-box">
                        <ul className="">
                          <li>
                            <Link
                              to="/"
                              className="blue-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#ApplicantModal"
                            >
                              + Add Applicant
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <section id="pickedCases">
              <div className="add_user-box">
                <div className="add_user-form">
                  <div className=" row">
                    <div className="col-4"></div>
                    <div>
                      {
                        (formConfig && pickedId && !initialValue && pickedCaseStatus == STATUS_TYPE.LOADING )
                          ?
                          (
                            <SkeletonForm
                              config={formConfig}
                              initialValues={initialValue}
                              // initialValues={initialValue}
                              onFinish={handleSubmit}
                              onCancel={onCancelButtonClick}
                              handleFieldChange={handleFieldChange}
                            />

                          ) : (
                            (formConfig && (pickedId && initialValue)) || (formConfig && !pickedId) ? (
                              <DynamicForm
                                config={formConfig}
                                initialValues={initialValue}
                                // initialValues={initialValue}
                                onFinish={handleSubmit}
                                onCancel={onCancelButtonClick}
                                handleFieldChange={handleFieldChange}
                              />
                            ) : ""
                          )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="sub-header sub-header-2">
              <div>
                {caseType === 2 &&
                  pickedId && ( // Ensure pickedId is a valid component or function
                    <div>
                      <div className="col-md-8">
                        <div className="title-box">
                          <h5>Applicant List</h5>
                        </div>
                      </div>
                      <Table
                        dataSource={rawApplicantList}
                        columns={columns}
                        rowKey="id"
                      // components={{
                      //   body: {
                      //     row: Row,
                      //   },
                      // }}
                      />
                    </div>
                  )}
              </div>
            </div>
            <div
              className="modal fade"
              id="ApplicantModal"
              tabIndex={-1}
              aria-labelledby="ApplicantModal"
              data-bs-backdrop="static"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-lg">
                {/* Modal content*/}
                <div className="modal-content">
                  {/* modal header  */}
                  <div className="modal-header">
                    <h5 className="text-center">Add/Edit Applicant</h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        applicantModalClose();
                      }}
                      ref={applicantCloseRef}
                    />
                  </div>
                  <div className="sub-header">
                    <h6>Applicant</h6>
                  </div>
                  {/* modal body  */}
                  <div className="modal-body">
                    <DynamicForm
                      config={applicantFormConfig}
                      onFinish={createUser}
                      // handleFieldChange={handleApplicantFieldChange}
                      // onFinish={CreateApplicant}
                      onCancel={applicantModalClose}
                    />
                  </div>
                </div>
              </div>
              {/* Add/Edit Applicant modal End */}

              <div
                href="/"
                className="d-none"
                data-bs-toggle="modal"
                data-bs-target="#ApplicantModal"
              // ref={applicantOpenRef}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateCase;
